import { IPaymentMethod } from '@model/appointment/appointment'

export const translatePaymentMethod = (method: IPaymentMethod) => {
  switch (method) {
    case 'plan':
      return 'Plano'

    default:
      return 'Dinheiro/Cartão'
  }
}
