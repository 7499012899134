/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  createFilterOptions,
} from '@mui/material'

import { IRecommendations } from '@model/appointment/appointment'
import {
  changeRecommendations,
  recommendationsSelector,
} from '@slices/appointments/appointments.slice'
import { useAppDispatch, useAppSelector } from '@store/store'

import { useStyles } from './recommendations.styles'

export const Recommendations = () => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const recommendationsData = useAppSelector(recommendationsSelector)
  const filter = createFilterOptions({
    stringify: (option: any) => option,
  })

  function handleChangeRecommendations(recommendations: IRecommendations) {
    dispatch(changeRecommendations({ recommendations }))
  }

  return (
    <Box className={classes.container}>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item className={classes.gridItem}>
          <Autocomplete
            value={recommendationsData}
            multiple
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Recomendações"
                autoFocus={true}
              />
            )}
            options={[]}
            freeSolo
            selectOnFocus
            handleHomeEndKeys
            noOptionsText="Adicione as reclamações do paciente aqui"
            onChange={(_, __, reason, details) => {
              const value: any = details?.option

              if (reason === 'removeOption') {
                handleChangeRecommendations([
                  ...recommendationsData.filter(item => item !== value),
                ])

                return
              }

              if (reason === 'selectOption') {
                handleChangeRecommendations([
                  ...recommendationsData,
                  value.inputValue,
                ])
              } else {
                handleChangeRecommendations([...recommendationsData, value])
              }
            }}
            getOptionLabel={option => {
              if (typeof option === 'string') {
                return option
              }

              return (option as any).title
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params)

              const { inputValue } = params
              // Suggest the creation of a new value
              const isExisting = options.some(option => inputValue === option)

              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  title: `Adicionar "${inputValue}"`,
                })
              }

              return filtered
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
