import { Add, Delete } from '@mui/icons-material'
import {
  Box,
  Fab,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'

import { IPhysicalAnalysis } from '@model/appointment/appointment'
import {
  addPhysicalAnalysis,
  removePhysicalAnlysis,
  changePhysicalAnalysis,
  physicalAnalysisSelector,
} from '@slices/appointments/appointments.slice'
import { useAppDispatch, useAppSelector } from '@store/store'

import { useStyles } from './physical-analysis.styles'

export const PhysicalAnalysis = () => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const physicalAnalysisData = useAppSelector(physicalAnalysisSelector)

  function handleIntervalChange(idx: number, add: number) {
    if (add === 1) {
      dispatch(addPhysicalAnalysis())
      return
    }
    if (add === 0) {
      dispatch(removePhysicalAnlysis({ idx }))
      return
    }
  }

  function handleChangePhysicalAnlysis(
    idx: number,
    physicalAnalysis: IPhysicalAnalysis,
  ) {
    dispatch(
      changePhysicalAnalysis({
        idx,
        physicalAnalysis,
      }),
    )
  }

  return (
    <Box className={classes.container}>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid>
          <Typography className={classes.textTitle}>
            Avaliação Física
          </Typography>
        </Grid>
        <Grid item className={classes.gridItem}>
          {physicalAnalysisData.map((item, idx) => (
            <Box className={classes.boxAvaliation}>
              <Box className={classes.boxItemAvaliation}>
                {idx >= 1 ? (
                  <TextField
                    name="Avaliação"
                    hiddenLabel
                    fullWidth
                    multiline
                    autoFocus
                    placeholder="Coloque aqui uma etapa da avaliação física"
                    variant="standard"
                    value={item.index}
                    className={classes.textField}
                    onChange={e =>
                      handleChangePhysicalAnlysis(idx, {
                        ...item,
                        index: e.target.value,
                      })
                    }
                  />
                ) : (
                  <TextField
                    hiddenLabel
                    name="Avaliação"
                    fullWidth
                    multiline
                    autoFocus
                    placeholder="Coloque aqui uma etapa da avaliação física"
                    label="Avaliação física do paciente"
                    variant="standard"
                    value={item.index}
                    className={classes.textField}
                    onChange={e =>
                      handleChangePhysicalAnlysis(idx, {
                        ...item,
                        index: e.target.value,
                      })
                    }
                  />
                )}
                {idx >= 1 ? (
                  <TextField
                    name="Avaliação"
                    hiddenLabel
                    fullWidth
                    multiline
                    autoFocus
                    placeholder="Valor"
                    variant="standard"
                    value={item.value}
                    className={classes.textValue}
                    onChange={e =>
                      handleChangePhysicalAnlysis(idx, {
                        ...item,
                        value: e.target.value,
                      })
                    }
                  />
                ) : (
                  <TextField
                    hiddenLabel
                    name="Avaliação"
                    fullWidth
                    multiline
                    autoFocus
                    placeholder="Valor"
                    label="Valor da medição"
                    variant="standard"
                    value={item.value}
                    className={classes.textValue}
                    onChange={e =>
                      handleChangePhysicalAnlysis(idx, {
                        ...item,
                        value: e.target.value,
                      })
                    }
                  />
                )}
                <Box className={classes.deleteButton}>
                  <IconButton
                    size="small"
                    onClick={() => handleIntervalChange(idx, 0)}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          ))}
        </Grid>
        <Box className={classes.addButton}>
          <Fab
            size="small"
            color="primary"
            onClick={() => handleIntervalChange(0, 1)}
          >
            <Add />
          </Fab>
        </Box>
      </Grid>
    </Box>
  )
}
