import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  button: {
    textTransform: 'none',
    fontSize: '0.8em',
    whiteSpace: 'nowrap',
  },
  icon: {
    verticalAlign: 'middle',
    fontSize: '1em',
    marginRight: '0.5em',
  },
  avatar: {
    height: '2em',
    width: '2em',
  },
  statusIcon: {
    verticalAlign: 'middle',
    fontSize: '1em',
    marginRight: '0.3em',
  },
  canceledFilter: {
    filter: 'grayscale(100%)',
  },
}))
