import { ReactNode, useState } from 'react'

import { ArrowBackIosNew } from '@mui/icons-material'
import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material'
import clsx from 'clsx'
import {
  FiBookOpen,
  FiCheckSquare,
  FiCalendar,
  FiHome,
  FiLogOut,
} from 'react-icons/fi'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'

import Logo from '@assets/sidebar-logo.png'
import { doctorSelector, signOut } from '@slices/session/session.slice'
import { useAppDispatch, useAppSelector } from '@store/store'

import { useStyles } from './dashboard.styles'

interface Props {
  title: string
  children: ReactNode
}

export const Dashboard = ({ children, title }: Props) => {
  const { classes } = useStyles()
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const doctor = useAppSelector(doctorSelector)

  function handleDrawerToggle() {
    setDrawerOpen(!drawerOpen)
  }

  function handleLogout() {
    dispatch(signOut())
    navigate('/')
  }

  function handleProfileNavigation() {
    navigate('/perfil')
  }

  const isAppointmentsPage = pathname.includes('dia-de-trabalho/consulta')

  const sidebarContent = (
    <>
      <Box className={classes.dashboardContainer}>
        <Box>
          <Box className={classes.doctorBase}>
            <Box className={classes.doctorBackground}>
              <img
                className={classes.doctorAvatar}
                src={doctor?.imageUrl}
                alt="Doctor"
                onClick={handleProfileNavigation}
                data-testid="profile-button"
              />
            </Box>
            <Box>
              <Typography
                className={clsx(classes.sidebarItem, classes.sidebarText)}
              >
                Olá,
              </Typography>
              <Typography className={classes.doctorName}>
                Dr(a). {doctor?.name}
              </Typography>
            </Box>
          </Box>
          <Divider className={classes.divider} />
          <List className={classes.listContainer}>
            <ListItemButton
              classes={{
                root: classes.listItem,
                selected: classes.listItemSelected,
              }}
              selected={pathname.includes('home')}
            >
              <RouterLink className={classes.link} to="/home">
                <FiHome size={20} className={classes.sidebarItem} />
                <Typography
                  className={clsx(classes.sidebarItem, classes.sidebarText)}
                >
                  Página Inicial
                </Typography>
              </RouterLink>
            </ListItemButton>
            <ListItemButton
              classes={{
                root: classes.listItem,
                selected: classes.listItemSelected,
              }}
              selected={pathname.includes('agenda')}
            >
              <RouterLink className={classes.link} to="/agenda">
                <FiCalendar size={20} className={classes.sidebarItem} />
                <Typography
                  className={clsx(classes.sidebarItem, classes.sidebarText)}
                >
                  Agenda
                </Typography>
              </RouterLink>
            </ListItemButton>
            <ListItemButton
              classes={{
                root: classes.listItem,
                selected: classes.listItemSelected,
              }}
              selected={pathname.includes('dia-de-trabalho')}
            >
              <RouterLink className={classes.link} to="/dia-de-trabalho">
                <FiCheckSquare size={20} className={classes.sidebarItem} />
                <Typography
                  className={clsx(classes.sidebarItem, classes.sidebarText)}
                >
                  Dia de trabalho
                </Typography>
              </RouterLink>
            </ListItemButton>
            <ListItemButton
              classes={{
                root: classes.listItem,
                selected: classes.listItemSelected,
              }}
              selected={pathname.includes('pacientes')}
            >
              <RouterLink className={classes.link} to="/pacientes">
                <FiBookOpen size={20} className={classes.sidebarItem} />
                <Typography
                  className={clsx(classes.sidebarItem, classes.sidebarText)}
                >
                  Pacientes
                </Typography>
              </RouterLink>
            </ListItemButton>
          </List>
          <Divider className={classes.divider} />
        </Box>
        <List className={classes.listContainer}>
          <ListItem
            classes={{
              root: classes.listItem,
              selected: classes.listItemSelected,
            }}
          >
            <Box
              className={classes.link}
              onClick={handleLogout}
              data-testid="logout-button"
            >
              <FiLogOut size={20} className={classes.sidebarItem} />
              <Typography
                className={clsx(classes.sidebarItem, classes.sidebarText)}
              >
                Fazer Logout
              </Typography>
            </Box>
          </ListItem>
        </List>
      </Box>
    </>
  )

  return (
    <div className={classes.container}>
      <CssBaseline />
      <Box className={classes.contentContainer}>
        <nav className={classes.sidebarContainer}>
          <Hidden implementation="css">
            <Drawer
              variant="temporary"
              open={drawerOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <>
                <img className={classes.logo} src={Logo} alt="Blu Med" />
                {sidebarContent}
              </>
            </Drawer>
          </Hidden>
          <Hidden lgDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {sidebarContent}
            </Drawer>
          </Hidden>
        </nav>
        <Box className={classes.alignDashboard}>
          <main className={classes.main}>
            <Box className={classes.pageHeader}>
              <Typography className={classes.pageTitle}>
                {isAppointmentsPage ? (
                  <ArrowBackIosNew
                    className={classes.backButton}
                    onClick={() => navigate(-1)}
                  />
                ) : null}
                {title}
              </Typography>
              <img className={classes.logo} src={Logo} alt="Blu Med" />
            </Box>
            {children}
          </main>
        </Box>
      </Box>
    </div>
  )
}
