import { ElementType, SyntheticEvent } from 'react'

import {
  TextField,
  TextFieldProps,
  Autocomplete,
  AutocompleteProps,
} from '@mui/material'
import { useField, useFormikContext } from 'formik'

interface Props<T>
  extends Omit<
    AutocompleteProps<T, boolean, boolean, boolean, ElementType>,
    'options' | 'renderInput'
  > {
  options: T[]
  textFieldParams: Omit<TextFieldProps, 'name'> & {
    name: string
  }
}

export function ControlledGeneralSelect<T>({
  textFieldParams,
  ...rest
}: Props<T>) {
  const [field, meta] = useField({
    name: textFieldParams.name,
  })
  const { setFieldValue } = useFormikContext()

  function handleOnBlur(e: SyntheticEvent) {
    field.onBlur(e)
  }

  return (
    <Autocomplete
      onChange={(_, newValue) => setFieldValue(textFieldParams.name, newValue)}
      {...rest}
      value={Boolean(field.value) ? field.value : null}
      renderInput={params => (
        <TextField
          {...params}
          {...textFieldParams}
          variant={
            textFieldParams.variant ? textFieldParams.variant : 'outlined'
          }
          error={Boolean(meta.error && meta.touched) ? true : false}
          onBlur={handleOnBlur}
          helperText={
            Boolean(meta.error && meta.touched)
              ? textFieldParams.helperText
              : null
          }
        />
      )}
    />
  )
}
