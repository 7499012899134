import { Box, Typography, Divider } from '@mui/material'

import { TableSummary } from '@components/TableSummary/table-summary'
import { IClinicalAnalysis } from '@model/appointment/appointment'

import { useStyles } from './patient-clinical-analizes-table.styles'

interface Props {
  clinicalAnalizes?: IClinicalAnalysis[]
}

export const PatientClinicalAnalizesTable = ({ clinicalAnalizes }: Props) => {
  const { classes } = useStyles()

  return (
    <TableSummary title="ÚLTIMOS DIAGNÓSTICOS" icon="calendar">
      {clinicalAnalizes?.map(item => (
        <>
          <Box key={item._id} className={classes.rowContainer}>
            <Typography className={classes.title}>{item.diagnosis}</Typography>
            <Typography className={classes.text}>{item.description}</Typography>
          </Box>
          <Divider />
        </>
      ))}
    </TableSummary>
  )
}
