import { combineReducers, configureStore, Reducer } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { COMMON } from '@config/common'
import { baseApi } from '@services/api'
import { appointmentsSlice } from '@slices/appointments/appointments.slice'
import { patientSlice } from '@slices/patient/patient.slice'
import { profileSlice } from '@slices/profile/profile.slice'
import { sessionApi } from '@slices/session/session.api'
import { sessionSlice } from '@slices/session/session.slice'

export type IAppDispatch = typeof store.dispatch

export const reducers = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  [sessionApi.reducerPath]: sessionApi.reducer,
  appointmentsSlice: appointmentsSlice.reducer,
  sessionSlice: sessionSlice.reducer,
  profileSlice: profileSlice.reducer,
  patientSlice: patientSlice.reducer,
})

const { ENV } = COMMON

export const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    appointmentsSlice.name,
    sessionSlice.name,
    profileSlice.name,
    patientSlice.name,
  ],
}

const rootReducer: Reducer = (state, action) => {
  if (action.type === 'sessionSlice/signOut') {
    state = undefined
  }
  return reducers(state, action)
}

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  devTools: ENV === 'PRODUCTION' ? false : true,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(baseApi.middleware)
  },
})

export type RootState = ReturnType<typeof reducers>

export const persistor = persistStore(store)

export const useAppDispatch = () => useDispatch<IAppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
