import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  graph: {
    color: theme.palette.primary.main,
    borderRadius: '100%',
    boxShadow: 'inset 0 0 0px 0.8em #DAEEFE',
  },
  caption: {
    fontWeight: 500,
    fontSize: '1.5em',
  },
}))
