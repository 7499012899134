import { ReactNode } from 'react'

import { Box } from '@mui/material'
import clsx from 'clsx'

import { useStyles } from './day-column-wrapper.styles'

interface Props {
  children: ReactNode
}

export const DayColumnWrapper = ({ children }: Props) => {
  const { classes } = useStyles()

  return (
    <Box className={clsx('rbc-day-slot', classes.container)}>{children}</Box>
  )
}
