import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(1, 1),
    backgroundColor: theme.palette.primary.dark,
    height: '4rem',

    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.85,
    },
  },
  disabled: {
    backgroundColor: theme.palette.grey[200],

    '&:hover': { opacity: 1, backgroundColor: theme.palette.grey[200] },
  },
  label: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
  },
}))
