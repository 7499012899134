export const sortTable = <T>(
  tableRows: T[],
  orderBy: keyof T,
  order: 'asc' | 'desc',
) => {
  return [...tableRows].sort((a, b) => {
    if (a[orderBy] instanceof Date) {
      return sortDate(
        a[orderBy] as unknown as Date,
        b[orderBy] as unknown as Date,
        order,
      )
    }

    return sortStrings(
      a[orderBy] as unknown as string,
      b[orderBy] as unknown as string,
      order,
    )
  })
}

const sortDate = (a: Date, b: Date, order: 'asc' | 'desc') => {
  if (order === 'desc') {
    return a.valueOf() - b.valueOf()
  }

  return b.valueOf() - a.valueOf()
}

const sortStrings = (a: string, b: string, order: 'asc' | 'desc') => {
  if (order === 'desc') {
    return String(b).localeCompare(String(a))
  }

  return String(a).localeCompare(String(b))
}
