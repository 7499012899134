import { ChangeEvent } from 'react'

import { TableCell, TableRow, Typography, Pagination } from '@mui/material'

import { getTableFooterText } from '@utils/get-table-footer-text/get-table-footer-text'

import { useStyles } from './table-footer.styles'

interface Props {
  page: number
  count?: number
  totalPages?: number
  handleChangePage(_: ChangeEvent<unknown>, newPage: number): void
}

export const TableFooter = ({
  page,
  count,
  totalPages,
  handleChangePage,
}: Props) => {
  const { classes } = useStyles()

  return (
    <TableRow>
      <TableCell>
        <Typography className={classes.label}>
          {getTableFooterText(page, count)}
        </Typography>
      </TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell>
        <Pagination
          shape="rounded"
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          data-testid="pagination"
        />
      </TableCell>
    </TableRow>
  )
}
