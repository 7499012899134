import CameraIcon from '@mui/icons-material/CameraEnhance'
import { Avatar, Box, Fab } from '@mui/material'

import { ErrorModal } from '@components/ErrorModal/error-modal'
import { FormButton } from '@components/FormButton/form-button'
import { useUpdateImageMutation } from '@slices/profile/profile.api'
import { doctorSelector, updateDoctor } from '@slices/session/session.slice'
import { useAppDispatch, useAppSelector } from '@store/store'

import { useStyles } from './profile-image-form.styles'

export const ProfileImageForm = () => {
  const { profileImage, name } = useAppSelector(doctorSelector)
  const dispatch = useAppDispatch()
  const { classes } = useStyles()

  const [
    updateImage,
    { error: updatingImageError, isLoading: isUpdatingImage },
  ] = useUpdateImageMutation()

  async function handleSubmit() {
    if (!profileImage?.imageFile) return

    const form = new FormData()
    form.append('file', profileImage.imageFile)

    await updateImage(form)
  }

  function handleImageChange(files: File[]) {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(files[0])

    fileReader.onload = e => {
      dispatch(
        updateDoctor({
          profileImage: {
            imageFile: files[0],
            imageUrl: e.target?.result?.toString(),
          },
        }),
      )
    }
  }

  return (
    <Box className={classes.container}>
      <Box>
        <Avatar
          className={classes.avatar}
          variant="square"
          title={name}
          src={profileImage?.imageUrl}
        />

        <Fab component="label" className={classes.button} size="small">
          <CameraIcon />
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={({ target }) =>
              handleImageChange(target.files as unknown as File[])
            }
          />
        </Fab>
      </Box>

      <FormButton
        disabled={!profileImage?.imageFile}
        onClick={handleSubmit}
        isLoading={isUpdatingImage}
        type="submit"
      >
        Salvar alterações
      </FormButton>
      {updatingImageError && <ErrorModal error={updatingImageError} />}
    </Box>
  )
}
