import { CSSProperties } from 'react'

import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    ...(theme.typography.h6 as CSSProperties),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.additionalColors.title,
  },
}))
