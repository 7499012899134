import { Avatar, Box, Button, Grid, Typography } from '@mui/material'
import clsx from 'clsx'
import {
  EnvelopeSimple,
  PhoneDisconnect,
  IdentificationBadge,
} from 'phosphor-react'
import { useNavigate } from 'react-router-dom'

import { patientSelector } from '@slices/patient/patient.slice'
import { useAppSelector } from '@store/store'
import { getAge } from '@utils/date/date'

import { useStyles } from './patient-general-info.styles'

export const PatientGeneralInfo = () => {
  const { classes } = useStyles()
  const patient = useAppSelector(patientSelector)

  const navigate = useNavigate()
  async function handleNavigation() {
    if (patient) {
      navigate(`/pacientes/${patient._id}/ficha`)
    }
  }

  return (
    <Box className={classes.container}>
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        className={classes.contentContainer}
      >
        <Grid item className={classes.appointmentInfoGridContainer}>
          <Box className={classes.appointmentInfo}>
            <Avatar className={classes.avatar}>
              <img
                className={classes.avatar}
                src={patient.imageUrl}
                alt="Blu"
              />
            </Avatar>
            <Box>
              <Typography className={classes.patientName}>
                {patient.name}
              </Typography>
              <Box className={classes.appointmentInfoDateTime}>
                <Box className={classes.firstPacient}>
                  <EnvelopeSimple
                    className={classes.pacientDescription}
                    fontSize="small"
                  />
                  <Typography className={classes.pacientDescription}>
                    {patient.email}
                  </Typography>
                </Box>
                <Box className={classes.datetimeContainer}>
                  <PhoneDisconnect
                    className={classes.pacientDescription}
                    fontSize="small"
                  />
                  <Typography className={classes.pacientDescription}>
                    {patient.phoneNumber}
                  </Typography>
                </Box>
                <Box className={classes.datetimeContainer}>
                  <IdentificationBadge
                    className={classes.pacientDescription}
                    fontSize="small"
                  />
                  <Typography className={classes.pacientDescription}>
                    {patient.birthday && `${getAge(patient.birthday)} anos`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Button
          className={clsx(classes.button, classes.medicalFileButton)}
          onClick={handleNavigation}
          disabled={!patient?.medicalFile}
        >
          Acessar ficha médica
        </Button>
      </Grid>
    </Box>
  )
}
