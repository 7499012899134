import { CSSProperties } from '@mui/styled-engine'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: { marginTop: theme.spacing(3) },
  root: {
    height: '2.5rem',
    background: '#cee3f9',
    borderRadius: theme.spacing(1),
  },
  indicator: {
    height: 0,
    '&::before': {
      content: '""',
      height: 0,
    },
  },
  tab: {
    ...(theme.typography.body1 as CSSProperties),
    color: theme.palette.primary.dark,
    fontSize: '13px',
    '&.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.light,
      borderRadius: theme.spacing(1),
    },
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
  },
}))
