import { Avatar, Box, TableCell, TableRow, Typography } from '@mui/material'

import { IPatient } from '@model/patient/patient'
import { getAge } from '@utils/date/date'
import { formatPhoneNumber } from '@utils/phone-number/phone-number'

import { useStyles } from './patients-table-row.styles'

interface Props
  extends Pick<
    IPatient,
    'name' | 'phoneNumber' | 'imageUrl' | 'isBlu' | 'birthday'
  > {
  onRowClick(): void
}

export const PatientsTableRow = ({
  name,
  phoneNumber,
  isBlu,
  imageUrl,
  birthday,
  onRowClick,
}: Props) => {
  const { classes } = useStyles()

  return (
    <TableRow className={classes.container} onClick={onRowClick}>
      <TableCell>
        <Box className={classes.nameCellTextContainer}>
          <Avatar variant="rounded" className={classes.avatar}>
            <img className={classes.avatarImg} src={imageUrl} alt="Blu" />
          </Avatar>
          <Typography variant="inherit" noWrap className={classes.nameText}>
            {name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        {isBlu ? (
          <Typography variant="inherit" noWrap className={classes.typeText}>
            Paciente Blu
          </Typography>
        ) : (
          <Typography>-</Typography>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="inherit" noWrap className={classes.nameText}>
          {formatPhoneNumber(phoneNumber)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="inherit" noWrap className={classes.dateText}>
          {birthday && `${getAge(birthday)} anos`}
        </Typography>
      </TableCell>
    </TableRow>
  )
}
