import { Box, Grid } from '@mui/material'

import { TableSummary } from '@components/TableSummary/table-summary'
import { IMedicalFile } from '@model/medical-file/medical-file'
import { translateFrequency } from '@utils/translate-frequency/translate-frequency'

import { useStyles } from './info-card.styles'

interface Props
  extends Partial<
    Pick<IMedicalFile, 'physicalFeatures' | 'smokingHabit' | 'drinkingHabit'>
  > {
  title: string
}

export const InfoCard = ({
  title,
  physicalFeatures,
  smokingHabit,
  drinkingHabit,
}: Props) => {
  const { classes } = useStyles()

  return (
    <TableSummary title={title} icon="text">
      <Grid container className={classes.container} spacing={1}>
        {physicalFeatures && (
          <>
            <Grid item xs={6}>
              <Box className={classes.label}>Grupo Sanguíneo</Box>
              <Box className={classes.value}>{physicalFeatures.bloodGroup}</Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.label}>Altura (m)</Box>
              <Box className={classes.value}>{physicalFeatures.height}</Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.label}>Peso (kg)</Box>
              <Box className={classes.value}>{physicalFeatures.weight}</Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.label}>PCD</Box>
              <Box className={classes.value}>
                {physicalFeatures.pwd ? 'Sim' : 'Não'}
              </Box>
            </Grid>
          </>
        )}
        {smokingHabit && (
          <Grid item xs={6}>
            <Box className={classes.label}>Fumo</Box>
            <Box className={classes.value}>
              {translateFrequency(smokingHabit)}
            </Box>
          </Grid>
        )}
        {drinkingHabit && (
          <Grid item xs={6}>
            <Box className={classes.label}>Bebida</Box>
            <Box className={classes.value}>
              {translateFrequency(drinkingHabit)}
            </Box>
          </Grid>
        )}
      </Grid>
    </TableSummary>
  )
}
