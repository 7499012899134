import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material'

export const Theme = (props: React.PropsWithChildren<unknown>) => {
  const customTheme = {
    palette: {
      primary: {
        main: '#3c91e6',
        light: '#1273eb',
        dark: '#0064b3',
      },
      secondary: {
        main: '#ededed',
        light: '#ffffff',
        dark: '#bbbbbb',
      },
      additionalColors: {
        title: '#00234C',
      },
      success: {
        main: '#0A9168',
        light: '#0A9168',
        dark: '#0A9168',
      },
      customShadows: {
        shadow1: '0 5px 20px 0 rgb(0 0 0 / 20%)',
        shadow2: '0 5px 10px 0 rgb(0 0 0 / 15%)',
      },
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
    },
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(customTheme)}>
        {props.children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
