import { Button, Stack, Typography } from '@mui/material'
import Lottie from 'react-lottie'
import { useNavigate } from 'react-router-dom'

import { useStyles } from '@app/home/components/FinishedDay/finished-day.styles'
import Balloons from '@assets/balloons.png'
import * as animation from '@assets/finished-day-animation.json'

export const FinishedDay = () => {
  const { classes } = useStyles()
  const navigate = useNavigate()

  function handleNavigation() {
    navigate('/dia-de-trabalho')
  }

  return (
    <Stack spacing={2} className={classes.root}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animation,
        }}
        height={500}
        width={500}
      />

      <Stack direction="column" spacing={3}>
        <Typography variant="h1" className={classes.title}>
          Dia <span className={classes.highlight}>Finalizado</span>
          <img src={Balloons} alt="balloons" className={classes.icon} />
        </Typography>
        <Typography className={classes.content}>
          Todas as consultas agendadas para hoje já foram concluídas. Bom
          descanso :)
        </Typography>
        <Button onClick={handleNavigation} className={classes.button}>
          Consultar Agenda
        </Button>
      </Stack>
    </Stack>
  )
}
