import { View, Text } from '@react-pdf/renderer'

import { PrescriptionFileBasis } from '@app/appointment/components/PrescriptionFileBasis/prescription-file-basis'
import {
  IExamItem,
  IMedicalCertificate,
  IPrescriptionItem,
} from '@model/appointment/appointment'
import { IDoctor } from '@model/doctor/doctor'

interface Props {
  patient: string
  doctor: IDoctor
  title: string
  prescription?: IPrescriptionItem[]
  examItem?: IExamItem
  medicalCertificate?: IMedicalCertificate
}

export const PrescriptionFile = ({
  patient,
  doctor,
  prescription,
  title,
  examItem,
  medicalCertificate,
}: Props) => {
  const date = new Date()
  const dateDay =
    date.getDate() > 9
      ? date.getDate().toString()
      : '0' + date.getDate().toString()
  const dateMonth =
    date.getMonth() > 8
      ? (date.getMonth() + 1).toString()
      : '0' + (date.getMonth() + 1).toString()
  const dateYear = date.getFullYear().toString()
  return (
    <>
      {title === 'RECEITA' && (
        <PrescriptionFileBasis
          patient={patient}
          doctor={doctor}
          title={title}
          dateDay={dateDay}
          dateMonth={dateMonth}
          dateYear={dateYear}
        >
          <View style={{ marginTop: 30 }}>
            <View style={{ display: 'flex' }}>
              {(prescription as IPrescriptionItem[]).map((item, idx) => (
                <View style={{ marginBottom: 10 }}>
                  <Text style={{ fontSize: 12 }}>
                    {idx + 1}º) {item.medicine} {item.dosage}
                  </Text>
                  <Text style={{ fontSize: 12 }}>
                    Uso {item.time} por {item.duration}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </PrescriptionFileBasis>
      )}
      {title === 'EXAME' && (
        <PrescriptionFileBasis
          patient={patient}
          doctor={doctor}
          title={title}
          dateDay={dateDay}
          dateMonth={dateMonth}
          dateYear={dateYear}
        >
          <View style={{ marginTop: 30 }}>
            <Text style={{ fontSize: 12 }}>
              Solicito os seguintes exames do tipo {examItem?.type} {':'}{' '}
            </Text>
            <View style={{ paddingLeft: '5px' }}>
              {examItem?.solicitations?.map((item, idx) => (
                <View style={{ marginBottom: 10 }}>
                  <Text style={{ fontSize: 12 }}>{item}</Text>
                </View>
              ))}
            </View>
          </View>
        </PrescriptionFileBasis>
      )}
      {title === 'ATESTADO' && (
        <PrescriptionFileBasis
          patient={patient}
          doctor={doctor}
          title={title}
          dateDay={dateDay}
          dateMonth={dateMonth}
          dateYear={dateYear}
        >
          <View style={{ marginTop: 30 }}>
            <Text style={{ fontSize: 12 }}>
              O paciente {patient} deve ficar afastado por{' '}
              {medicalCertificate?.period}. {medicalCertificate?.reason}
            </Text>
          </View>
        </PrescriptionFileBasis>
      )}
    </>
  )
}
