import { useCallback, useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'

import { ModalContainer } from '@components/ModalContainer/modal-container'
import { COMMON } from '@config/common'
import { ServiceError } from '@model/service-error/service-error'

import { useStyles } from './error-modal.styles'
import { parseErrorMessage } from './utils/parse-error-message/parse-error-message'

interface Props {
  error: ServiceError | SerializedError | FetchBaseQueryError | null
}

const { CONTACT_EMAIL, CONTACT_PHONE } = COMMON

export const ErrorModal = ({ error }: Props) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const { classes } = useStyles()
  const parsedMessage = error ? parseErrorMessage(error) : ''

  useEffect(() => {
    setModalOpen(Boolean(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeModal = useCallback(() => {
    setModalOpen(false)
  }, [])

  return (
    <ModalContainer
      isOpen={isModalOpen}
      id="error-modal"
      handleModalClose={closeModal}
      title="Atenção!"
      closeButton
    >
      <Box className={classes.container}>
        <Typography className={classes.modalText}>{parsedMessage}</Typography>
        <Typography className={classes.modalHelperText}>
          Precisa de ajuda? Entre em contato com a nossa equipe de suporte{' '}
          <strong>{CONTACT_EMAIL}</strong> ou <strong>{CONTACT_PHONE}</strong>.
        </Typography>
      </Box>
    </ModalContainer>
  )
}
