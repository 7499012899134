import { useState } from 'react'

import { Box, TextField, TextFieldProps } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ptBR from 'date-fns/locale/pt-BR'
import { Form, Formik } from 'formik'
import usePlacesAutocomplete from 'use-places-autocomplete'
import * as Yup from 'yup'

import { AddressForm } from '@components/AddressForm/address-form'
import { ControlledGeneralSelect } from '@components/ControlledGeneralSelect/controlled-general-select'
import { ErrorModal } from '@components/ErrorModal/error-modal'
import { FormButton } from '@components/FormButton/form-button'
import { Loader } from '@components/Loader/loader'
import { IAddress, IDoctor, IFullAddress } from '@model/doctor/doctor'
import { IHealthPlan, ISpeciality } from '@model/health-info/health-info'
import { useUpdateProfileMutation } from '@slices/profile/profile.api'
import { useGetHealthInfoQuery } from '@slices/searches/searches.api'
import { doctorSelector, updateDoctor } from '@slices/session/session.slice'
import { useAppDispatch, useAppSelector } from '@store/store'
import { normalizedDate } from '@utils/date/date'
import { zipCodeRegex } from '@utils/zipcode/zipcode'

import { useStyles } from './personal-form.styles'

const validationSchema = Yup.object().shape({
  clinicName: Yup.string().required('Esse campo é obrigatório'),
  specialities: Yup.array().required('Esse campo é obrigatório'),
  healthPlans: Yup.array(),
  birthDate: Yup.date(),
  fullAddress: Yup.object()
    .shape({ placeId: Yup.string(), description: Yup.string() })
    .required('Esse campo é obrigatório'),
  uf: Yup.string().required('Esse campo é obrigatório'),
  city: Yup.string().required('Esse campo é obrigatório'),
  district: Yup.string().required('Esse campo é obrigatório'),
  street: Yup.string().required('Esse campo é obrigatório'),
  number: Yup.number().required('Esse campo é obrigatório'),
  zipcode: Yup.string()
    .required('Esse campo é obrigatório')
    .matches(
      zipCodeRegex(),
      'O CEP precisa estar no seguinte formato: XXXXX-XXX',
    ),
})

type FormData = Pick<IDoctor, 'specialities' | 'healthPlans' | 'birthDate'> &
  IAddress

export const PersonalForm = () => {
  const { classes } = useStyles()
  const [coordinates, setCoordinates] = useState<number[]>([])
  const { address, specialities, healthPlans, birthDate } =
    useAppSelector(doctorSelector)
  const [
    updateProfileMutation,
    { isLoading: isUpdatingDoctorProfile, error: updatingProfileError },
  ] = useUpdateProfileMutation()
  const dispatch = useAppDispatch()

  const {
    data: healthInfoData,
    isLoading: isGettingHealthInfo,
    error: gettingHealthInfoError,
  } = useGetHealthInfoQuery()

  const {
    suggestions: { data: placesData },
    setValue,
  } = usePlacesAutocomplete()

  const error = gettingHealthInfoError || updatingProfileError

  async function handleSubmit(data: FormData) {
    const payload = {
      coordinates,
      address: {
        uf: data.uf,
        city: data.city,
        district: data.district,
        street: data.street,
        number: data.number,
        zipcode: data.zipcode,
        clinicName: data.clinicName,
        fullAddress: data.fullAddress,
      },
      specialities: data.specialities,
      healthPlans: data.healthPlans,
      birthDate: data.birthDate,
    }

    updateProfileMutation({ ...payload })
    dispatch(updateDoctor(payload))
  }

  function handleNewCoordinates(newCoordinates: number[]) {
    setCoordinates(newCoordinates)
  }

  if (isGettingHealthInfo) {
    return <Loader />
  }

  const fullAddressOptions = placesData.length
    ? placesData.map(item => ({
        ...item,
        placeId: item.place_id,
      }))
    : []

  return (
    <>
      <Formik
        initialValues={{
          ...address,
          specialities,
          healthPlans,
          birthDate,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          values,
          setFieldValue,
          setFieldTouched,
          touched,
          isValid,
        }) => (
          <Form className={classes.form}>
            <TextField
              error={Boolean(errors['clinicName'] && touched['clinicName'])}
              name="clinicName"
              label="Nome da clínica"
              helperText={touched['clinicName'] ? errors['clinicName'] : null}
              className={classes.input}
              variant="outlined"
              value={values['clinicName']}
              onBlur={() => setFieldTouched('clinicName', true)}
              onChange={e => setFieldValue('clinicName', e.target.value)}
            />
            {healthInfoData?.specialities && (
              <ControlledGeneralSelect
                options={healthInfoData?.specialities}
                multiple
                getOptionLabel={option => (option as ISpeciality).name}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                textFieldParams={{
                  name: 'specialities',
                  label: 'Especialidades',
                  className: classes.input,
                  helperText: 'Selecione ao menos uma especialidade',
                }}
              />
            )}
            {healthInfoData?.healthPlans && (
              <ControlledGeneralSelect
                options={healthInfoData?.healthPlans}
                multiple
                getOptionLabel={option => (option as IHealthPlan).name}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                textFieldParams={{
                  name: 'healthPlans',
                  label: 'Planos de saúde',
                  className: classes.input,
                  helperText: 'Selecione os planos de saúde aceitos',
                }}
              />
            )}

            <ControlledGeneralSelect
              options={fullAddressOptions}
              freeSolo
              onChange={(_, newValue) => {
                setValue((newValue as IFullAddress).placeId)
                setFieldValue('fullAddress', newValue)
              }}
              noOptionsText="Endereço não encontrado"
              getOptionLabel={newValue => {
                const option = newValue as IFullAddress

                return option.description ? option.description : ''
              }}
              isOptionEqualToValue={(option, value) =>
                option.placeId === value.placeId
              }
              textFieldParams={{
                name: 'fullAddress',
                label: 'Endereço',
                className: classes.input,
                helperText: 'Insira um endereço',
                onChange: e => setValue(e.target.value),
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DesktopDatePicker
                label=""
                value={values['birthDate'] ?? null}
                onChange={newValue => {
                  setFieldValue(
                    'birthDate',
                    normalizedDate(newValue as Date, false),
                  )
                }}
                renderInput={params => (
                  <TextField
                    {...(params as TextFieldProps)}
                    fullWidth
                    variant="outlined"
                    label="Data de nascimento"
                  />
                )}
              />
            </LocalizationProvider>
            <AddressForm
              placeId={
                values['fullAddress']?.placeId
                  ? values['fullAddress']?.placeId
                  : ''
              }
              errors={errors}
              touched={touched}
              values={values}
              handleNewCoordinates={handleNewCoordinates}
            />

            <Box className={classes.buttonContainer}>
              <FormButton
                disabled={!isValid}
                isLoading={isUpdatingDoctorProfile}
                type="submit"
              >
                Salvar alterações
              </FormButton>
            </Box>
          </Form>
        )}
      </Formik>
      {error && <ErrorModal error={error} />}
    </>
  )
}
