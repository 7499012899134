import { useEffect } from 'react'

import { Box, TextField, TextFieldProps } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { ControlledGeneralSelect } from '@components/ControlledGeneralSelect/controlled-general-select'
import { FormButton } from '@components/FormButton/form-button'
import { Loader } from '@components/Loader/loader'
import { ModalContainer } from '@components/ModalContainer/modal-container'
import { ptBR } from '@config/datetime'
import { PAYMENT_METHOD_OPTIONS } from '@model/appointment/appointment'
import { useLazyGetAppointmentAvaliabilityQuery } from '@slices/appointments/appointments.api'
import { normalizedDate } from '@utils/date/date'
import { formatCPF } from '@utils/format-cpf/format-cpf'
import { formatPhoneNumber } from '@utils/phone-number/phone-number'

import { useStyles } from './create-appointment-modal.styles'
import { ICreateAppointmentForm } from '../../model/create-appointment-form/create-appointment-form'
import { SearchPatientInput } from '../SearchPatientInput/search-patient-input'

interface Props {
  modalIsOpen: boolean
  handleSubmit(data: ICreateAppointmentForm): void
  handleCloseModal(): void
  date: string
}

const validationSchema = Yup.object().shape({
  patient: Yup.object()
    .shape({ _id: Yup.string(), title: Yup.string() })
    .required('Esse campo é obrigatório'),
  email: Yup.string()
    .email('Insira um endereço de email válido')
    .required('Esse campo é obrigatório'),
  paymentMethod: Yup.object().required('Esse campo é obrigatório'),
  date: Yup.date().required('Esse campo é obrigatório'),
  time: Yup.string().required('Esse campo é obrigatório'),
})

export const CreateAppointmentModal = ({
  modalIsOpen,
  handleCloseModal,
  handleSubmit,
  date,
}: Props) => {
  const { classes } = useStyles()

  const [
    getAppointmentAvaliabilityCall,
    {
      data: getAppointmentAvaliability,
      isLoading: isListingDoctorAvailableHours,
    },
  ] = useLazyGetAppointmentAvaliabilityQuery()

  useEffect(() => {
    if (modalIsOpen) {
      getAppointmentAvaliabilityCall({ date })
    }
  }, [getAppointmentAvaliabilityCall, date, modalIsOpen])

  return (
    <ModalContainer
      isOpen={modalIsOpen}
      handleModalClose={handleCloseModal}
      title="Marcação de consulta"
      id="new-appointment"
      closeButton
    >
      <Formik
        initialValues={{
          patient: { _id: '', title: '' },
          email: '',
          phoneNumber: '',
          cpf: '',
          paymentMethod: { value: '', label: '' },
          date: normalizedDate(new Date(), false) as Date,
          time: getAppointmentAvaliability?.availableHours.length
            ? getAppointmentAvaliability?.availableHours[0]
            : '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, values, setFieldValue, setFieldTouched, touched }) =>
          isListingDoctorAvailableHours ? (
            <Loader />
          ) : (
            <Form className={classes.form} id="login-form">
              <SearchPatientInput />
              <TextField
                error={Boolean(errors['email'] && touched['email'])}
                name="email"
                placeholder="E-mail"
                helperText={touched['email'] ? errors['email'] : null}
                className={classes.input}
                variant="standard"
                value={values['email']}
                onBlur={() => setFieldTouched('email', true)}
                onChange={e => setFieldValue('email', e.target.value)}
              />
              <TextField
                error={Boolean(errors['phoneNumber'] && touched['phoneNumber'])}
                name="phoneNumber"
                placeholder="Telefone"
                helperText={
                  touched['phoneNumber'] ? errors['phoneNumber'] : null
                }
                variant="standard"
                className={classes.input}
                value={values['phoneNumber']}
                onBlur={() => setFieldTouched('phoneNumber', true)}
                onChange={e =>
                  setFieldValue(
                    'phoneNumber',
                    formatPhoneNumber(e.target.value),
                  )
                }
              />
              <TextField
                error={Boolean(errors['cpf'] && touched['cpf'])}
                name="cpf"
                placeholder="CPF"
                helperText={touched['cpf'] ? errors['cpf'] : null}
                variant="standard"
                className={classes.input}
                value={values['cpf']}
                onBlur={() => setFieldTouched('cpf', true)}
                onChange={e => setFieldValue('cpf', formatCPF(e.target.value))}
              />
              <ControlledGeneralSelect
                options={PAYMENT_METHOD_OPTIONS}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value && option.label === value.label
                }
                onChange={(_, newValue) =>
                  setFieldValue('paymentMethod', newValue)
                }
                getOptionLabel={option =>
                  (option as typeof PAYMENT_METHOD_OPTIONS[number]).label
                }
                textFieldParams={{
                  name: 'paymentMethod',
                  placeholder: 'Pagamento',
                  className: classes.input,
                  helperText: 'Selecione um método de pagamento',
                  variant: 'standard',
                }}
              />

              <Box className={classes.timePickerContainer}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={ptBR}
                >
                  <DesktopDatePicker
                    label=""
                    value={values['date']}
                    onChange={async newValue => {
                      const parsed = newValue ?? new Date()

                      const normalizedValue = normalizedDate(
                        parsed,
                        false,
                      ) as Date

                      setFieldValue('date', normalizedValue)
                      await getAppointmentAvaliabilityCall({
                        date: normalizedValue?.toISOString(),
                      })
                    }}
                    renderInput={(params: TextFieldProps) => (
                      <TextField variant="standard" {...params} />
                    )}
                    minDate={new Date()}
                  />
                </LocalizationProvider>
              </Box>
              {getAppointmentAvaliability?.availableHours &&
                getAppointmentAvaliability?.availableHours?.length > 0 && (
                  <>
                    <ControlledGeneralSelect
                      options={getAppointmentAvaliability?.availableHours}
                      textFieldParams={{
                        name: 'time',
                        placeholder: 'Hora',
                        className: classes.input,
                        helperText: 'Selecione um horário',
                        variant: 'standard',
                      }}
                    />
                  </>
                )}

              <FormButton
                type="submit"
                isLoading={isListingDoctorAvailableHours}
                fullWidth
              >
                Marcar consulta
              </FormButton>
            </Form>
          )
        }
      </Formik>
    </ModalContainer>
  )
}
