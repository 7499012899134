import { Box } from '@mui/material'

import { FormButton } from '@components/FormButton/form-button'

import { useStyles } from './work-windows-tab.styles'

export const WorkWindowsTab = () => {
  const { classes } = useStyles()
  // const { workWindows } = useAppSelector(doctorProfileSelector)
  // const dispatch = useDispatch()
  // const [
  //   updateProfileMutation,
  //   { isLoading: isUpdatingProfile, error: updatingProfile },
  // ] = useUpdateProfileMutation()

  async function handleSubmit() {
    // await updateProfileMutation({ workWindows }).unwrap()
    // dispatch(updateDoctor({ workWindows }))
  }

  return (
    <>
      <Box className={classes.container}>
        {/* {(
          Object.keys(workWindows) as Array<typeof IWorkWindowMapKey[number]>
        ).map(day => (
          <WorkWindowsForm day={day} workWindow={workWindows[day]} />
        ))} */}

        <Box className={classes.buttonContainer}>
          <FormButton onClick={handleSubmit}>Salvar alterações</FormButton>
        </Box>
      </Box>
      {/* {updatingProfile && <ErrorModal error={updatingProfile} />} */}
    </>
  )
}
