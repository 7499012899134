import { useState } from 'react'

import { Box, TextField, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import usePlacesAutocomplete from 'use-places-autocomplete'

import { AddressForm } from '@components/AddressForm/address-form'
import { ControlledGeneralSelect } from '@components/ControlledGeneralSelect/controlled-general-select'
import { ErrorModal } from '@components/ErrorModal/error-modal'
import { FormButton } from '@components/FormButton/form-button'
import { Loader } from '@components/Loader/loader'
import { ModalContainer } from '@components/ModalContainer/modal-container'
import { IAddress, IFullAddress } from '@model/doctor/doctor'
import { ISpeciality } from '@model/health-info/health-info'
import { useGetHealthInfoQuery } from '@slices/searches/searches.api'
import { useResendConfirmationEmailMutation } from '@slices/session/session.api'
import { useSignUpMutation } from '@slices/sign-up/sign-up.api'
import { ISignUp } from '@slices/sign-up/sign-up.api.model'
import { onlyDigits } from '@utils/only-digits/only-digits'
import { formatPhoneNumber } from '@utils/phone-number/phone-number'

import { useSignUpForm } from './hooks/use-sign-up-form'
import { useStyles } from './sign-up.styles'

type FormData = Omit<ISignUp.Create.Request, 'coordinates' | 'address'> &
  IAddress

export const SignUp = () => {
  const { classes } = useStyles()
  const [coordinates, setCoordinates] = useState<number[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()
  const { validationSchema, initialValues } = useSignUpForm()

  const {
    data: healthInfoData,
    isLoading: isGettingHealthInfo,
    error: gettingHealthInfoError,
  } = useGetHealthInfoQuery()

  const [
    signUpDoctor,
    { isLoading: isDoctorSigningUp, error: signingUpError },
  ] = useSignUpMutation()

  const [resendConfirmationEmail, { error: resendingConfirmationEmailError }] =
    useResendConfirmationEmailMutation()

  const {
    suggestions: { data: placesData },
    setValue,
  } = usePlacesAutocomplete()

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  function handleNewCoordinates(newCoordinates: number[]) {
    setCoordinates(newCoordinates)
  }

  function navigateToLogin() {
    navigate('/')
  }

  async function handleResendConfirmationEmail(email: string) {
    await resendConfirmationEmail({ email }).unwrap()
  }

  if (isGettingHealthInfo) {
    return <Loader />
  }

  async function handleSubmit(data: FormData) {
    const address = {
      uf: data.uf,
      city: data.city,
      district: data.district,
      street: data.street,
      number: data.number,
      zipcode: data.zipcode,
      clinicName: data.clinicName,
      fullAddress: data.fullAddress,
    }

    await signUpDoctor({
      name: data.name,
      email: data.email,
      password: data.password,
      phoneNumber: data.phoneNumber,
      crmNumber: data.crmNumber,
      specialities: data.specialities,
      address,
      coordinates,
    }).unwrap()
    openModal()
  }

  const fullAddressOptions = placesData.length
    ? placesData.map(item => ({
        ...item,
        placeId: item.place_id,
      }))
    : []

  const error =
    signingUpError || gettingHealthInfoError || resendingConfirmationEmailError

  return (
    <>
      <Box className={classes.header} />
      <Box className={classes.loginTextContainer}>
        <Typography
          variant="overline"
          className={`${classes.loginText} ${classes.loginTextColor}`}
        >
          Você já tem uma conta?
        </Typography>
        <Link to="/" className={classes.link}>
          <Typography
            variant="overline"
            className={classes.loginText}
            color="primary"
          >
            Entre agora
          </Typography>
        </Link>
      </Box>
      <Box className={classes.mainContainer}>
        <Box className={classes.mainContentContainer}>
          <Typography className={classes.title}>
            Cadastre suas informações abaixo
          </Typography>
          <Typography className={classes.subtitle}>
            Algumas dessas informações ficaram visíveis para os seus pacientes
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              values,
              setFieldValue,
              setFieldTouched,
              touched,
              isValid,
              dirty,
            }) => (
              <Form className={classes.form}>
                <TextField
                  error={Boolean(errors['name'] && touched['name'])}
                  name="name"
                  label="Nome"
                  helperText={touched['name'] ? errors['name'] : null}
                  className={classes.input}
                  variant="outlined"
                  value={values['name']}
                  onBlur={() => setFieldTouched('name', true)}
                  onChange={e => setFieldValue('name', e.target.value)}
                />
                <TextField
                  error={Boolean(errors['email'] && touched['email'])}
                  name="email"
                  label="E-mail"
                  helperText={touched['email'] ? errors['email'] : null}
                  className={classes.input}
                  variant="outlined"
                  value={values['email']}
                  onBlur={() => setFieldTouched('email', true)}
                  onChange={e => setFieldValue('email', e.target.value)}
                />
                <TextField
                  error={Boolean(errors['password'] && touched['password'])}
                  name="password"
                  label="Senha"
                  type="password"
                  helperText={touched['password'] ? errors['password'] : null}
                  className={classes.input}
                  variant="outlined"
                  value={values['password']}
                  onBlur={() => setFieldTouched('password', true)}
                  onChange={e => setFieldValue('password', e.target.value)}
                />
                <TextField
                  error={Boolean(
                    errors['phoneNumber'] && touched['phoneNumber'],
                  )}
                  name="phoneNumber"
                  label="Número para contato"
                  helperText={
                    touched['phoneNumber'] ? errors['phoneNumber'] : null
                  }
                  className={classes.input}
                  variant="outlined"
                  value={values['phoneNumber']}
                  onBlur={() => setFieldTouched('phoneNumber', true)}
                  onChange={e =>
                    setFieldValue(
                      'phoneNumber',
                      formatPhoneNumber(e.target.value),
                    )
                  }
                />
                <TextField
                  error={Boolean(errors['crmNumber'] && touched['crmNumber'])}
                  name="crmNumber"
                  label="CRM"
                  helperText={
                    touched['crmNumber'] && errors['crmNumber']
                      ? 'O CRM  deve ser numérico'
                      : null
                  }
                  className={classes.input}
                  variant="outlined"
                  value={values['crmNumber']}
                  onBlur={() => setFieldTouched('crmNumber', true)}
                  onChange={e =>
                    setFieldValue('crmNumber', onlyDigits(e.target.value))
                  }
                />
                <TextField
                  error={Boolean(errors['clinicName'] && touched['clinicName'])}
                  name="clinicName"
                  label="Nome da clínica"
                  helperText={
                    touched['clinicName'] ? errors['clinicName'] : null
                  }
                  className={classes.input}
                  variant="outlined"
                  value={values['clinicName']}
                  onBlur={() => setFieldTouched('clinicName', true)}
                  onChange={e => setFieldValue('clinicName', e.target.value)}
                />
                {healthInfoData?.specialities && (
                  <ControlledGeneralSelect
                    options={healthInfoData.specialities}
                    multiple
                    getOptionLabel={option => (option as ISpeciality).name}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    textFieldParams={{
                      name: 'specialities',
                      label: 'Especialidades',
                      className: classes.input,
                      helperText: 'Selecione ao menos uma especialidade',
                    }}
                  />
                )}
                <ControlledGeneralSelect
                  options={fullAddressOptions}
                  freeSolo
                  onChange={(_, newValue) => {
                    setValue((newValue as IFullAddress).placeId)
                    setFieldValue('fullAddress', newValue)
                  }}
                  noOptionsText="Endereço não encontrado"
                  getOptionLabel={newValue => {
                    const option = newValue as IFullAddress

                    return option.description ? option.description : ''
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.placeId === value.placeId
                  }
                  textFieldParams={{
                    name: 'fullAddress',
                    label: 'Endereço',
                    className: classes.input,
                    helperText: 'Insira um endereço',
                    onChange: e => setValue(e.target.value),
                  }}
                />
                <AddressForm
                  placeId={
                    values['fullAddress']?.placeId
                      ? values['fullAddress']?.placeId
                      : ''
                  }
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleNewCoordinates={handleNewCoordinates}
                />

                <Box className={classes.buttonContainer}>
                  <FormButton
                    disabled={!isValid || !dirty}
                    type="submit"
                    isLoading={isDoctorSigningUp}
                  >
                    Cadastrar
                  </FormButton>
                </Box>
                <ModalContainer
                  isOpen={isModalOpen}
                  closeButton
                  id="sign-up-modal-success"
                  title="Sucesso!"
                  handleModalClose={closeModal}
                >
                  <>
                    <Typography>
                      Estamos validando suas informações. Mas, enquanto isso,
                      você receberá um e-mail de confirmação na sua caixa de
                      entrada!
                    </Typography>
                    <Typography
                      onClick={() =>
                        handleResendConfirmationEmail(values.email)
                      }
                      className={classes.resendEmailText}
                    >
                      Renviar e-mail
                    </Typography>
                    <FormButton onClick={navigateToLogin}>Entendi</FormButton>
                  </>
                </ModalContainer>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>

      {error && <ErrorModal error={error} />}
    </>
  )
}
