import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  subtitle: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.common.black,
  },
  form: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  input: {
    width: '100%',
    margin: `${theme.spacing(1, 0)} !important`,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))
