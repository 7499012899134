import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

interface Props {
  data: { y: number; name: string }[]
  name: string
}

export const PizzaGraph = ({ data, name }: Props) => {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        credits: {
          enabled: false,
        },
        chart: {
          type: 'pie',
          width: 110,
          height: 110,
        },
        title: {
          text: '',
        },
        tooltip: {
          pointFormat:
            '{series.name}: <b>{point.y}</b> ({point.percentage:.1f} %)',
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
          },
        },
        colors: ['#1273eb', '#CFDCF1'],
        series: [
          {
            dataLabels: {
              enabled: false,
            },
            minPointSize: 40,
            innerSize: '70%',
            name,
            data,
          },
        ],
      }}
    />
  )
}
