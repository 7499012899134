import { ChangeEvent } from 'react'

import { Tab, Tabs } from '@mui/material'

import { AppointmentTabs } from '@slices/appointments/appointments.model'
import {
  changeAppointmentTab,
  currentAppointmentTabSelector,
} from '@slices/appointments/appointments.slice'
import { useAppDispatch, useAppSelector } from '@store/store'

import { useStyles } from './appointments-tabs-container.styles'
import { ConfirmationTab } from '../ConfirmationTab/confirmation-tab'
import { MedicalAnalysisTab } from '../MedicalAnalysisTab/medical-analysis-tab'
import { PrescriptionsTab } from '../PrescriptionsTab/prescriptions-tab'

export const AppointmentTabsContainer = () => {
  const currentAppointmentTab = useAppSelector(currentAppointmentTabSelector)
  const dispatch = useAppDispatch()
  const { classes } = useStyles()

  function handleTabChange(_: ChangeEvent<unknown>, newValue: AppointmentTabs) {
    dispatch(changeAppointmentTab({ appointmentTab: newValue }))
  }

  return (
    <>
      <Tabs
        value={currentAppointmentTab}
        textColor="primary"
        onChange={handleTabChange}
        classes={{ indicator: classes.indicator, root: classes.root }}
        variant="scrollable"
      >
        <Tab
          className={classes.tab}
          value={AppointmentTabs.Medical}
          label="Análise Médica"
        />
        <Tab
          className={classes.tab}
          value={AppointmentTabs.Prescriptions}
          label="Prescrições"
        />
        <Tab
          className={classes.tab}
          value={AppointmentTabs.Confirmation}
          label="Confirmação"
        />
      </Tabs>
      {currentAppointmentTab === AppointmentTabs.Medical && (
        <MedicalAnalysisTab />
      )}
      {currentAppointmentTab === AppointmentTabs.Prescriptions && (
        <PrescriptionsTab />
      )}
      {currentAppointmentTab === AppointmentTabs.Confirmation && (
        <ConfirmationTab />
      )}
    </>
  )
}
