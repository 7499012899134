import { CSSProperties } from '@mui/styled-engine'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  indicator: {
    height: '0.2rem',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '0.2rem',
      background: theme.palette.primary.light,
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    },
  },
  tab: {
    ...(theme.typography.body1 as CSSProperties),
    fontWeight: 'bold',
    fontSize: '13px',
    textTransform: 'none',
  },
  root: {
    height: '2.5rem',
    '& .MuiTabs-flexContainer': { justifyContent: 'flex-start' },
  },
}))
