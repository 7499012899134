import { CSSProperties } from '@mui/styled-engine'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '10rem',
    margin: theme.spacing(0, 2),
    overflow: 'hidden',
    flexShrink: 0,
    height: '10rem',
  },
  contentContainer: {
    width: '100%',
    overflow: 'hidden',
    alignItems: 'center',
    marginTop: 5,
  },
  numberOfAppointment: {
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.3, 2),
    color: theme.palette.primary.dark,
    alignSelf: 'center',
    fontSize: '13px',
    textAlign: 'center',
  },
  numberOfAppointmentFinished: {
    border: `1px solid ${'#bbd8f6'}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.3, 2),
    color: theme.palette.primary.dark,
    alignSelf: 'center',
    backgroundColor: '#bbd8f6',
    fontSize: '13px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  text: {
    ...(theme.typography.caption as CSSProperties),
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
  },
  status: { marginLeft: 5 },
  title: { color: theme.palette.primary.dark },
  confirmed: { color: theme.palette.success.light },
  progress: { color: theme.palette.primary.light },
  canceled: { color: theme.palette.error.dark },
  checked: { color: theme.palette.success.dark },
  realized: { color: theme.palette.primary.dark },
  otherwise: { color: theme.palette.secondary.dark },
  button: {
    ...(theme.typography.button as CSSProperties),
    textTransform: 'none',
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(0.2),
    color: theme.palette.common.white,
    alignSelf: 'center',
    width: '8rem',
    top: 5,
    fontSize: 14,
  },
}))
