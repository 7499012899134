import { CSSProperties } from 'react'

import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    margin: theme.spacing(1, 0),
  },
  value: {
    marginLeft: theme.spacing(2),
    ...(theme.typography.body2 as CSSProperties),
    fontWeight: theme.typography.fontWeightBold,
  },
  icon: {
    color: theme.palette.primary.light,
  },
}))
