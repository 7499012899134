import { ReactNode } from 'react'

import { Box, Typography, Divider } from '@mui/material'
import {
  BsCalendarEvent,
  BsClipboard,
  BsFileEarmarkText,
  BsShield,
} from 'react-icons/bs'

import { useStyles } from './table-summary.styles'

interface Props {
  icon: 'calendar' | 'text' | 'clipboard' | 'shield'
  title: string
  children: ReactNode
}

export const TableSummary = ({ icon, title, children }: Props) => {
  const { classes } = useStyles()

  const Icon = () => {
    switch (icon) {
      case 'calendar':
        return <BsCalendarEvent data-testid="icon" className={classes.icon} />
      case 'text':
        return <BsFileEarmarkText data-testid="icon" className={classes.icon} />
      case 'clipboard':
        return <BsClipboard data-testid="icon" className={classes.icon} />
      case 'shield':
        return <BsShield data-testid="icon" className={classes.icon} />
      default:
        return <BsCalendarEvent data-testid="icon" className={classes.icon} />
    }
  }

  return (
    <Box className={classes.table}>
      <Box className={classes.header}>
        <Typography className={classes.title}>{title}</Typography>
        <Icon />
      </Box>
      <Divider />
      {children}
    </Box>
  )
}
