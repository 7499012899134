import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  root: {
    padding: '10em 15em',
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
  },
  img: {
    width: '20em',
    height: '20em',
  },
  icon: {
    width: '34px',
    height: '34px',
  },
  title: {
    fontSize: '2.5em',
    textAlign: 'center',
    fontWeight: 300,
  },
  highlight: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  content: {
    textAlign: 'center',
    fontWeight: 300,
  },
  button: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    width: '100%',
    fontSize: '1em',
    borderRadius: '0.5em',
    color: 'white',
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
}))
