import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: { marginTop: theme.spacing(3) },
  gridContainer: { margin: 0, width: '100%' },
  gridItem: {
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
    width: '100%',
  },
}))
