import { Box, Grid, Typography } from '@mui/material'

import empty from '@assets/empty.png'
import { AnalysisCard } from '@components/AnalysisCard/analysis-card'
import { IFormattedAppointment } from '@model/appointment/appointment'

import { useStyles } from './prescriptions-analysis.styles'

type Props = Pick<
  IFormattedAppointment,
  'prescriptions' | 'exams' | 'patient' | 'medicalCertificate'
>

export const PrescriptionsAnalysis = ({
  prescriptions,
  exams,
  patient,
  medicalCertificate,
}: Props) => {
  const { classes } = useStyles()

  if (!prescriptions || !exams || !medicalCertificate)
    return (
      <Box className={classes.emptyContainer}>
        <img src={empty} className={classes.emptyImage} alt="Empty" />
        <Typography className={classes.emptyTitle}>
          Nenhuma prescrição foi realizada
        </Typography>
      </Box>
    )

  return (
    <Grid container spacing={2} className={classes.container}>
      {prescriptions.length > 0 && (
        <Grid item xs={12} md={6} lg={4}>
          <AnalysisCard
            title="PRESCRIÇÕES"
            analisys={prescriptions}
            patientName={patient.name}
            data-testid="prescriptions"
          />
        </Grid>
      )}
      {exams.length > 0 && (
        <Grid item xs={12} md={6} lg={4}>
          <AnalysisCard
            title="EXAMES"
            analisys={exams}
            patientName={patient.name}
            data-testid="exams"
          />
        </Grid>
      )}
      {medicalCertificate.reason.length > 0 ||
        (medicalCertificate.period.length > 0 && (
          <Grid item xs={12} md={6} lg={4}>
            <AnalysisCard
              title="ATESTADO"
              analisys={medicalCertificate}
              patientName={patient.name}
              data-testid="certificate"
            />
          </Grid>
        ))}
    </Grid>
  )
}
