import { ISearches } from '@slices/searches/searches.model'
import { formatTime } from '@utils/date/date'
import { numberFormatter } from '@utils/number/number'
import { translateWeekday } from '@utils/translate-weekday/translate-weekday'

const newAppointmentsFormatter = numberFormatter({
  minimumIntegerDigits: 2,
})

export const formatHomeData = (data: ISearches.DashboardHome.Response) => {
  const formattedNewAppointments = data.appointmentsOfWeekInfo.newAppointments
    ? newAppointmentsFormatter.format(
        data.appointmentsOfWeekInfo.newAppointments as number,
      )
    : '0'

  const formattedConfirmedAppointments = data.appointmentsOfDayInfo.Confirmed
    ? newAppointmentsFormatter.format(
        data.appointmentsOfDayInfo.Confirmed as number,
      )
    : '0'

  const formattedNextAppointmentHour = data.nextAppointment
    ? formatTime(data.nextAppointment)
    : '-'

  const scheduledConfirmedData = [
    {
      y: data.appointmentsOfDayInfo.Confirmed as number,
      name: 'Confirmadas',
    },
    {
      y: data.appointmentsOfDayInfo.Scheduled as number,
      name: 'Agendadas',
    },
  ]

  const appointmentsByWeekDay = (
    Object.keys(data.appointmentsOfWeekInfo) as [number | 'newAppointments']
  )?.reduce<{ y: number; name: string }[]>((prev, curr) => {
    if (curr === 'newAppointments') {
      return prev
    }

    return [
      ...prev,
      {
        name: translateWeekday(curr.toString()),
        y: data.appointmentsOfWeekInfo[curr] as number,
      },
    ]
  }, [])

  return {
    formattedNewAppointments,
    formattedNextAppointmentHour,
    scheduledConfirmedData,
    appointmentsByWeekDay,
    formattedConfirmedAppointments,
  }
}
