import { IAppointments } from '@slices/appointments/appointments.model'
import { normalizedDate } from '@utils/date/date'

export const formatEvents = (
  appointmentsList?: IAppointments.Get.FormattedResponse['appointments'],
): IAppointments.Get.FormattedResponse['appointments'] => {
  if (!appointmentsList) return []

  const appointments = appointmentsList?.map(item => ({
    ...item,
    start: new Date(item.start),
    end: new Date(item.end),
  }))

  return [
    ...appointments,
    {
      title: 'Hoje',
      start: normalizedDate(undefined, false),
      end: normalizedDate(undefined, false),
      allDay: true,
    },
  ]
}
