import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '20px',
    fontWeight: 300,
    textAlign: 'left',
  },
  span: {
    fontWeight: 500,
    color: 'hsla(213, 86%, 50%, 1)',
  },
  subtitle: {
    fontSize: '12.63px',
    fontWeight: 300,
    textAlign: 'left',
    width: '315px',
  },
  icon: {
    width: '15px',
    height: '15px',
    marginLeft: '5px',
  },
}))
