import { Box, Grid, Typography } from '@mui/material'

import empty from '@assets/empty.png'
import { AnalysisCard } from '@components/AnalysisCard/analysis-card'
import { useStyles } from '@components/AppointmentMedicalAnalysis/appointment-medical-analysis.styles'
import { IFormattedAppointment } from '@model/appointment/appointment'

type Props = Pick<
  IFormattedAppointment,
  | 'anamnesis'
  | 'physicalAnalysis'
  | 'clinicalAnalysis'
  | 'recommendations'
  | 'patient'
>

export const AppointmentMedicalAnalysis = ({
  anamnesis,
  physicalAnalysis,
  clinicalAnalysis,
  recommendations,
  patient,
}: Props) => {
  const { classes } = useStyles()

  if (!anamnesis || !recommendations || !physicalAnalysis || !clinicalAnalysis)
    return (
      <Box className={classes.emptyContainer} data-testid="empty">
        <img src={empty} className={classes.emptyImage} alt="Empty" />
        <Typography className={classes.emptyTitle}>
          Nenhuma análise realizada
        </Typography>
      </Box>
    )

  return (
    <Grid container spacing={2} className={classes.container}>
      {anamnesis[0]?.complaint.length > 0 && (
        <Grid item xs={12} md={6} lg={6}>
          <AnalysisCard
            title="ANAMNESE"
            analisys={anamnesis}
            patientName={patient?.name}
          />
        </Grid>
      )}
      {recommendations.length > 0 && (
        <Grid item xs={12} md={6} lg={6}>
          <AnalysisCard
            title="RECOMENDAÇÕES"
            analisys={recommendations}
            patientName={patient?.name}
          />
        </Grid>
      )}
      {physicalAnalysis[0]?.index.length > 0 && (
        <Grid item xs={12} md={6} lg={6}>
          <AnalysisCard
            title="ANÁLISE FÍSICA"
            analisys={physicalAnalysis}
            patientName={patient?.name}
            isPhysicalAnalysis={true}
          />
        </Grid>
      )}
      {clinicalAnalysis.description.length > 0 ||
        (clinicalAnalysis.diagnosis.length > 0 && (
          <Grid item xs={12} md={6} lg={6}>
            <AnalysisCard
              title="ANÁLISE CLÍNICA"
              analisys={clinicalAnalysis}
              patientName={patient.name}
            />
          </Grid>
        ))}
    </Grid>
  )
}
