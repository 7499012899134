import { onlyDigits } from '../only-digits/only-digits'

export function phoneNumberRegex(): RegExp {
  return new RegExp('^\\(\\d{2}\\)\\ (\\d{4}|\\d{5})\\-\\d{4}$')
}

export function formatPhoneNumber(value?: string): string {
  if (!value) {
    return ''
  }

  let phoneNumber = onlyDigits(value)

  if (phoneNumber.length < 3) {
    return phoneNumber
  }

  let boundary: number
  if (phoneNumber.length <= 10) {
    boundary = 6
  } else {
    phoneNumber = phoneNumber.substring(0, 11)
    boundary = 7
  }

  const ddd = phoneNumber.substring(0, 2)
  const prefix = phoneNumber.substring(2, boundary)
  const sufix = phoneNumber.substring(boundary)

  let formattedValue: string
  if (sufix === '') {
    formattedValue = `(${ddd}) ${prefix}`
  } else {
    formattedValue = `(${ddd}) ${prefix}-${sufix}`
  }

  return formattedValue
}
