import { CSSProperties } from '@mui/styled-engine'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '4rem',
  },
  loginTextContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
  },
  loginText: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
  loginTextColor: {
    color: theme.palette.grey[600],
  },
  link: {
    marginLeft: theme.spacing(0.5),
  },
  mainContainer: {
    backgroundColor: theme.palette.common.white,
  },
  mainContentContainer: {
    width: '100%',
    maxWidth: '40rem',
    margin: theme.spacing(4, 'auto', 10),
  },
  title: {
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
  },
  subtitle: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.common.black,
  },
  form: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  input: {
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  resendEmailText: {
    marginTop: theme.spacing(1),
    ...(theme.typography.body2 as CSSProperties),
    textAlign: 'center',
    color: theme.palette.primary.dark,
    cursor: 'pointer',
  },
}))
