import { ISearches } from '@slices/searches/searches.model'
import { numberFormatter } from '@utils/number/number'

const newAppointmentsFormatter = numberFormatter({
  minimumIntegerDigits: 2,
})

export const formatPatientsData = (data: ISearches.Patients.Response) => {
  const formattedRegularPatients = data.regularPatients
    ? newAppointmentsFormatter.format(data.regularPatients as number)
    : '0'

  const formattedNewPatients = data.newPatients
    ? newAppointmentsFormatter.format(data.newPatients as number)
    : '0'

  const formattedCount = data.count
    ? newAppointmentsFormatter.format(data.count)
    : '0'

  const regularBluPatientsData = [
    {
      y: data.regularPatients,
      name: 'Recorrentes',
      sliced: true,
      selected: true,
    },
    {
      y: data.bluPatients,
      name: 'Pacientes do app',
    },
  ]

  const newBluPatientsData = [
    {
      y: data.newPatients,
      name: 'Novos',
      sliced: true,
      selected: true,
    },
    {
      y: data.bluPatients,
      name: 'Pacientes do app',
    },
  ]

  return {
    patients: data.patients,
    totalPages: data.totalPages,
    count: data.count,
    formattedCount,
    formattedRegularPatients,
    regularBluPatientsData,
    formattedNewPatients,
    newBluPatientsData,
  }
}
