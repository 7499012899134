import { Stack, Typography } from '@mui/material'
import Lottie from 'react-lottie'

import { useStyles } from '@app/home/components/Lobby/lobby.styles'
import { LobbyPatient } from '@app/home/components/LobbyPatient/lobby-patient'
import Balloons from '@assets/balloons.png'
import * as EmpytLobbyAnimation from '@assets/empty-lobby-animation.json'
import * as LastAppointmentAnimation from '@assets/last-appointment-animation.json'
import { IPatient } from '@model/patient/patient'

interface Props {
  patients: IPatient[]
}

const scroll = {
  overflowX: 'scroll',
}

export const Lobby = ({ patients }: Props) => {
  const { classes } = useStyles()

  if (patients.length === 1) {
    return (
      <Stack className={classes.container}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: LastAppointmentAnimation,
          }}
          height={119}
          width={119}
        />
        <Stack className={classes.text}>
          <Typography className={classes.title}>
            Esta é a <span className={classes.span}>última</span> constulta do
            dia
          </Typography>
          <Typography className={classes.subtitle}>
            Falta apenas uma para você curtir seu tão merecido descanso. Falta
            pouco!!
            <img src={Balloons} alt="balloons" className={classes.icon} />
          </Typography>
        </Stack>
      </Stack>
    )
  }

  if (!patients.length) {
    return (
      <Stack className={classes.container}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: EmpytLobbyAnimation,
          }}
          height={119}
          width={119}
        />
        <Stack className={classes.text}>
          <Typography className={classes.title}>
            Sem <span className={classes.span}>paciente</span> no lobby
          </Typography>
          <Typography className={classes.subtitle}>
            Aproveita para tomar aquele cafezinho, tomar aquele ar. Logo estarão
            aqui.
          </Typography>
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack spacing={2} direction="column">
      <Typography variant="h6">Pacientes no Lobby</Typography>
      <Stack spacing={3} direction="row" sx={scroll}>
        {patients.map(patient => (
          <LobbyPatient patient={patient} />
        ))}
      </Stack>
    </Stack>
  )
}
