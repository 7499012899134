import {
  IParseAddress,
  GmapsAddressFields,
  IGmapsAddressFieldMap,
} from './parse-address.model'

export const parseAddress: IParseAddress = ({
  formatted_address,
  address_components,
}) => {
  if (!formatted_address) {
    return null
  }

  if (!address_components) {
    return Object.values(IGmapsAddressFieldMap).reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: curr === 'street' ? formatted_address : '',
      }),
      { uf: '', city: '', district: '', street: '', number: '', zipcode: '' },
    )
  }

  return Object.values(address_components)
    .filter(component => component !== undefined)
    .reduce(
      (prev, curr) => {
        const item = curr.types[0] as typeof GmapsAddressFields[number]

        if (!GmapsAddressFields.includes(item)) {
          return prev
        }

        return {
          ...prev,
          [IGmapsAddressFieldMap[item]]:
            curr.types[0] === 'administrative_area_level_1'
              ? curr.short_name
              : curr.long_name,
        }
      },
      { uf: '', city: '', district: '', street: '', number: '', zipcode: '' },
    )
}
