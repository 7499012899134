import { Box, Grid } from '@mui/material'

import { PatientGeneralInfo } from '@components/PatientGeneralInfo/patient-general-info'
import { patientSelector } from '@slices/patient/patient.slice'
import { useAppSelector } from '@store/store'

import { HealthIssues } from './components/HealthIssues/health-issues'
import { InfoCard } from './components/InfoCard/info-card'
import { MedicalHistory } from './components/TableType3/medical-history'
import { useStyles } from './medical-file.styles'

export const MedicalFile = () => {
  const { classes } = useStyles()
  const patient = useAppSelector(patientSelector)

  return (
    <>
      <PatientGeneralInfo />
      <Box className={classes.container}>
        <Grid container className={classes.gridContainer} spacing={3}>
          <Grid item xs={6}>
            <InfoCard
              title="INFORMAÇÕES"
              physicalFeatures={patient?.medicalFile?.physicalFeatures}
            />
          </Grid>
          <Grid item xs={6}>
            <InfoCard
              title="HÁBITOS"
              smokingHabit={patient?.medicalFile?.smokingHabit}
              drinkingHabit={patient?.medicalFile?.drinkingHabit}
            />
          </Grid>
          <Grid item xs={6}>
            <HealthIssues
              title="ALERGIAS"
              allergies={patient?.medicalFile?.allergies}
            />
          </Grid>
          <Grid item xs={6}>
            <HealthIssues
              title="MEDICAMENTOS ATUAIS"
              allergies={patient?.medicalFile?.medicationInUse}
            />
          </Grid>
        </Grid>
        <MedicalHistory />
      </Box>
    </>
  )
}
