import { Grid, Paper, Stack } from '@mui/material'

import { DailySummary } from '@app/home/components/DailySummary/daily-summary'
import { DailyTimeline } from '@app/home/components/DailyTimeline/daily-timeline'
import { DayOff } from '@app/home/components/DayOff/day-off'
import { FinishedDay } from '@app/home/components/FinishedDay/finished-day'
import { Lobby } from '@app/home/components/Lobby/lobby'
import { useStyles } from '@app/home/home.styles'
import { ErrorModal } from '@components/ErrorModal/error-modal'
import { Loader } from '@components/Loader/loader'
import { COMMON } from '@config/common'
import { Status } from '@model/appointment/appointment'
import { useGetDoctorWorkdayQuery } from '@slices/appointments/appointments.api'
import { setCurrentAppointment } from '@slices/appointments/appointments.slice'
import { useAppDispatch } from '@store/store'
import { dataAvailableTypeGuard } from '@utils/data-available-type-guard/data-available-type-guard'

import { NextAppointment } from './components/NextAppointment/next-appointment'
const { POLLING_INTERVAL_IN_SECONDS } = COMMON

export const Home = () => {
  const { classes } = useStyles()
  const { data, isLoading, error } = useGetDoctorWorkdayQuery(null, {
    pollingInterval: POLLING_INTERVAL_IN_SECONDS,
  })
  const dispatch = useAppDispatch()

  if (error) {
    return <ErrorModal error={error} />
  }

  if (!dataAvailableTypeGuard(isLoading, data)) {
    return <Loader />
  }

  const { dayOff, appointments, endOfDay } = data

  if (dayOff) {
    return <DayOff />
  }

  const nextAppointment = appointments.find(
    ({ _id }) => _id === data.nextAppointmentId,
  )

  if (nextAppointment) {
    dispatch(setCurrentAppointment({ appointmentId: nextAppointment._id }))
  }

  if (endOfDay) {
    return <FinishedDay />
  }

  const summary = {
    appointments: appointments.length,
    confirmed: data.appointmentsInfo.Confirmed,
    finished: data.appointmentsInfo.Finished,
    lobby: data.appointmentsInfo.Checked_In,
  }

  const lobbyPatients =
    appointments
      .filter(({ status }) => status === Status.Checked_In)
      .map(({ patient }) => patient) || []

  return (
    <>
      <Grid container spacing={4} className={classes.root}>
        <Grid item xs={4.5}>
          <Paper className={classes.paperCard}>
            <NextAppointment />
          </Paper>
        </Grid>
        <Grid item xs={7.5}>
          <Stack spacing={4} height="100%">
            <Paper className={classes.paperCard}>
              <DailySummary summary={summary} />
            </Paper>
            <Paper className={classes.paperCard}>
              <Lobby patients={lobbyPatients} />
            </Paper>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperCard}>
            <DailyTimeline appointments={appointments} />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
