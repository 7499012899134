import { AddBoxOutlined, Close } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Box,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Fab,
  Typography,
} from '@mui/material'

import {
  examsSelector,
  addExam,
  removeExam,
} from '@slices/appointments/appointments.slice'
import { useAppDispatch, useAppSelector } from '@store/store'

import { useStyles } from './exams.styles'
import { Exams } from '../Exams/exams'

export const ExamsCards = () => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const examsData = useAppSelector(examsSelector)

  function handleIntervalChange(idx: number, add: number) {
    if (add === 1) {
      dispatch(addExam())
      return
    }
    if (add === 0) {
      dispatch(removeExam({ idx }))
      return
    }
  }

  return (
    <Box className={classes.container}>
      {examsData.map((item, idx) => (
        <>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              className={classes.accordionSummary}
            >
              <Box className={classes.boxComponentSummary}>
                <Typography className={classes.title}>
                  Exame {idx + 1}
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => handleIntervalChange(idx, 0)}
                >
                  <Close />
                </IconButton>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Exams exam={item} examIdx={idx} />
            </AccordionDetails>
          </Accordion>
        </>
      ))}

      <Box className={classes.boxAddButton}>
        <Fab
          variant="extended"
          size="small"
          color="primary"
          onClick={() => handleIntervalChange(0, 1)}
          className={classes.addButton}
        >
          <AddBoxOutlined color="secondary" />
          <Typography className={classes.textAddButton}>
            Nova solicitação
          </Typography>
        </Fab>
      </Box>
    </Box>
  )
}
