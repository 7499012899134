import { CSSProperties } from '@mui/styled-engine'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  modalText: {
    textAlign: 'center',
  },
  modalHelperText: {
    marginTop: theme.spacing(1),
    ...(theme.typography.caption as CSSProperties),
    textAlign: 'center',
  },
}))
