import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  content: {
    borderRadius: theme.spacing(1),
    background: theme.palette.common.white,
    minWidth: '50rem',
    boxShadow: theme.palette.customShadows.shadow1,
  },
  table: {
    marginTop: theme.spacing(1),
  },
  dashContainer: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'space-around',
  },
  dashItem: {
    marginLeft: theme.spacing(2),
  },
}))
