import { timelineItemClasses } from '@mui/lab'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    boxShadow: '0 5px 20px 0 rgb(0 0 0 / 20%)',
    minHeight: '11rem',
    maxHeight: '11rem',
    overflowY: 'auto',
  },
  containerPrescription: {
    boxShadow: '0 5px 20px 0 rgb(0 0 0 / 20%)',
    minHeight: '11rem',
    maxHeight: '20rem',
    overflowY: 'auto',
  },
  title: { fontSize: '13px', fontWeight: 500 },
  anamneseContainer: { display: 'block', marginTop: theme.spacing(1) },
  analisysContainer: {
    backgroundColor: '#cee3f9',
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(1),
    display: 'block',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    minHeight: '2.5rem',
    marginRight: theme.spacing(2),
  },
  border: {
    width: '4px',
    minHeight: '2.5rem',
    backgroundColor: theme.palette.primary.main,
    margin: '2px',
    opacity: 0.7,
  },
  timeLine: {
    [`& .${timelineItemClasses.root}:before`]: {
      flex: 0,
      padding: 0,
    },
    padding: 0,
  },
  listTitle: {
    marginLeft: theme.spacing(2),
    fontSize: 13,
  },
  listTitleSecondary: {
    fontSize: 13,
    color: theme.palette.common.white,
  },
  boxListSecondary: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    minWidth: '6rem',
    maxHeight: '2rem',
    display: 'flex',
  },
  diagnostic: {
    fontSize: 13,
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
  description: {
    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  boxFlexItems: { display: 'flex', alignItems: 'center' },
  boxItemsAnamnese: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginRight: '1rem',
    alignItems: 'center',
  },
  boxPhysicalAnalysis: {
    display: 'flex',
    alignItems: 'initial',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3 / 8),
    marginRight: '1rem',
  },
  prescriptionContainer: {
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(1),
    display: 'block',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    minHeight: '1rem',
    marginRight: theme.spacing(2),
    width: 'auto',
  },
  boxItemsPrescription: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}))
