import { Box, Grid, TextField, Typography } from '@mui/material'

import { IMedicalCertificate } from '@model/appointment/appointment'
import {
  changeMedicalCertificate,
  medicalCertificateSelector,
} from '@slices/appointments/appointments.slice'
import { useAppDispatch, useAppSelector } from '@store/store'

import { useStyles } from './medicalCertificate.styles'

export const MedicalCertificate = () => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const medicalCertificateData = useAppSelector(medicalCertificateSelector)

  function handleChangeMedicalCertificate(
    medicalCertificate: IMedicalCertificate,
  ) {
    dispatch(changeMedicalCertificate({ medicalCertificate }))
  }

  return (
    <Box className={classes.container}>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid>
          <Typography className={classes.textTitle}>Atestado médico</Typography>
        </Grid>
        <Grid item className={classes.gridItem}>
          <Box className={classes.BoxInput}>
            <TextField
              hiddenLabel
              name="Tempo_reclusao"
              fullWidth
              multiline
              autoFocus
              placeholder="Coloque aqui o tempo de reclusão do paciente"
              label="Tempo de Reclusão"
              variant="standard"
              className={classes.textDiagnostico}
              value={medicalCertificateData.period}
              onChange={e =>
                handleChangeMedicalCertificate({
                  ...medicalCertificateData,
                  period: e.target.value,
                })
              }
            />
            <Box style={{ display: 'flex' }}>
              <TextField
                name="motivo"
                fullWidth
                multiline
                autoFocus
                placeholder="Explique o motivo da reclusão"
                label="Motivo da reclusão"
                variant="outlined"
                value={medicalCertificateData.reason}
                className={classes.textDiagnostico}
                onChange={e =>
                  handleChangeMedicalCertificate({
                    ...medicalCertificateData,
                    reason: e.target.value,
                  })
                }
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
