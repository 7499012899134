import { Box } from '@mui/material'

import { useStyles } from './personal-tab.styles'
import { PersonalForm } from '../PersonalForm/personal-form'

export const PersonalTab = () => {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      <PersonalForm />
    </Box>
  )
}
