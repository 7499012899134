import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: { marginTop: theme.spacing(3) },
  gridContainer: { margin: 0, width: '100%' },
  gridItem: {
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
    width: '100%',
    border: `1.5px solid ${theme.palette.primary.main}`,
    padding: '1rem',
    borderRadius: theme.spacing(1),
  },
  input: {
    width: '100%',
    margin: `${theme.spacing(1, 0)} !important`,
  },
  textTitle: {
    fontSize: '16px',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  boxAvaliation: { marginBottom: '1rem' },
  boxItemAvaliation: { display: 'flex' },
  textField: { flex: 3, marginRight: '2rem' },
  textValue: { flex: 1 },
  deleteButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '1rem',
  },
  addButton: { display: 'flex', marginTop: '16px', justifyContent: 'flex-end' },
}))
