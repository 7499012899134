import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: { margin: theme.spacing(1) },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '3rem',
    marginLeft: theme.spacing(3),
    width: '87%',
  },
  table: {
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1),
    background: theme.palette.common.white,
    boxShadow: '0 5px 20px 0 rgb(0 0 0 / 20%)',
  },
  title: {
    color: 'black',
    fontSize: 12,
  },
  icon: {
    color: theme.palette.primary.light,
  },
}))
