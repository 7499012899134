import { Typography } from '@mui/material'
import { HeaderProps } from 'react-big-calendar'

import { useStyles } from './weekday.styles'

export const Weekday = ({ label }: HeaderProps) => {
  const { classes } = useStyles()

  return <Typography className={classes.container}>{label}</Typography>
}
