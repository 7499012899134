import { CSSProperties } from 'react'

import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    ...(theme.typography.body2 as CSSProperties),
    textTransform: 'uppercase',
  },
}))
