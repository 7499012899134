import { useEffect } from 'react'

import { TextField } from '@mui/material'
import { FormikErrors, FormikTouched, useFormikContext } from 'formik'
import { getGeocode } from 'use-places-autocomplete'

import { ControlledGeneralSelect } from '@components/ControlledGeneralSelect/controlled-general-select'
import { IAddress, STATE_OPTIONS } from '@model/doctor/doctor'
import { onlyDigits } from '@utils/only-digits/only-digits'
import { parseAddress } from '@utils/parse-address/parse-address'
import { IParseAddress } from '@utils/parse-address/parse-address.model'
import { formatZipCode } from '@utils/zipcode/zipcode'

import { useStyles } from './address-form.styles'

interface Props {
  placeId: string
  touched: FormikTouched<IAddress>
  errors: FormikErrors<IAddress>
  values: IAddress
  handleNewCoordinates(newCoordinates: number[]): void
}

export const AddressForm = ({
  placeId,
  touched,
  errors,
  values,
  handleNewCoordinates,
}: Props) => {
  const { classes } = useStyles()
  const { setFieldValue, setFieldTouched } = useFormikContext()

  useEffect(() => {
    ;(async () => {
      if (placeId) {
        const [{ formatted_address, address_components, geometry }] =
          await getGeocode({
            placeId,
          })

        const coordinates = [geometry.location.lng(), geometry.location.lat()]

        handleNewCoordinates(coordinates)

        const parsedAddress = parseAddress({
          formatted_address,
          address_components,
        })

        if (parsedAddress) {
          ;(
            Object.keys(parsedAddress) as Array<keyof IParseAddress.Result>
          ).forEach(addressKey => {
            setFieldTouched(addressKey)
            setFieldValue(
              addressKey,
              parsedAddress[addressKey]
                ? parsedAddress[addressKey]
                : values[addressKey],
            )
          })
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeId])

  return (
    <>
      <TextField
        error={Boolean(errors['city'] && touched['city'])}
        name="city"
        label="Cidade"
        helperText={touched['city'] ? errors['city'] : null}
        className={classes.input}
        variant="outlined"
        value={values['city']}
        onChange={e => setFieldValue('city', e.target.value)}
      />
      <ControlledGeneralSelect
        options={STATE_OPTIONS}
        textFieldParams={{
          label: 'Estado',
          name: 'uf',
          helperText: 'Selecione ao menos um estado',
        }}
      />
      <TextField
        error={Boolean(errors['district'] && touched['district'])}
        name="district"
        label="Bairro"
        helperText={touched['district'] ? errors['district'] : null}
        className={classes.input}
        variant="outlined"
        value={values['district']}
        onChange={e => setFieldValue('district', e.target.value)}
      />
      <TextField
        error={Boolean(errors['number'] && touched['number'])}
        name="number"
        label="Número"
        helperText={
          touched['number'] && errors['number']
            ? 'O número do estabelecimento deve ser numérico'
            : null
        }
        className={classes.input}
        variant="outlined"
        value={values['number']}
        onChange={e => setFieldValue('number', onlyDigits(e.target.value))}
      />
      <TextField
        error={Boolean(errors['zipcode'] && touched['zipcode'])}
        name="zipcode"
        label="Cep"
        helperText={touched['zipcode'] ? errors['zipcode'] : null}
        className={classes.input}
        variant="outlined"
        value={values['zipcode']}
        onChange={e => setFieldValue('zipcode', formatZipCode(e.target.value))}
      />
      <TextField
        error={Boolean(errors['street'] && touched['street'])}
        name="street"
        label="Rua"
        helperText={touched['street'] ? errors['street'] : null}
        className={classes.input}
        variant="outlined"
        value={values['street']}
        onChange={e => setFieldValue('street', e.target.value)}
      />
    </>
  )
}
