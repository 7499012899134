import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

import { ServiceError } from '@model/service-error/service-error'
const STANDARD_MESSAGE =
  'Estamos tendo problemas em fazer isso. Verifique sua conexão com a internet e tente novamente.'

export const parseErrorMessage = (
  error: ServiceError | SerializedError | FetchBaseQueryError,
) => {
  if (isServiceError(error))
    return error.data.message === 'Network Error'
      ? STANDARD_MESSAGE
      : error.data.message
  return STANDARD_MESSAGE
}

const isServiceError = (
  error: ServiceError | SerializedError | FetchBaseQueryError,
): error is ServiceError => {
  return (
    error &&
    'data' in error &&
    'message' in (error.data as ServiceError['data'])
  )
}
