import { Grid, Typography, Box } from '@mui/material'
import {
  BsGridFill,
  BsFillCheckSquareFill,
  BsPersonPlusFill,
} from 'react-icons/bs'

import { Status } from '@model/appointment/appointment'

import { useStyles } from './appoinment-info.styles'

interface Props {
  appointmentsNumber?: number
  status?: Status
  title: string
  icon: string
}

export const AppoinmentInfo = ({
  status,
  appointmentsNumber = 0,
  title,
  icon,
}: Props) => {
  const numberOfAppointmentsInText = `${
    appointmentsNumber < 10 ? '0' : ''
  }${appointmentsNumber}`

  const subtitle = `${numberOfAppointmentsInText}`

  const { classes } = useStyles()

  return (
    <Grid item className={classes.container}>
      <Box className={classes.subcontainer}>
        <Typography className={classes.title}>{title}</Typography>
        {icon === 'checkbox' && (
          <BsFillCheckSquareFill size={24} className={classes.icon} />
        )}
        {icon === 'square' && <BsGridFill size={24} className={classes.icon} />}
        {icon === 'person' && (
          <BsPersonPlusFill size={24} className={classes.icon} />
        )}
      </Box>
      <Box className={classes.subcontainer}>
        <Typography className={classes.subtitle}>{subtitle}</Typography>
        {icon === 'checkbox' && (
          <Typography className={classes.description}>
            Ver lista de consultas não confirmadas
          </Typography>
        )}
      </Box>
    </Grid>
  )
}
