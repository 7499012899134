import { Avatar, Button, Stack, Tooltip, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useStyles } from '@app/home/components/LobbyPatient/lobby-patient.styles'
import { IPatient } from '@model/patient/patient'
import { setPatient } from '@slices/patient/patient.slice'
import { useAppDispatch } from '@store/store'
import { formatName } from '@utils/name/name'

interface Props {
  patient: IPatient
}

export const LobbyPatient = ({ patient }: Props) => {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  function handleNavigation(patient: IPatient) {
    dispatch(setPatient(patient))
    navigate(`/pacientes/${patient._id}`)
  }

  const name = formatName(patient.name)

  return (
    <Stack
      data-testid={`lobby-${patient._id}`}
      direction="column"
      alignItems="center"
      spacing={1}
    >
      <Avatar src={patient.imageUrl} className={classes.avatar} />
      <Tooltip title={patient.name}>
        <Typography variant="body1" fontSize="1em">
          {name}
        </Typography>
      </Tooltip>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleNavigation(patient)}
        className={classes.button}
        data-testid={`lobby-profile-${patient._id}-button`}
      >
        Ver detalhes
      </Button>
    </Stack>
  )
}
