import { Add, Delete } from '@mui/icons-material'
import {
  Box,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'

import { IAnamnesis, Intensity } from '@model/appointment/appointment'
import {
  anamnesisSelector,
  changeAnamnesis,
  addAnamnese,
  removeAnamense,
} from '@slices/appointments/appointments.slice'
import { useAppDispatch, useAppSelector } from '@store/store'

import { useStyles } from './anamnesis.styles'

export const Anamnesis = () => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const anamnesisData = useAppSelector(anamnesisSelector)

  function handleIntervalChange(idx: number, add: number) {
    if (add === 1) {
      dispatch(addAnamnese())
      return
    }
    if (add === 0) {
      dispatch(removeAnamense({ idx }))
      return
    }
  }

  function handleChangeAnamnese(idx: number, anamnese: IAnamnesis) {
    dispatch(
      changeAnamnesis({
        idx,
        anamnese,
      }),
    )
  }

  return (
    <Box className={classes.container}>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid>
          <Typography className={classes.textTitle}>
            Queixas principais
          </Typography>
        </Grid>
        <Grid item className={classes.gridItem}>
          {anamnesisData.map((item, idx) => (
            <Box className={classes.boxAnamnese}>
              <Box className={classes.BoxItemAnamnse}>
                {idx >= 1 ? (
                  <TextField
                    name="Queixa"
                    hiddenLabel
                    fullWidth
                    multiline
                    autoFocus
                    placeholder="Coloque aqui a queixa do paciente"
                    variant="standard"
                    value={item.complaint}
                    className={classes.textField}
                    onChange={e =>
                      handleChangeAnamnese(idx, {
                        ...item,
                        complaint: e.target.value,
                      })
                    }
                  />
                ) : (
                  <TextField
                    hiddenLabel
                    name="Queixa"
                    fullWidth
                    multiline
                    autoFocus
                    placeholder="Coloque aqui a queixa do paciente"
                    label="Queixa do paciente"
                    variant="standard"
                    value={item.complaint}
                    className={classes.textField}
                    onChange={e =>
                      handleChangeAnamnese(idx, {
                        ...item,
                        complaint: e.target.value,
                      })
                    }
                  />
                )}
                {idx >= 1 ? (
                  <FormControl
                    variant="standard"
                    className={classes.boxIntensity}
                  >
                    <Select
                      value={item.intensity}
                      onChange={e =>
                        handleChangeAnamnese(idx, {
                          ...item,
                          intensity: e.target.value,
                        })
                      }
                    >
                      <MenuItem value={Intensity.Low}> Fraco </MenuItem>
                      <MenuItem value={Intensity.Moderate}> Médio </MenuItem>
                      <MenuItem value={Intensity.Intense}> Forte </MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl
                    variant="standard"
                    className={classes.boxIntensity}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Intensidade
                    </InputLabel>
                    <Select
                      value={item.intensity}
                      onChange={e =>
                        handleChangeAnamnese(idx, {
                          ...item,
                          intensity: e.target.value,
                        })
                      }
                    >
                      <MenuItem value={Intensity.Low}> Fraco </MenuItem>
                      <MenuItem value={Intensity.Moderate}> Médio </MenuItem>
                      <MenuItem value={Intensity.Intense}> Forte </MenuItem>
                    </Select>
                  </FormControl>
                )}
                <Box className={classes.deleteButton}>
                  <IconButton
                    size="small"
                    onClick={() => handleIntervalChange(idx, 0)}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          ))}
        </Grid>
        <Box className={classes.addButton}>
          <Fab
            size="small"
            color="primary"
            onClick={() => handleIntervalChange(0, 1)}
          >
            <Add />
          </Fab>
        </Box>
      </Grid>
    </Box>
  )
}
