import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: { marginTop: theme.spacing(3) },
  gridContainer: { margin: 0, width: '100%' },
  gridItem: {
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
    width: '100%',
    border: `1.5px solid ${theme.palette.primary.main}`,
    padding: '1rem',
    borderRadius: theme.spacing(1),
  },
  input: {
    width: '100%',
    margin: `${theme.spacing(1, 0)} !important`,
  },
  textTitle: {
    fontSize: '16px',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  BoxInput: { display: 'block' },
  textDiagnostico: {
    flex: 3,
    marginRight: '2rem',
    marginBottom: '2rem',
    width: '50%',
  },
  boxDiagnostico: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  textDescription: {
    flex: 3,
    marginRight: '2rem',
  },
}))
