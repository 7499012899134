import WatchLaterIcon from '@mui/icons-material/WatchLaterOutlined'
import { Box, Button, Grid, Typography } from '@mui/material'
import clsx from 'clsx'
import { BsPersonLinesFill } from 'react-icons/bs'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import SimpleScrollBar from 'simplebar-react'

import empty from '@assets/empty.png'
import { ErrorModal } from '@components/ErrorModal/error-modal'
import { Loader } from '@components/Loader/loader'
import { COMMON } from '@config/common'
import { Status } from '@model/appointment/appointment'
import {
  useCancelWorkdayMutation,
  useGetDoctorWorkdayQuery,
  useUpdateAppointmentStatusMutation,
} from '@slices/appointments/appointments.api'
import { setCurrentAppointment } from '@slices/appointments/appointments.slice'
import { doctorSelector } from '@slices/session/session.slice'
import { useAppDispatch, useAppSelector } from '@store/store'
import { formatDate } from '@utils/date/date'

import { AppointmentCard } from './components/AppointmentCard/appointment-card'
import { AppoinmentInfo } from './components/AppointmentInfo/appoinment-info'
import { useStyles } from './day-of-work.styles'
const { POLLING_INTERVAL_IN_SECONDS } = COMMON

export const DayOfWork = () => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { workWindow } = useAppSelector(doctorSelector)

  function handleNavigation(appointmentId?: string | null) {
    if (appointmentId) {
      dispatch(setCurrentAppointment({ appointmentId }))
      navigate(`/dia-de-trabalho/pre-consulta/${appointmentId}`)
    }
  }

  const [
    cancelWorkday,
    { isLoading: isCancellingWorkday, error: cancellingWorkdayError },
  ] = useCancelWorkdayMutation()

  const [
    updateAppointmentStatus,
    { error: updateAppointmentError, isLoading: isUpdatingAppointmentStatus },
  ] = useUpdateAppointmentStatusMutation()

  const {
    data,
    isLoading: isGettingWorkday,
    error: gettingWorkdayError,
  } = useGetDoctorWorkdayQuery(null, {
    pollingInterval: POLLING_INTERVAL_IN_SECONDS,
  })

  function handleCancelation() {
    const appointmentId = data?.nextAppointmentId

    if (appointmentId) {
      updateAppointmentStatus({ appointmentId, status: Status.Canceled })
    }
  }

  function handleCancelationOfAll() {
    cancelWorkday()
  }

  if (isCancellingWorkday || isGettingWorkday || isUpdatingAppointmentStatus) {
    return <Loader />
  }

  const error =
    cancellingWorkdayError || updateAppointmentError || gettingWorkdayError

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        className={classes.appointmentsInfoContainer}
      >
        <AppoinmentInfo
          status={Status.Scheduled}
          title="CONSULTAS DO DIA"
          appointmentsNumber={data?.appointmentsInfo.Total}
          icon="square"
        />
        <AppoinmentInfo
          status={Status.Checked_In}
          title="CONSULTAS CONFIRMADAS"
          appointmentsNumber={data?.appointmentsInfo.Confirmed}
          icon="checkbox"
        />
        <Grid item className={classes.container}>
          <Box className={classes.subcontainer}>
            <Typography className={classes.title}>PRÓXIMA CONSULTA</Typography>
            <BsPersonLinesFill size={25} className={classes.icon} />
          </Box>
          {data?.formattedNextAppointmentHour ? (
            <Typography className={classes.subtitle}>
              {data?.formattedNextAppointmentHour}
            </Typography>
          ) : (
            <Typography className={classes.noAppointments}>
              Sem consultas
            </Typography>
          )}
        </Grid>
      </Grid>
      {data?.appointments && data?.appointments.length < 1 ? (
        <Box className={classes.emptyContainer}>
          <img className={classes.emptyImage} src={empty} alt="Empty" />
          <Typography className={classes.emptyTitle}>
            Sem consultas marcadas para hoje
          </Typography>
          <RouterLink to="/agenda">
            <Typography className={classes.emptySubtitle}>
              Inserir nova consulta na agenda
            </Typography>
          </RouterLink>
          <Typography className={classes.emptySubtitle}>
            Cancelar horários do dia
          </Typography>
        </Box>
      ) : (
        <Box className={classes.timelineContainer}>
          <Box className={classes.datetimeSubContainer}>
            <Grid item>
              <Box className={classes.datetimeContainer}>
                <Typography className={classes.datetimeTitle}>
                  Trilha do dia
                </Typography>
                <Typography className={classes.datetimeDate}>
                  {formatDate()}
                </Typography>
              </Box>
            </Grid>
            {workWindow && (
              <Grid item>
                <Box className={classes.workdayContainer}>
                  <Typography className={classes.datetimeDate}>
                    {workWindow}
                  </Typography>
                  <WatchLaterIcon className={classes.workdayIcon} />
                </Box>
              </Grid>
            )}
          </Box>
          <SimpleScrollBar className={classes.scrollBar}>
            <Box className={classes.timelineContentContainer}>
              {data?.appointments.map(
                ({ patient, translatedStatus, formattedTime, _id }, index) => (
                  <AppointmentCard
                    key={_id}
                    patientName={patient.name}
                    number={index}
                    translatedStatus={translatedStatus}
                    time={formattedTime}
                    handleDetails={() => handleNavigation(_id)}
                  />
                ),
              )}
            </Box>
          </SimpleScrollBar>
          <Grid
            container
            justifyContent="center"
            className={classes.actionButtonsContainer}
            spacing={2}
          >
            <Grid item>
              <Button
                className={clsx(
                  classes.button,
                  classes.cancelNextAppointmentButton,
                )}
                onClick={handleCancelation}
                disabled={!data?.nextAppointmentId}
              >
                Cancelar próxima consulta
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={clsx(classes.button, classes.cancelAllButton)}
                onClick={handleCancelationOfAll}
                disabled={!data?.nextAppointmentId}
              >
                Cancelar consultas do dia
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={clsx(classes.button, classes.nextAppointment)}
                onClick={() => handleNavigation(data?.nextAppointmentId)}
                disabled={!data?.nextAppointmentId}
              >
                Iniciar próxima consulta
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      {error && <ErrorModal error={error} />}
    </>
  )
}
