import { PayloadAction } from '@reduxjs/toolkit'

import {
  IAnamnesis,
  IAppointment,
  IClinicalAnalysis,
  IExam,
  IExamItem,
  IFormattedAppointment,
  IMedicalCertificate,
  IPhysicalAnalysis,
  IPrescription,
  IPrescriptionItem,
  IRecommendations,
  Status,
} from '@model/appointment/appointment'
import { IPatient } from '@model/patient/patient'

export type IPeriod = 'month' | 'day'

export namespace IAppointments {
  export namespace Get {
    export interface Request {
      date: string
      period: IPeriod
    }

    export interface Response {
      appointments: (IAppointment & { count?: number; status?: string })[]
    }

    export type FormattedResponse = {
      appointments: ({
        title: string
        status?: Status | string
        start: Date | string
        end: Date | string
        allDay?: boolean
      } & Partial<Omit<IAppointment, 'start' | 'end'>>)[]
    }
  }

  export namespace GetWorkday {
    export type Request = null

    export interface Response {
      appointments: IFormattedAppointment[]
      appointmentsInfo: {
        [key in Status | 'Total']: number
      }
      nextAppointmentId: string | null
      nextAppointmentHour: string
      formattedNextAppointmentHour: string | null
      dayOff: boolean
      endOfDay: boolean
    }

    export interface FormattedResponse {
      appointments: IFormattedAppointment[]
      appointmentsInfo: {
        [key in Status | 'Total']: number
      }
      nextAppointmentId: string | null
      nextAppointmentHour: string
      formattedNextAppointmentHour: string | null
    }
  }

  export namespace GetAvaliability {
    export interface Request {
      date: string
    }

    export interface Response {
      availableHours: string[]
    }
  }

  export namespace Create {
    export interface Request {
      appointmentStartAt: string
      patient: Partial<IPatient>
      paymentMethod: string
    }

    export interface Response {
      appointment: IAppointment
    }
  }

  export namespace Finish {
    export interface Request
      extends Pick<
        IAppointments.Slice.State,
        | 'anamnesis'
        | 'clinicalAnalysis'
        | 'recommendations'
        | 'physicalAnalysis'
        | 'recommendations'
        | 'prescriptions'
        | 'exams'
        | 'medicalCertificate'
      > {
      appointmentId: string
    }

    export type Response = void
  }

  export namespace ChangeStatus {
    export interface Request {
      appointmentId: string
      status: IFormattedAppointment['status']
    }

    export type Response = void
  }

  export namespace NextStatus {
    export interface Request {
      appointmentId: string
    }

    export type Response = { appointment: IFormattedAppointment }
  }

  export namespace CancelWorkday {
    export type Request = void

    export type Response = void
  }

  export namespace Slice {
    export interface State {
      doctorWorkday: IFormattedAppointment[]
      currentAppointment?: IFormattedAppointment
      appointmentTab: AppointmentTabs
      medicalTab: MedicalTabs
      prescriptionsTab: PrescriptionsTabs
      confirmationTab: ConfirmationTabs
      anamnesis: IAnamnesis[]
      physicalAnalysis: IPhysicalAnalysis[]
      clinicalAnalysis: IClinicalAnalysis
      recommendations: IRecommendations
      prescriptions: IPrescription[]
      exams: IExam[]
      medicalCertificate: IMedicalCertificate
      cameFromWorkday: boolean
    }

    export namespace SetCurrentAppointment {
      export type Payload = PayloadAction<{ appointmentId: string }>
    }

    export namespace ChangeStatus {
      export type Payload = PayloadAction<{ status: keyof typeof Status }>
    }

    export namespace ChangeAppointmentTab {
      export type Payload = PayloadAction<{ appointmentTab: AppointmentTabs }>
    }

    export namespace ChangeCurrentMedicalTab {
      export type Payload = PayloadAction<{ medicalTab: MedicalTabs }>
    }

    export namespace ChangeCurrentConfirmationTab {
      export type Payload = PayloadAction<{ confirmationTab: ConfirmationTabs }>
    }

    export namespace ChangeCurrentPrescriptionsTab {
      export type Payload = PayloadAction<{
        prescriptionsTab: PrescriptionsTabs
      }>
    }

    export namespace RemoveAnamense {
      export type Payload = PayloadAction<{
        idx: number
      }>
    }

    export namespace ChangeAnamnesis {
      export type Payload = PayloadAction<{
        idx: number
        anamnese: IAnamnesis
      }>
    }

    export namespace ChangeClinicalAnalysis {
      export type Payload = PayloadAction<{
        clinicalAnalysis: IClinicalAnalysis
      }>
    }

    export namespace RemovePhysicalAnlysis {
      export type Payload = PayloadAction<{
        idx: number
      }>
    }

    export namespace ChangePhysicalAnalysis {
      export type Payload = PayloadAction<{
        idx: number
        physicalAnalysis: IPhysicalAnalysis
      }>
    }

    export namespace ChangeRecommendations {
      export type Payload = PayloadAction<{
        recommendations: IRecommendations
      }>
    }

    export namespace RemovePrescription {
      export type Payload = PayloadAction<{
        idx: number
      }>
    }

    export namespace AddPrescriptionItem {
      export type Payload = PayloadAction<{
        prescriptionIdx: number
      }>
    }

    export namespace RemovePrescriptionItem {
      export type Payload = PayloadAction<{
        prescriptionIdx: number
        prescriptionItemIdx: number
      }>
    }

    export namespace ChangePrescriptions {
      export type Payload = PayloadAction<{
        prescriptionIdx: number
        prescriptionItemIdx: number
        prescriptionItem: IPrescriptionItem
      }>
    }

    export namespace RemoveExam {
      export type Payload = PayloadAction<{
        idx: number
      }>
    }

    export namespace AddExamItem {
      export type Payload = PayloadAction<{
        examIdx: number
      }>
    }

    export namespace RemoveExamItem {
      export type Payload = PayloadAction<{
        examIdx: number
        examItemIdx: number
      }>
    }

    export namespace ChangeExams {
      export type Payload = PayloadAction<{
        examIdx: number
        examItemIdx: number
        examItem: IExamItem
      }>
    }

    export namespace ChangeMedicalCertificate {
      export type Payload = PayloadAction<{
        medicalCertificate: IMedicalCertificate
      }>
    }
  }
}

export enum AppointmentTabs {
  Medical = 'medical',
  Prescriptions = 'prescriptions',
  Confirmation = 'confirmation',
}

export enum MedicalTabs {
  Anamnesis = 'anamnesis',
  Physical = 'physical',
  Clinical = 'clinical',
  Recommendations = 'recommendations',
}

export enum PrescriptionsTabs {
  Prescriptions = 'prescriptions',
  Exams = 'exams',
  MedicalCertificate = 'medicalCertificate',
}

export enum ConfirmationTabs {
  Medical = 'medical',
  Prescriptions = 'precriptions',
}
