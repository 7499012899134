import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'

import { IPatient } from '@model/patient/patient'

import { useStyles } from './patients-table-head.styles'

interface Props {
  order: 'asc' | 'desc'
  orderBy: keyof IPatient
  onRequestSort: (property: keyof IPatient) => void
}

export const PatientsTableHead = ({ order, onRequestSort, orderBy }: Props) => {
  const { classes } = useStyles()

  return (
    <TableHead>
      <TableRow>
        <TableCell sortDirection={orderBy === 'name' ? order : false}>
          <TableSortLabel
            active={true}
            direction={orderBy === 'name' ? order : 'asc'}
            onClick={() => onRequestSort('name')}
          >
            <Typography className={classes.tableHeadText} variant="caption">
              NOME
            </Typography>
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <Typography className={classes.tableHeadText} variant="caption">
            CATEGORIA
          </Typography>
        </TableCell>
        <TableCell sortDirection={orderBy === 'phoneNumber' ? order : false}>
          <TableSortLabel
            active={true}
            direction={orderBy === 'phoneNumber' ? order : 'asc'}
            onClick={() => onRequestSort('phoneNumber')}
          >
            <Typography className={classes.tableHeadText} variant="caption">
              TELEFONE
            </Typography>
          </TableSortLabel>
        </TableCell>
        <TableCell sortDirection={orderBy === 'birthday' ? order : false}>
          <TableSortLabel
            active={true}
            direction={orderBy === 'birthday' ? order : 'asc'}
            onClick={() => onRequestSort('birthday')}
          >
            <Typography className={classes.tableHeadText} variant="caption">
              IDADE
            </Typography>
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
