import { COMMON } from '@config/common'
import { baseApi } from '@services/api'
import { formatHomeData } from '@utils/format-home-data/format-home-data'
import { formatPatientsData } from '@utils/format-patients-data/format-patients-data'

import { ISearches } from './searches.model'

const { POLLING_INTERVAL_IN_SECONDS, SEARCH_PATIENTS_DEFAULT_LIMIT } = COMMON

export const searchesApi = baseApi.injectEndpoints({
  endpoints(builder) {
    return {
      getDoctorPatients: builder.query<
        ISearches.Patients.FormattedResponse,
        ISearches.Patients.Request
      >({
        query: ({ page = 0, name }) => {
          let url = `searches/patients?page=${page}&limit=${SEARCH_PATIENTS_DEFAULT_LIMIT}`

          if (name) {
            url = `searches/patients?name=${name}&limit=${SEARCH_PATIENTS_DEFAULT_LIMIT}`
          }

          return {
            url,
            method: 'GET',
          }
        },
        transformResponse: (response: ISearches.Patients.Response) => {
          return formatPatientsData(response)
        },
        keepUnusedDataFor: POLLING_INTERVAL_IN_SECONDS,
      }),
      getHealthInfo: builder.query<
        ISearches.HealthInfo.Response,
        ISearches.HealthInfo.Request
      >({
        query() {
          return {
            url: '/searches/health-info',
            method: 'GET',
          }
        },
      }),
      getHomeInfo: builder.query<
        ISearches.DashboardHome.FormattedResponse,
        ISearches.DashboardHome.Request
      >({
        query() {
          return {
            url: `/searches/dashboard-home?date=${new Date().toISOString()}`,
            method: 'GET',
          }
        },
        transformResponse: (response: ISearches.DashboardHome.Response) => {
          return formatHomeData(response)
        },
      }),
    }
  },
})

export const {
  useGetDoctorPatientsQuery,
  useLazyGetDoctorPatientsQuery,
  useGetHealthInfoQuery,
  useGetHomeInfoQuery,
} = searchesApi
