import { ReactNode } from 'react'

import { Box } from '@mui/material'

import { useStyles } from './event-wrapper.styles'

interface Props {
  children: ReactNode
}

export const EventWrapper = ({ children }: Props) => {
  const { classes } = useStyles()

  return <Box className={classes.container}>{children}</Box>
}
