import { IHabityFrequency } from '@model/medical-file/medical-file'

export type ITranslateFrequencyResponse =
  | 'Nunca'
  | 'Quase nunca'
  | 'Às vezes'
  | 'Sempre'

export interface ITranslateFrequency {
  (data?: IHabityFrequency): ITranslateFrequencyResponse
}

export const translateFrequency: ITranslateFrequency = data => {
  switch (data) {
    case IHabityFrequency.Never:
      return 'Nunca'
    case IHabityFrequency.Almost_Never:
      return 'Quase nunca'
    case IHabityFrequency.Sometimes:
      return 'Às vezes'
    case IHabityFrequency.Always:
      return 'Sempre'
    default:
      return 'Nunca'
  }
}
