import { AccessTime } from '@mui/icons-material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {
  Avatar,
  Badge,
  Button,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { IFormattedAppointment, Status } from '@model/appointment/appointment'
import { IPatient } from '@model/patient/patient'
import { setPatient } from '@slices/patient/patient.slice'
import { useAppDispatch } from '@store/store'
import { formatTime } from '@utils/date/date'
import { formatName } from '@utils/name/name'
import { statusColor, statusIcon } from '@utils/status/status'

import { useStyles } from './timeline-appointment.styles'

interface Props {
  appointment: IFormattedAppointment
}

export const TimelineAppointment = ({ appointment }: Props) => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  function handleNavigation(patient: IPatient) {
    dispatch(setPatient(appointment.patient))
    navigate(`/pacientes/${appointment.patient._id}`)
  }

  const name = formatName(appointment.patient.name)
  const formattedTime = `${formatTime(appointment.start)} - ${formatTime(
    appointment.end,
  )}`
  const status = appointment.status
  const StatusIcon = statusIcon(status)
  const color = statusColor(status)
  const canceledStyle =
    appointment.status === Status.Canceled ? classes.canceledFilter : undefined

  return (
    <Stack
      direction="column"
      alignItems="center"
      spacing={1}
      className={canceledStyle}
    >
      <Badge
        badgeContent={<CheckCircleIcon color="primary" />}
        invisible={!appointment.patient.isBlu}
      >
        <Avatar src={appointment.patient.imageUrl} className={classes.avatar} />
      </Badge>
      <Tooltip title={appointment.patient.name}>
        <Typography variant="body1" fontSize="1em">
          {name}
        </Typography>
      </Tooltip>
      <Typography variant="body1" color={color} fontSize="0.8em" noWrap>
        <StatusIcon className={classes.statusIcon} />
        {appointment.translatedStatus}
      </Typography>
      <Typography variant="body1" color="primary" fontSize="0.8em">
        <AccessTime className={classes.icon} />
        {formattedTime}
      </Typography>
      <Button
        data-testid={`timeline-profile-${appointment.patient._id}-button`}
        variant="contained"
        fullWidth={true}
        color="primary"
        onClick={() => handleNavigation(appointment.patient)}
        className={classes.button}
      >
        Ver detalhes
      </Button>
    </Stack>
  )
}
