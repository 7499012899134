import { Button, ButtonProps } from '@mui/material'

import { useStyles } from '@app/home/components/AppointmentStatusButton/appointment-status-button.styles'
import { Status } from '@model/appointment/appointment'

interface Props extends ButtonProps {
  status: string
  onClick: () => void
}

export const AppointmentStatusButton = ({
  status,
  onClick,
  ...rest
}: Props) => {
  const { classes } = useStyles()

  const label = (() => {
    switch (status) {
      case Status.Scheduled:
        return 'Confirmar Consulta'
      case Status.Confirmed:
        return 'Fazer Check-In'
      case Status.Checked_In:
        return 'Iniciar Consulta'
      case Status.In_Progress:
        return 'Voltar para Consulta'
      default:
        return ''
    }
  })()

  const style = (() => {
    switch (status) {
      case Status.Scheduled:
        return classes.confirmButton
      case Status.Confirmed:
        return classes.checkInButton
      case Status.Checked_In:
        return classes.startButton
      case Status.In_Progress:
        return classes.finishButton
      default:
        return classes.finishButton
    }
  })()

  return (
    <Button {...rest} variant="contained" onClick={onClick} className={style}>
      {label}
    </Button>
  )
}
