import { ChangeEvent } from 'react'

import { Box, Tab, Tabs } from '@mui/material'

import { AppointmentMedicalAnalysis } from '@components/AppointmentMedicalAnalysis/appointment-medical-analysis'
import { PrescriptionsAnalysis } from '@components/AppointmentPrescriptionsAnalysis/prescriptions-analysis'
import { ConfirmationTabs } from '@slices/appointments/appointments.model'
import {
  anamnesisSelector,
  changeCurrentConfirmationTab,
  clinicalAnalysisSelector,
  currentConfirmationTabSelector,
  examsSelector,
  physicalAnalysisSelector,
  prescriptionsSelector,
  recommendationsSelector,
  medicalCertificateSelector,
  currentAppointmentSelector,
} from '@slices/appointments/appointments.slice'
import { useAppDispatch, useAppSelector } from '@store/store'

import { useStyles } from './confirmation-tab.styles'

export const ConfirmationTab = () => {
  const currentConfirmationTab = useAppSelector(currentConfirmationTabSelector)
  const { classes } = useStyles()
  const dispatch = useAppDispatch()

  const anamnesis = useAppSelector(anamnesisSelector)
  const appointment = useAppSelector(currentAppointmentSelector)
  const clinicalAnalysis = useAppSelector(clinicalAnalysisSelector)
  const physicalAnalysis = useAppSelector(physicalAnalysisSelector)
  const recommendations = useAppSelector(recommendationsSelector)
  const prescriptions = useAppSelector(prescriptionsSelector)
  const exams = useAppSelector(examsSelector)
  const medicalCertificate = useAppSelector(medicalCertificateSelector)

  function handleTabChange(
    _: ChangeEvent<unknown>,
    newValue: ConfirmationTabs,
  ) {
    dispatch(changeCurrentConfirmationTab({ confirmationTab: newValue }))
  }

  return (
    <Box className={classes.container}>
      <Tabs
        value={currentConfirmationTab}
        classes={{ indicator: classes.indicator, root: classes.root }}
        onChange={handleTabChange}
        variant="scrollable"
      >
        <Tab
          className={classes.tab}
          value={ConfirmationTabs.Medical}
          label="Análises"
        />
        <Tab
          className={classes.tab}
          value={ConfirmationTabs.Prescriptions}
          label="Prescrições"
        />
      </Tabs>
      {currentConfirmationTab === ConfirmationTabs.Medical &&
        appointment?.patient && (
          <AppointmentMedicalAnalysis
            anamnesis={anamnesis}
            clinicalAnalysis={clinicalAnalysis}
            physicalAnalysis={physicalAnalysis}
            patient={appointment?.patient}
            recommendations={recommendations}
          />
        )}
      {currentConfirmationTab === ConfirmationTabs.Prescriptions &&
        appointment?.patient && (
          <PrescriptionsAnalysis
            prescriptions={prescriptions}
            exams={exams}
            patient={appointment?.patient}
            medicalCertificate={medicalCertificate}
          />
        )}
    </Box>
  )
}
