import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: { margin: theme.spacing(2, 0, 0), width: '100%' },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '60rem',
    height: '20rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyTitle: {
    fontSize: 18,
    fontWeight: 600,
  },
  emptySubtitle: {
    fontSize: 18,
    color: '#1273eb',
    opacity: 0.9,
    textDecorationLine: 'underline',
  },
  emptyImage: {
    width: '20rem',
    height: 'auto',
  },
}))
