import { useNavigate } from 'react-router-dom'

import { AppointmentInfo } from '@components/AppointmentInfo/appointment-info'
import { ErrorModal } from '@components/ErrorModal/error-modal'
import { IFormattedAppointment, Status } from '@model/appointment/appointment'
import { useFinishAppointmentMutation } from '@slices/appointments/appointments.api'
import {
  changeStatus,
  clinicalAnalysisSelector,
  anamnesisSelector,
  physicalAnalysisSelector,
  recommendationsSelector,
  prescriptionsSelector,
  examsSelector,
  medicalCertificateSelector,
  currentAppointmentSelector,
} from '@slices/appointments/appointments.slice'
import { useAppDispatch, useAppSelector } from '@store/store'

import { AppointmentTabsContainer } from './components/AppointmentTabsContainer/appointments-tabs-container'

export const Appointment = () => {
  const dispatch = useAppDispatch()
  const appointmentData = useAppSelector(currentAppointmentSelector)
  const anamnesis = useAppSelector(anamnesisSelector)
  const clinicalAnalysis = useAppSelector(clinicalAnalysisSelector)
  const physicalAnalysis = useAppSelector(physicalAnalysisSelector)
  const recommendations = useAppSelector(recommendationsSelector)
  const prescriptions = useAppSelector(prescriptionsSelector)
  const exams = useAppSelector(examsSelector)
  const medicalCertificate = useAppSelector(medicalCertificateSelector)
  const navigate = useNavigate()

  const [
    finishAppointment,
    { isLoading: isFinishingAppointment, error: finishingAppointmentError },
  ] = useFinishAppointmentMutation()

  async function handleFinish() {
    const appointmentId = appointmentData?._id

    if (appointmentId) {
      await finishAppointment({
        appointmentId,
        anamnesis,
        clinicalAnalysis,
        physicalAnalysis,
        recommendations,
        prescriptions,
        exams,
        medicalCertificate,
      }).unwrap()
      dispatch(changeStatus({ status: Status.Finished }))
      navigate('/dia-de-trabalho')
    }
  }

  return (
    <>
      <AppointmentInfo
        {...(appointmentData as IFormattedAppointment)}
        finishButton={{ isLoading: isFinishingAppointment, handleFinish }}
      >
        <AppointmentTabsContainer />
      </AppointmentInfo>
      {finishingAppointmentError && (
        <ErrorModal error={finishingAppointmentError} />
      )}
    </>
  )
}
