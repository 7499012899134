import { CSSProperties } from '@mui/styled-engine'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  optionContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    '&:hover': {
      background: theme.palette.grey[100],
      cursor: 'pointer',
    },
  },
  optionTitle: {
    ...(theme.typography.body1 as CSSProperties),
    fontWeight: theme.typography.fontWeightBold,
  },
  optionSubtitle: {
    ...(theme.typography.caption as CSSProperties),
  },
}))
