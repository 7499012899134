import { Box, Typography, Divider } from '@mui/material'

import { TableSummary } from '@components/TableSummary/table-summary'
import { IMedicalFile } from '@model/medical-file/medical-file'

import { useStyles } from './health-issues.styles'

interface Props
  extends Partial<Pick<IMedicalFile, 'allergies' | 'medicationInUse'>> {
  title: string
}

export const HealthIssues = ({ title, allergies, medicationInUse }: Props) => {
  const { classes } = useStyles()

  return (
    <TableSummary title={title} icon="calendar">
      <Box className={classes.container}>
        {allergies?.map(item => (
          <>
            <Box className={classes.itemContainer}>
              <Typography className={classes.title}>{item}</Typography>
            </Box>
            <Divider />
          </>
        ))}
        {medicationInUse?.map(item => (
          <>
            <Box className={classes.itemContainer}>
              <Typography className={classes.title}>{item}</Typography>
            </Box>
            <Divider />
          </>
        ))}
      </Box>
    </TableSummary>
  )
}
