import { Add, Close } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Box,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Fab,
  Typography,
} from '@mui/material'

import {
  addPrescription,
  removePrescription,
  prescriptionsSelector,
} from '@slices/appointments/appointments.slice'
import { useAppDispatch, useAppSelector } from '@store/store'

import { Prescriptions } from '../Prescriptions/prescriptions'
import { useStyles } from '../Prescriptions/prescriptions.styles'

export const PrescriptionsCards = () => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const prescriptionsData = useAppSelector(prescriptionsSelector)

  function handleIntervalChange(idx: number, add: number) {
    if (add === 1) {
      dispatch(addPrescription())
      return
    }
    if (add === 0) {
      dispatch(removePrescription({ idx }))
      return
    }
  }

  return (
    <Box className={classes.container}>
      {prescriptionsData.map((item, idx) => (
        <>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              className={classes.accordionSummary}
            >
              <Box className={classes.boxComponentSummary}>
                <Typography className={classes.prescriptionTitle}>
                  Receita {idx + 1}
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => handleIntervalChange(idx, 0)}
                >
                  <Close />
                </IconButton>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Prescriptions prescription={item} prescriptionIdx={idx} />
            </AccordionDetails>
          </Accordion>
        </>
      ))}
      <Box className={classes.boxAddButton}>
        <Fab
          size="medium"
          color="primary"
          onClick={() => handleIntervalChange(0, 1)}
        >
          <Add />
        </Fab>
      </Box>
    </Box>
  )
}
