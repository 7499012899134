import { ReactElement } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import { useStyles } from './modal-container.styles'

interface Props {
  isOpen: boolean
  handleModalClose(): void
  children: ReactElement
  title: string
  id: string
  containerClassname?: string
  closeButton?: boolean
}

export const ModalContainer: React.FC<Props> = ({
  isOpen,
  handleModalClose,
  children,
  title,
  id,
  containerClassname,
  closeButton,
}: Props) => {
  const { classes } = useStyles()
  const theme = useTheme()
  const fullScreen = !useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Dialog
      id={id}
      data-testid={id}
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="xs"
      open={isOpen}
      className={containerClassname}
      onClose={handleModalClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography
          variant="body1"
          className={`${classes.textWeight} ${classes.textColor}`}
        >
          {title}
        </Typography>
        {closeButton && (
          <IconButton
            data-testid="close-button"
            id={`${id}-close`}
            edge="start"
            onClick={handleModalClose}
            aria-label="close"
            className={classes.buttonClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>
        {children}
      </DialogContent>
    </Dialog>
  )
}
