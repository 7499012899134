import { Box } from '@mui/material'
import { EventProps } from 'react-big-calendar'

import { Status } from '@model/appointment/appointment'

import { useStyles } from './event.styles'
import { formatEvents } from '../../utils/format-events/format-events'

export const Event = ({
  event: { status, title },
}: EventProps<ReturnType<typeof formatEvents>[number]>) => {
  const { classes } = useStyles()

  if (status === Status.Canceled) {
    return <Box className={classes.eventSecondary}>{title}</Box>
  } else if (
    status === Status.In_Progress ||
    status === Status.Confirmed ||
    status === Status.Checked_In
  ) {
    return <Box className={classes.eventConfirmed}>{title}</Box>
  } else if (status === Status.Finished) {
    return <Box className={classes.eventFinished}>{title}</Box>
  } else {
    return <Box className={classes.eventPrimary}>{title}</Box>
  }
}
