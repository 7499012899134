import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '4rem',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(1, 3),
  },
  text: {
    color: theme.palette.grey[600],
    fontSize: 11.5,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 11.5,
  },
}))
