import { CSSProperties } from '@mui/styled-engine'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: { marginTop: theme.spacing(3), justifyContent: 'center' },
  gridContainer: { margin: 0, width: '90%' },
  gridExternalContainer: { marginTop: theme.spacing(2 / 8), width: '100%' },
  gridItem: {
    width: '30%',
  },
  title: {
    ...(theme.typography.body1 as CSSProperties),
    fontWeight: theme.typography.fontWeightBold as CSSProperties['fontWeight'],
  },
  accordionSummary: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    margin: 0,
    height: '48px',
    minHeight: 'unset',
  },
  boxComponentSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  boxAddButton: {
    display: 'flex',
    marginTop: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  boxDeleteButton: {
    display: 'flex',
    marginTop: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  boxExamsItems: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  contatinerSolicitations: {
    flex: 4,
  },
  gridSolicitations: {
    width: '100%',
    marginLeft: '2rem',
  },
  selectForm: {
    width: '100%',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  addButton: {
    marginTop: theme.spacing(5 / 8),
    padding: '10px',
  },
  textAddButton: { fontSize: 11 },
}))
