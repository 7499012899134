/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Box,
  createFilterOptions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

import { ExamType, IExam, IExamItem } from '@model/appointment/appointment'
import { changeExams } from '@slices/appointments/appointments.slice'
import { useAppDispatch } from '@store/store'

import { useStyles } from './exams.styles'

interface Props {
  examIdx: number
  exam: IExam
}

export const Exams = ({ exam, examIdx }: Props) => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const filter = createFilterOptions({
    stringify: (option: any) => option,
  })

  function handleChangeExamItem(examItemIdx: number, examItem: IExamItem) {
    dispatch(
      changeExams({
        examIdx,
        examItemIdx,
        examItem,
      }),
    )
  }

  return (
    <Box>
      {exam.examItems.map((item, idx) => (
        <Box className={classes.boxExamsItems}>
          <Grid key={idx} container className={classes.gridContainer}>
            <Grid item style={{ flex: 1 }}>
              <FormControl variant="standard" className={classes.selectForm}>
                <InputLabel id="demo-simple-select-label">
                  Tipo de exame
                </InputLabel>
                <Select
                  value={item.type}
                  onChange={e =>
                    handleChangeExamItem(idx, {
                      ...item,
                      type: e.target.value,
                    })
                  }
                >
                  <MenuItem value={ExamType.Laboratory}>Laboratorial</MenuItem>
                  <MenuItem value={ExamType.Image}>Imagem</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid
              container
              spacing={3}
              className={classes.contatinerSolicitations}
            >
              <Grid item className={classes.gridSolicitations}>
                <Autocomplete
                  value={item.solicitations}
                  multiple
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Solicitações "
                      autoFocus={true}
                    />
                  )}
                  options={[]}
                  freeSolo
                  onChange={(_, __, reason, details) => {
                    const value: any = details?.option

                    if (reason === 'removeOption') {
                      handleChangeExamItem(idx, {
                        ...item,
                        solicitations: [
                          ...item.solicitations.filter(item => item !== value),
                        ],
                      })

                      return
                    }

                    if (reason === 'selectOption') {
                      handleChangeExamItem(idx, {
                        ...item,
                        solicitations: [
                          ...item.solicitations,
                          value.inputValue,
                        ],
                      })
                    } else {
                      handleChangeExamItem(idx, {
                        ...item,
                        solicitations: [...item.solicitations, value],
                      })
                    }
                  }}
                  getOptionLabel={option => {
                    if (typeof option === 'string') {
                      return option
                    }

                    return (option as any).title
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params)

                    const { inputValue } = params
                    const isExisting = options.some(
                      option => inputValue === option,
                    )

                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Adicionar "${inputValue}"`,
                      })
                    }

                    return filtered
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  )
}
