import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    minHeight: '10rem',
  },
  title: {
    color: 'black',
    fontSize: '13.5px',
    marginRight: '1rem',
    fontWeight: 400,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  label: {
    color: 'gray',
    fontSize: '11px',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  value: {
    color: 'black',
    fontSize: '12px',
    fontWeight: 500,
    marginLeft: theme.spacing(2),
  },
}))
