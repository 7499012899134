import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    justifyContent: 'space-between',
    minHeight: '10rem',
    width: '28%',
  },
  contentContainer: {
    padding: theme.spacing(1),
  },
  question: {
    color: 'gray',
    fontSize: '11px',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  answer: {
    color: 'black',
    fontSize: '12px',
    fontWeight: 500,
    marginLeft: theme.spacing(2),
  },
}))
