import { CSSProperties } from '@mui/styled-engine'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    margin: theme.spacing(1, 0),
    minWidth: '20rem',
    backgroundColor: theme.palette.common.white,
    overflowX: 'hidden',
    boxShadow: theme.palette.customShadows.shadow1,
    height: '6rem',
    justifyContent: 'center',
  },
  subcontainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  title: {
    color: 'black',
    fontSize: '13px',
    marginLeft: '1.5rem',
  },
  subtitle: {
    ...(theme.typography.h5 as CSSProperties),
    fontWeight: 600,
    color: theme.palette.additionalColors.title,
    marginLeft: '1.5rem',
    fontSize: '42px',
    marginTop: theme.spacing(-0.5),
  },
  description: {
    fontSize: '10px',
    color: theme.palette.primary.light,
    fontWeight: 'bold',
    maxWidth: '7rem',
    marginRight: '1.5rem',
    textAlign: 'right',
    lineHeight: '0.6rem',
    marginTop: theme.spacing(-1.5),
  },
  icon: {
    color: theme.palette.primary.dark,
    marginRight: '1.5rem',
  },
}))
