import { Box } from '@mui/material'
import clsx from 'clsx'
import { DateCellWrapperProps } from 'react-big-calendar'

import { isInMonth } from '@utils/date/date'

import { useStyles } from './data-cell-wrapper.styles'

export const DataCellWrapper = ({ children, value }: DateCellWrapperProps) => {
  const { classes } = useStyles()

  const isOffRange = !isInMonth(value)

  return (
    <Box
      className={clsx(classes.container, { [classes.offRange]: isOffRange })}
    >
      {children}
    </Box>
  )
}
