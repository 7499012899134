import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'

import { HTTP } from '@config/http'
import { ServiceError } from '@model/service-error/service-error'
import { signOut, updateSession } from '@slices/session/session.slice'
import { RootState } from '@store/store'
import { TAGS } from '@store/tags'
import { isTokenExpired } from '@utils/is-token-expired/is-token-expired'

import { baseQuery } from './base-query'

const { API_URL } = HTTP

export const api = axios.create({
  baseURL: API_URL,
})

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
    },
    unknown,
    undefined | ServiceError
  > =>
  async (args, baseQueryApi, extraOptions) => {
    const { doctor, token } = (baseQueryApi.getState() as RootState)
      .sessionSlice

    let headers = {}
    headers = { ...headers, Authorization: `Bearer ${token}` }

    const hasFile = 'data' in args && '_parts' in args.data

    if (hasFile) {
      headers = {
        ...headers,
        'content-type': 'multipart/form-data',
      }
    }

    try {
      const result = await api.request({
        ...args,
        headers,
      })

      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError

      if (err?.response?.status === 401) {
        if (!isTokenExpired(doctor.refreshToken)) {
          try {
            const response = await api.post('accounts/doctor/refresh-token', {
              token: doctor.refreshToken,
            })

            const { token, refreshToken: newRefreshToken } = response.data

            baseQueryApi.dispatch(
              updateSession({ token, refreshToken: newRefreshToken }),
            )

            const result = await baseQuery(token)(
              { body: args.data, ...args },
              baseQueryApi,
              extraOptions,
            )

            if (result.error) {
              const errorData = result.error.data
              const status = result.error.status

              return {
                error: {
                  status,
                  data:
                    typeof errorData === 'string'
                      ? { message: errorData }
                      : errorData,
                } as ServiceError,
              }
            }

            return result
          } catch {
            baseQueryApi.dispatch(signOut())
          }
        } else {
          baseQueryApi.dispatch(signOut())
        }
      }

      const errorData = err.response?.data || err.message

      return {
        error: {
          status: err.response?.status,
          data:
            typeof errorData === 'string' ? { message: errorData } : errorData,
        } as ServiceError,
      }
    }
  }

export const baseApi = createApi({
  reducerPath: 'api',
  tagTypes: TAGS,
  endpoints: () => ({}),
  baseQuery: axiosBaseQuery(),
})
