import { Box } from '@mui/material'
import { DateHeaderProps } from 'react-big-calendar'

import { useStyles } from './date-header.styles'

export const DateHeader = ({ label }: DateHeaderProps) => {
  const { classes } = useStyles()

  return <Box className={classes.container}>{label}</Box>
}
