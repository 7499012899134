import { CSSProperties } from '@mui/styled-engine'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.grey[300],
  },
  animation: {
    width: '15rem',
  },
  titleContainer: {
    width: '50%',
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    padding: theme.spacing(5, 10),
    background: theme.palette.common.white,
    zIndex: theme.zIndex.appBar,
    boxShadow: theme.palette.customShadows.shadow2,
  },
  logo: {
    maxWidth: '14rem',
  },
  form: {
    width: '100%',
    margin: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  forgotPasswordText: {
    ...(theme.typography.button as CSSProperties),
    cursor: 'pointer',
    textTransform: 'none',
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    fontSize: '12px',
  },
  signupText: {
    cursor: 'pointer',
    ...(theme.typography.button as CSSProperties),
    textTransform: 'none',
    color: '#707070',
    fontWeight: 300,
    fontSize: 12,
    marginRight: '1rem',
  },
  title: {
    fontSize: 25,
    color: theme.palette.common.white,
    fontWeight: 500,
    width: '70%',
    textAlign: 'center',
    lineHeight: '2rem',
  },
  subTitle: {
    fontSize: 18,
    color: theme.palette.common.white,
    fontWeight: 300,
    width: '70%',
    textAlign: 'center',
  },
  signupContainer: {
    display: 'flex',
  },
}))
