import { Box, Typography, Grid } from '@mui/material'
import clsx from 'clsx'
import {
  BsGridFill,
  BsFillClockFill,
  BsFillCheckSquareFill,
  BsPersonPlusFill,
} from 'react-icons/bs'

import { useStyles } from './dashboard-card.styles'
import { PizzaGraph } from '../PizzaGraph/pizza-graph'

interface Props {
  title: string
  value?: string | number
  description?: string
  graphData?: { y: number; name: string }[]
  graphName?: string
  icon: string
}

export const DashboardCard = ({
  title,
  value,
  description,
  graphData,
  graphName,
  icon,
}: Props) => {
  const { classes } = useStyles()

  return (
    <Grid item className={classes.container}>
      <Box className={classes.headerContainer}>
        <Box className={classes.headerContent}>
          <Typography className={classes.title}>{title}</Typography>
          {icon === 'checkbox' && (
            <BsFillCheckSquareFill size={24} className={classes.icon} />
          )}
          {icon === 'square' && (
            <BsGridFill size={24} className={classes.icon} />
          )}
          {icon === 'clock' && (
            <BsFillClockFill size={24} className={classes.icon} />
          )}
          {icon === 'person' && (
            <BsPersonPlusFill size={24} className={classes.icon} />
          )}
        </Box>
        <Typography className={clsx([classes.subtitle])}>{value}</Typography>
      </Box>

      {graphData && graphName && graphData.length > 0 && description && (
        <Box className={classes.resultContainer}>
          <Typography className={classes.description}>{description}</Typography>
          <PizzaGraph name={graphName} data={graphData} />
        </Box>
      )}
    </Grid>
  )
}
