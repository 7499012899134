import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  dialogTitle: {
    backgroundColor: theme.palette.common.white,
  },
  textWeight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  buttonClose: {
    position: 'absolute',
    top: theme.spacing(1),
    right: 0,
  },
}))
