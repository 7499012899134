import { Button, Stack, Typography } from '@mui/material'
import Lottie from 'react-lottie'
import { useNavigate } from 'react-router-dom'

import { useStyles } from '@app/home/components/DayOff/day-off.styles'
import Balloons from '@assets/balloons.png'
import * as animation from '@assets/day-off-animation.json'

export const DayOff = () => {
  const { classes } = useStyles()
  const navigate = useNavigate()

  function handleNavigation() {
    navigate('/agenda')
  }

  return (
    <Stack spacing={2} className={classes.root}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animation,
        }}
        height={500}
        width={500}
      />

      <Stack direction="column" spacing={3}>
        <Typography variant="h1" className={classes.title}>
          Dia de <span className={classes.highlight}>Folga</span>
          <img src={Balloons} alt="balloons" className={classes.icon} />
        </Typography>
        <Typography className={classes.content}>
          Aparentemente, não temos consultas marcadas para hoje. Aproveite seu
          dia de folga ou já trabalhe nos próximos agendamentos :)
        </Typography>
        <Button onClick={handleNavigation} className={classes.button}>
          Agendar nova consulta
        </Button>
      </Stack>
    </Stack>
  )
}
