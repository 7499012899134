import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

import { useStyles } from '@components/ProgressChart/progress-chart.styles'

interface Props {
  value: number
}

export const ProgressChart = ({ value }: Props) => {
  const { classes } = useStyles()
  const formattedValue = Math.round(value)
  const valueString = `${formattedValue} %`

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        size={90}
        value={value}
        thickness={5}
        className={classes.graph}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          className={classes.caption}
        >
          {valueString}
        </Typography>
      </Box>
    </Box>
  )
}
