import { ChangeEvent } from 'react'

import { Box, Tab, Tabs } from '@mui/material'

import { PrescriptionsTabs } from '@slices/appointments/appointments.model'
import {
  changeCurrentPrescriptionsTab,
  currentPrescriptionsTabSelector,
} from '@slices/appointments/appointments.slice'
import { useAppDispatch, useAppSelector } from '@store/store'

import { useStyles } from './prescriptions-tab.styles'
import { ExamsCards } from '../ExamsCard/exams-cards'
import { MedicalCertificate } from '../MedicalCertificate/medicalCertificate'
import { PrescriptionsCards } from '../PrescriptionsCard/prescriptions-cards'

export const PrescriptionsTab = () => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()

  const currentPrescriptionsTab = useAppSelector(
    currentPrescriptionsTabSelector,
  )

  function handleTabChange(
    _: ChangeEvent<unknown>,
    newValue: PrescriptionsTabs,
  ) {
    dispatch(changeCurrentPrescriptionsTab({ prescriptionsTab: newValue }))
  }
  return (
    <Box className={classes.container}>
      <Tabs
        value={currentPrescriptionsTab}
        classes={{ indicator: classes.indicator, root: classes.root }}
        onChange={handleTabChange}
      >
        <Tab
          className={classes.tab}
          value={PrescriptionsTabs.Prescriptions}
          label="Prescrições"
        />
        <Tab
          className={classes.tab}
          value={PrescriptionsTabs.Exams}
          label="Exames"
        />
        <Tab
          className={classes.tab}
          value={PrescriptionsTabs.MedicalCertificate}
          label="Atestado"
        />
      </Tabs>
      {currentPrescriptionsTab === PrescriptionsTabs.Prescriptions && (
        <PrescriptionsCards />
      )}
      {currentPrescriptionsTab === PrescriptionsTabs.Exams && <ExamsCards />}
      {currentPrescriptionsTab === PrescriptionsTabs.MedicalCertificate && (
        <MedicalCertificate />
      )}
    </Box>
  )
}
