import { ChangeEvent, useState } from 'react'

import { Tab, Tabs, Typography } from '@mui/material'

import { ModalContainer } from '@components/ModalContainer/modal-container'
import { doctorSelector } from '@slices/session/session.slice'
import { useAppSelector } from '@store/store'

import { useStyles } from './profile-tabs-container.styles'
import { ProfileTabs } from '../../model/profile-tabs/profile-tabs'
import { PersonalTab } from '../PersonalTab/personal-tab'
import { ProfileImageTab } from '../ProfileImageTab/profile-image-tab'
import { WorkWindowsTab } from '../WorkWindowsTab/work-windows-tab'

export const ProfileTabsContainer = () => {
  const { classes } = useStyles()
  const [tab, setTab] = useState(ProfileTabs.Personal)
  const doctor = useAppSelector(doctorSelector)
  const [isModalOpen, setIsModalOpen] = useState(doctor.firstLogin)

  function closeModal() {
    setIsModalOpen(false)
  }

  function handleTabChange(_: ChangeEvent<unknown>, newValue: ProfileTabs) {
    setTab(newValue)
  }

  return (
    <>
      <Tabs
        value={tab}
        textColor="primary"
        classes={{ indicator: classes.indicator, root: classes.root }}
        variant="scrollable"
        onChange={handleTabChange}
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab className={classes.tab} label="Informações pessoais" />
        <Tab className={classes.tab} label="Horários" />
        <Tab className={classes.tab} label="Foto de perfil" />
      </Tabs>
      {tab === ProfileTabs.Personal && <PersonalTab />}
      {tab === ProfileTabs.Work_Windows && <WorkWindowsTab />}
      {tab === ProfileTabs.Profile_Image && <ProfileImageTab />}
      <ModalContainer
        isOpen={isModalOpen}
        closeButton
        id="sign-up-modal-success"
        title="Bem vindo novamente!"
        handleModalClose={closeModal}
      >
        <>
          <Typography>
            Como é a sua primeira vez por aqui, que tal adicionar mais algumas
            configurações?
          </Typography>
        </>
      </ModalContainer>
    </>
  )
}
