import { AccessTime, ArrowRight, KeyboardArrowRight } from '@mui/icons-material'
import { Typography, Button, Avatar, Stack } from '@mui/material'
import Lottie from 'react-lottie'
import { useNavigate } from 'react-router-dom'

import { AppointmentStatusButton } from '@app/home/components/AppointmentStatusButton/appointment-status-button'
import { useStyles } from '@app/home/components/NextAppointment/next-appointments.styles'
import * as EmpytLobbyAnimation from '@assets/empty-lobby-animation.json'
import { ErrorModal } from '@components/ErrorModal/error-modal'
import { Loader } from '@components/Loader/loader'
import { IFormattedAppointment, Status } from '@model/appointment/appointment'
import { IPatient } from '@model/patient/patient'
import {
  useMoveAppointmentToNextStatusMutation,
  useUpdateAppointmentStatusMutation,
} from '@slices/appointments/appointments.api'
import {
  changeStatus,
  currentAppointmentSelector,
} from '@slices/appointments/appointments.slice'
import { setPatient } from '@slices/patient/patient.slice'
import { useAppDispatch, useAppSelector } from '@store/store'

export const NextAppointment = () => {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const nextAppointment = useAppSelector(currentAppointmentSelector)

  const [
    moveAppointmentToNextStatus,
    {
      error: moveAppointmentToNextStatusError,
      isLoading: isMovingAppointmentToNextStatus,
    },
  ] = useMoveAppointmentToNextStatusMutation()

  const [
    updateAppointmentStatus,
    {
      error: updateAppointmentStatusError,
      isLoading: isUpdatingAppointmentStatusError,
    },
  ] = useUpdateAppointmentStatusMutation()

  const isLoading =
    isMovingAppointmentToNextStatus || isUpdatingAppointmentStatusError

  const error = moveAppointmentToNextStatusError || updateAppointmentStatusError

  function handleNavigation(patient?: IPatient) {
    if (patient) {
      dispatch(setPatient(patient))
      navigate(`/pacientes/${patient._id}`)
    }
  }

  async function handleCancelation(appointmentId?: string) {
    if (appointmentId) {
      await updateAppointmentStatus({
        appointmentId,
        status: Status.Canceled,
      }).unwrap()
      navigate('/dia-de-trabalho')
    }
  }

  async function handleActionButton(
    appointment: IFormattedAppointment,
    status: string,
  ) {
    switch (status) {
      case Status.Scheduled:
      case Status.Confirmed:
        await handleStatusChange(appointment._id)
        break
      case Status.Checked_In:
        await handleStatusChange(appointment._id)
        navigate(`/dia-de-trabalho/consulta/${appointment._id}`)
        break
      case Status.In_Progress:
        await handleStatusChange(appointment._id)
        navigate(`/dia-de-trabalho/consulta/${appointment._id}`)
        break
    }
  }

  if (isLoading) {
    return <Loader />
  }

  if (!nextAppointment) {
    return (
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: EmpytLobbyAnimation,
        }}
        height={200}
        width={200}
      />
    )
  }

  const { formattedTime, patient, translatedStatus, status } = nextAppointment
  const { name, imageUrl } = patient

  async function handleStatusChange(appointmentId: string) {
    if (appointmentId && status !== Status.In_Progress) {
      const response = await moveAppointmentToNextStatus({
        appointmentId,
      }).unwrap()
      dispatch(changeStatus({ status: response.appointment.status }))
    }
  }

  return (
    <>
      <Stack data-testid="next-appointment">
        <Stack className={classes.header}>
          <Stack>
            <Typography className={classes.title}>Próxima Consulta</Typography>
            <Typography className={classes.appointmentTime}>
              <AccessTime className={classes.clockIcon} />
              {formattedTime}
            </Typography>
          </Stack>
          <Button
            data-testid="cancel-button"
            onClick={() => handleCancelation(nextAppointment._id)}
            className={classes.cancelButton}
          >
            <Typography className={classes.cancelText}>
              Cancelar Consulta
            </Typography>
            <KeyboardArrowRight className={classes.cancelArrow} />
          </Button>
        </Stack>
        <Stack className={classes.summary}>
          <Avatar src={imageUrl} className={classes.profileImage} />
          <Typography className={classes.name}>{name}</Typography>
          <Typography className={classes.status}>
            <ArrowRight className={classes.statusIcon} />
            {translatedStatus}
          </Typography>
        </Stack>
        <Button
          data-testid={`profile-${patient._id}-button`}
          startIcon={<Avatar className={classes.profileButtonIcon} />}
          onClick={() => handleNavigation(patient)}
          className={classes.profileButton}
        >
          Acessar Perfil
        </Button>
        <AppointmentStatusButton
          data-testid={`update-status-${patient._id}-button`}
          status={status}
          onClick={() => handleActionButton(nextAppointment, status)}
        />
      </Stack>

      {error && <ErrorModal error={error} />}
    </>
  )
}
