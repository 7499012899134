import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab'
import {
  Card,
  CardContent,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Grid,
  CardActions,
} from '@mui/material'
import { PDFDownloadLink } from '@react-pdf/renderer'

import { PrescriptionFile } from '@app/appointment/components/PrescriptionFile/prescription-file'
import {
  IAnamnesis,
  IClinicalAnalysis,
  IExam,
  IMedicalCertificate,
  IPhysicalAnalysis,
  IPrescription,
  IPrescriptionItem,
} from '@model/appointment/appointment'
import { doctorSelector } from '@slices/session/session.slice'
import { useAppSelector } from '@store/store'

import { useStyles } from './analysis-card.styles'

export interface Props {
  title: string
  patientName: string
  isPhysicalAnalysis?: boolean
  analisys:
    | string[]
    | IAnamnesis[]
    | IPhysicalAnalysis[]
    | IClinicalAnalysis
    | IPrescription[]
    | IExam[]
    | IMedicalCertificate
}

export const AnalysisCard = ({
  analisys,
  title,
  isPhysicalAnalysis,
  patientName,
}: Props) => {
  const { classes } = useStyles()

  const lenghtAnalisys = (
    analisys as
      | IPrescriptionItem[]
      | string[]
      | string
      | IAnamnesis[]
      | IPhysicalAnalysis[]
  ).length

  const doctor = useAppSelector(doctorSelector)

  return (
    <>
      <Card className={classes.container}>
        <CardContent>
          <Typography gutterBottom className={classes.title}>
            {title}
          </Typography>

          {'period' in analisys && (
            <>
              <Typography
                className={classes.diagnostic}
                data-testid="medical-certificate"
              >
                {`Paciente ${patientName} está com atestado de ${analisys.period}`}
              </Typography>
              <Box className={classes.analisysContainer}>
                <Box>
                  <Typography className={classes.description}>
                    Motivo
                  </Typography>

                  <Typography className={classes.listTitle}>
                    {analisys.reason}
                  </Typography>
                </Box>
              </Box>
              <CardActions>
                <PDFDownloadLink
                  document={
                    <PrescriptionFile
                      patient={patientName}
                      doctor={doctor}
                      title={'ATESTADO'}
                      medicalCertificate={analisys}
                    />
                  }
                  fileName={`${patientName}_atestado.pdf`}
                >
                  Imprimir prescrição
                </PDFDownloadLink>
              </CardActions>
            </>
          )}
          {'diagnosis' in analisys && (
            <>
              <Typography className={classes.diagnostic}>
                {analisys.diagnosis}
              </Typography>
              <Box className={classes.analisysContainer}>
                <Box>
                  <Typography className={classes.description}>
                    Descrição
                  </Typography>

                  <Typography className={classes.listTitle}>
                    {analisys.description}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          {0 in analisys &&
            !isPhysicalAnalysis &&
            analisys.map((item, idx) => {
              if (typeof item === 'string')
                return (
                  <Box className={classes.analisysContainer}>
                    <Box className={classes.boxFlexItems}>
                      <Divider className={classes.border} />
                      <Typography className={classes.listTitle}>
                        {item}
                      </Typography>
                    </Box>
                  </Box>
                )

              if ('prescriptionItems' in item)
                return (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                    >
                      <Typography gutterBottom className={classes.title}>
                        {`RECEITA ${idx + 1}`}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        className={classes.prescriptionContainer}
                        data-testid="prescription-container"
                      >
                        {item.prescriptionItems.map(text => (
                          <div className={classes.boxItemsPrescription}>
                            <Divider className={classes.border} />
                            <Typography className={classes.listTitle}>
                              {text.medicine}
                            </Typography>
                          </div>
                        ))}
                      </Box>

                      <CardActions>
                        <PDFDownloadLink
                          document={
                            <PrescriptionFile
                              patient={patientName}
                              doctor={doctor}
                              prescription={
                                item.prescriptionItems as IPrescriptionItem[]
                              }
                              title="RECEITA"
                            />
                          }
                          fileName={`${patientName}_receita.pdf`}
                        >
                          Imprimir prescrição
                        </PDFDownloadLink>
                      </CardActions>
                    </AccordionDetails>
                  </Accordion>
                )

              if ('examItems' in item)
                return (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                    >
                      <Typography gutterBottom className={classes.title}>
                        {`EXAME ${idx + 1} | ${item.examItems[0].type}`}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        className={classes.prescriptionContainer}
                        data-testid="exam-container"
                      >
                        {(item.examItems[0].solicitations as [string]).map(
                          text => (
                            <div className={classes.boxItemsPrescription}>
                              <Divider className={classes.border} />
                              <Typography className={classes.listTitle}>
                                {text}
                              </Typography>
                            </div>
                          ),
                        )}
                      </Box>

                      <CardActions>
                        <PDFDownloadLink
                          document={
                            <PrescriptionFile
                              patient={patientName}
                              doctor={doctor}
                              examItem={item.examItems[0]}
                              title="EXAME"
                            />
                          }
                          fileName={`${patientName}_exame.pdf`}
                        >
                          Imprimir prescrição
                        </PDFDownloadLink>
                      </CardActions>
                    </AccordionDetails>
                  </Accordion>
                )

              const anamnesis = item as IAnamnesis

              return (
                <Box className={classes.anamneseContainer}>
                  <Box className={classes.boxFlexItems}>
                    <Divider className={classes.border} />
                    <Box className={classes.boxItemsAnamnese}>
                      <Typography className={classes.listTitle}>
                        {anamnesis.complaint}
                      </Typography>
                      {anamnesis.intensity.length > 0 ? (
                        <Box
                          className={classes.boxListSecondary}
                          data-testid="anamnesis-container"
                        >
                          <Typography className={classes.listTitleSecondary}>
                            {anamnesis.intensity}
                          </Typography>
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              )
            })}

          {isPhysicalAnalysis && (
            <Timeline
              data-testid="physical-analysis-container"
              className={classes.timeLine}
            >
              {(analisys as IPhysicalAnalysis[]).map((text, idx) => (
                <Grid className={classes.boxPhysicalAnalysis}>
                  <TimelineItem sx={{ minHeight: 'auto' }}>
                    <TimelineSeparator>
                      <TimelineDot color="primary" />
                      {lenghtAnalisys - 1 === idx ? (
                        <></>
                      ) : (
                        <TimelineConnector
                          sx={{ backgroundColor: '#3c91e6', minHeight: '20px' }}
                        />
                      )}
                    </TimelineSeparator>
                    <TimelineContent sx={{ fontSize: '13px' }}>
                      {text.index}
                    </TimelineContent>
                  </TimelineItem>
                  {text.value && text.value.length > 0 ? (
                    <Box className={classes.boxListSecondary}>
                      <Typography className={classes.listTitleSecondary}>
                        {text.value}
                      </Typography>
                    </Box>
                  ) : null}
                </Grid>
              ))}
            </Timeline>
          )}
        </CardContent>
      </Card>
    </>
  )
}
