import 'react-big-calendar/lib/css/react-big-calendar.css'
import { ComponentType, useState } from 'react'

import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar'

import { ErrorModal } from '@components/ErrorModal/error-modal'
import { Loader } from '@components/Loader/loader'
import { COMMON } from '@config/common'
import { ptBRLocalizer } from '@config/datetime'
import {
  useCreateAppointmentMutation,
  useGetAppointmentsQuery,
} from '@slices/appointments/appointments.api'
import { IPeriod } from '@slices/appointments/appointments.model'
import { normalizedDate } from '@utils/date/date'

import { AppointmentDetail } from './components/AppointmentDetailModal/appointment-detail-modal'
import { CreateAppointmentModal } from './components/CreateAppointmentModal/create-appointment-modal'
import { DataCellWrapper } from './components/DataCellWrapper/data-cell-wrapper'
import { DateHeader } from './components/DateHeader/date-header'
import { DayColumnWrapper } from './components/DayColumnWrapper/day-column-wrapper'
import { Event } from './components/Event/event'
import { EventWrapper } from './components/EventWrapper/event-wrapper'
import { Weekday } from './components/Weekday/weekday'
import { ICreateAppointmentForm } from './model/create-appointment-form/create-appointment-form'
import { formatCreateAppointmentPayload } from './utils/format-create-appointment-payload/format-create-appointment-payload'
import { formatEvents } from './utils/format-events/format-events'

const { POLLING_INTERVAL_IN_SECONDS } = COMMON

const localizer = dateFnsLocalizer(ptBRLocalizer)

export const Calendar = () => {
  const [newAppointmentModalIsOpen, setNewAppointmentModalIsOpen] =
    useState(false)
  const [appointmentDetailContent, setAppointmentDetailContent] = useState<
    ReturnType<typeof formatEvents>[number] | null
  >(null)
  const [date, setDate] = useState<string>(
    normalizedDate(new Date(), true) as string,
  )
  const [period, setPeriod] = useState<IPeriod>('month')

  function handleOpenAppointmentModal() {
    setNewAppointmentModalIsOpen(true)
  }

  function handleCloseAppointmentModal() {
    setNewAppointmentModalIsOpen(false)
  }

  function handleOpenAppointmentDetailModal(
    data: ReturnType<typeof formatEvents>[number],
  ) {
    if (period === 'month') return

    setAppointmentDetailContent(data)
  }

  function handleCloseAppointmentDetailModal() {
    setAppointmentDetailContent(null)
  }

  const {
    isLoading: isListingAppointments,
    data: getAppointments,
    error: listingAppointmentsError,
  } = useGetAppointmentsQuery(
    { date, period },
    {
      pollingInterval: POLLING_INTERVAL_IN_SECONDS,
    },
  )

  const [
    createAppointment,
    { isLoading: isCreatingAppointment, error: creatingAppointmentError },
  ] = useCreateAppointmentMutation()

  async function handleSubmit(data: ICreateAppointmentForm) {
    handleCloseAppointmentModal()

    const payload = formatCreateAppointmentPayload(data)

    await createAppointment(payload).unwrap()
  }

  if (isListingAppointments || isCreatingAppointment) {
    return <Loader />
  }

  const error = creatingAppointmentError || listingAppointmentsError

  return (
    <>
      <BigCalendar
        selectable={true}
        localizer={localizer}
        date={date}
        components={{
          event: Event,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          eventWrapper: EventWrapper as ComponentType<any>,
          dateCellWrapper: DataCellWrapper as ComponentType,
          dayColumnWrapper: DayColumnWrapper as ComponentType,
          header: Weekday,
          month: {
            dateHeader: DateHeader,
          },
        }}
        events={formatEvents(getAppointments?.appointments)}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 700, width: '100%', minWidth: '60rem' }}
        step={15}
        views={['month', 'day']}
        defaultView={'day'}
        onSelectSlot={handleOpenAppointmentModal}
        onSelectEvent={handleOpenAppointmentDetailModal}
        view={period}
        onShowMore={() => setPeriod('day')}
        onView={newView => setPeriod(newView as IPeriod)}
        onNavigate={newDate => setDate(newDate.toISOString())}
        formats={{
          weekdayFormat: 'EEE',
          dayFormat: 'EEE',
        }}
        messages={{
          month: 'Mês',
          previous: 'Anterior',
          next: 'Próximo',
          week: 'Semana',
          day: 'Dia',
          today: 'Hoje',
          showMore: () => 'Mostrar mais',
        }}
        culture="pt-BR"
      />
      <CreateAppointmentModal
        modalIsOpen={newAppointmentModalIsOpen}
        handleCloseModal={handleCloseAppointmentModal}
        handleSubmit={handleSubmit}
        date={date}
      />
      {appointmentDetailContent && (
        <AppointmentDetail
          modalIsOpen={Boolean(appointmentDetailContent)}
          handleCloseModal={handleCloseAppointmentDetailModal}
          data={appointmentDetailContent}
        />
      )}
      {error && <ErrorModal error={error} />}
    </>
  )
}
