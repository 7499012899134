import { AccessAlarm, Wallet } from '@mui/icons-material'
import { Typography } from '@mui/material'

import { ModalContainer } from '@components/ModalContainer/modal-container'
import { IPaymentMethod } from '@model/appointment/appointment'
import { formatTime } from '@utils/date/date'
import { translateStatus } from '@utils/status/status'
import { translatePaymentMethod } from '@utils/translate-payment-method/translate-payment-method'

import { useStyles } from './appointment-detail-modal.styles'
import { formatEvents } from '../../utils/format-events/format-events'

interface Props {
  data: ReturnType<typeof formatEvents>[number]
  modalIsOpen: boolean
  handleCloseModal(): void
}

export const AppointmentDetail = ({
  data,
  modalIsOpen,
  handleCloseModal,
}: Props) => {
  const { classes } = useStyles()
  const { title, status, paymentMethod, start } = data

  const modalTitle = `${title} - ${translateStatus(status)}`

  return (
    <ModalContainer
      isOpen={modalIsOpen}
      handleModalClose={handleCloseModal}
      title={modalTitle}
      id="appointment-detail"
      closeButton
    >
      <div className={classes.container}>
        <div className={classes.content}>
          <Wallet className={classes.icon} />
          <Typography className={classes.value}>
            {translatePaymentMethod(paymentMethod as IPaymentMethod)}
          </Typography>
        </div>
        <div className={classes.content}>
          <AccessAlarm className={classes.icon} />
          <Typography className={classes.value}>
            {formatTime(start as string)}
          </Typography>
        </div>
      </div>
    </ModalContainer>
  )
}
