import { Stack, Typography } from '@mui/material'

import { TimelineAppointment } from '@app/home/components/TimelineAppointment/timeline-appointment'
import { IFormattedAppointment } from '@model/appointment/appointment'
import { formatDate } from '@utils/date/date'

interface Props {
  appointments: IFormattedAppointment[]
}

const scroll = {
  paddingTop: '1em',
  overflowX: 'scroll',
}

export const DailyTimeline = ({ appointments }: Props) => {
  const today = formatDate(new Date(), true)

  return (
    <Stack spacing={2} direction="column">
      <Stack>
        <Typography variant="body1">Trilha do Dia</Typography>
        <Typography variant="body2" color={theme => theme.palette.primary.main}>
          {today}
        </Typography>
      </Stack>
      <Stack spacing={6} direction="row" sx={scroll}>
        {appointments.map(appointment => (
          <TimelineAppointment appointment={appointment} />
        ))}
      </Stack>
    </Stack>
  )
}
