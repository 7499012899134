import { CSSProperties } from '@mui/styled-engine'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  appointmentsInfoContainer: {
    margin: theme.spacing(2, 0),
    width: '100%',
  },
  actionButtonsContainer: {
    marginTop: theme.spacing(1),
  },
  datetimeContainer: {
    margin: theme.spacing(1, 0),
    height: '4rem',
  },
  datetimeSubContainer: {
    height: '4rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '3rem',
    marginRight: '3rem',
    marginTop: '1rem',
  },
  datetimeDate: {
    ...(theme.typography.h5 as CSSProperties),
    color: theme.palette.primary.main,
    fontSize: 17,
    fontWeight: theme.typography.fontWeightBold,
  },
  datetimeTitle: {
    ...(theme.typography.h5 as CSSProperties),
    color: theme.palette.common.black,

    fontSize: 17,
  },
  workdayContainer: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.2, 1),
    display: 'flex',
    alignItems: 'center',
    boxShadow: theme.palette.customShadows.shadow2,
  },
  workdayIcon: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },
  timelineContainer: {
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(45),
    width: '100%',
    overflowX: 'hidden',
    boxShadow: theme.palette.customShadows.shadow1,
    borderRadius: 5,
    alignItems: 'center',
    minWidth: '55rem',
  },
  timelineContentContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '60rem',
    height: '100%',
    marginTop: '1.5rem',
  },
  scrollBar: {
    width: '60rem',

    '& .simplebar-content': {
      height: '100%',
    },
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  button: {
    minHeight: '3.2rem',
    margin: 5,
    width: '16rem',
    borderRadius: 5,
    color: theme.palette.common.white,
    fontSize: 11,
    backgroundColor: theme.palette.primary.dark,
    ...(theme.typography.body1 as CSSProperties),
    fontWeight: theme.typography.fontWeightBold,
  },
  cancelNextAppointmentButton: {
    color: theme.palette.common.white,
    fontSize: 14,
    backgroundColor: theme.palette.grey[400],
  },
  cancelAllButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[600],
    fontSize: 14,
  },
  nextAppointment: {
    backgroundColor: theme.palette.primary.dark,
    ...(theme.typography.body1 as CSSProperties),
    color: theme.palette.common.white,
    minHeight: '3.2rem',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    margin: theme.spacing(1, 0),
    minWidth: '20rem',
    backgroundColor: theme.palette.common.white,
    overflowX: 'hidden',
    boxShadow: theme.palette.customShadows.shadow1,
    height: '6rem',
    justifyContent: 'center',
  },
  subcontainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  title: {
    color: 'black',
    fontSize: '13px',
    marginLeft: '1.5rem',
  },
  subtitle: {
    ...(theme.typography.h5 as CSSProperties),
    fontWeight: 600,
    color: theme.palette.additionalColors.title,
    marginLeft: '1.5rem',
    fontSize: '42px',
    marginTop: theme.spacing(-0.5),
  },
  noAppointments: {
    ...(theme.typography.h5 as CSSProperties),
    fontWeight: 600,
    color: theme.palette.additionalColors.title,
    marginLeft: '1.5rem',
  },
  icon: {
    color: theme.palette.primary.dark,
    marginRight: '1.5rem',
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '60rem',
    height: '20rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyTitle: {
    fontSize: 18,
    fontWeight: 600,
  },
  emptySubtitle: {
    fontSize: 18,
    color: '#1273eb',
    opacity: 0.9,
    textDecorationLine: 'underline',
  },
  emptyImage: {
    width: '20rem',
    height: 'auto',
  },
}))
