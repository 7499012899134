import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  rowContainer: {
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.dark}`,
    },
    display: 'flex',
    flexDirection: 'column',
    minHeight: '4rem',
    justifyContent: 'center',
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  cell: {
    color: theme.palette.grey[600],
    fontSize: 11.5,
  },
  title: {
    marginLeft: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 11.5,
  },
  seeText: {
    color: theme.palette.primary.light,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 11.5,
  },
  icon: {
    color: theme.palette.primary.light,
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(0.5),
  },
}))
