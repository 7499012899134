import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  avatar: {
    height: '2em',
    width: '2em',
  },
  button: {
    textTransform: 'none',
    fontSize: '0.8em',
    whiteSpace: 'nowrap',
  },
}))
