import { ChangeEvent, useCallback, useState } from 'react'

export const usePagination = () => {
  const [page, setPage] = useState(0)

  const handleChangePage = useCallback(
    (_: ChangeEvent<unknown>, newPage: number) => {
      setPage(newPage + 1)
    },
    [],
  )

  return { page, handleChangePage }
}
