import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import { Box, Button, Typography } from '@mui/material'
import clsx from 'clsx'
import { BsFillFlagFill, BsPlayCircleFill } from 'react-icons/bs'

import { ITranslateStatusResponse } from '@utils/status/status'

import { useStyles } from './appointment-card.styles'

interface Props {
  patientName: string
  translatedStatus: ITranslateStatusResponse
  number: number
  time: string
  handleDetails(): void
}

export const AppointmentCard = ({
  patientName,
  translatedStatus,
  number,
  time,
  handleDetails,
}: Props) => {
  const { classes } = useStyles()
  const parsedStatus =
    translatedStatus === 'Agendada' ? 'Não confirmada' : translatedStatus

  const isGreenStatus =
    parsedStatus === 'Confirmada' ||
    parsedStatus === 'Check-in realizado' ||
    parsedStatus === 'Em progresso' ||
    parsedStatus === 'Finalizada'

  const icon = () => {
    switch (parsedStatus) {
      case 'Confirmada':
      case 'Check-in realizado':
        return <CheckCircleIcon fontSize="small" color="inherit" />
      case 'Cancelada':
        return <HighlightOffIcon fontSize="small" color="inherit" />
      case 'Não confirmada':
        return <WatchLaterIcon fontSize="small" color="inherit" />
      case 'Finalizada':
        return <BsFillFlagFill fontSize="small" color="inherit" />
      case 'Em progresso':
        return <BsPlayCircleFill fontSize="small" color="inherit" />
      default:
        return <WatchLaterIcon fontSize="small" color="inherit" />
    }
  }

  return (
    <Box className={classes.container}>
      {parsedStatus === 'Finalizada' ? (
        <Typography className={classes.numberOfAppointmentFinished}>{`${
          number + 1
        }º consulta`}</Typography>
      ) : (
        <Typography className={classes.numberOfAppointment}>{`${
          number + 1
        }º consulta`}</Typography>
      )}
      <Box className={classes.contentContainer}>
        <Typography className={clsx(classes.text, classes.title)} noWrap>
          {patientName}
        </Typography>

        <Box
          className={clsx(classes.statusContainer, {
            [classes.confirmed]: isGreenStatus,
            [classes.canceled]: parsedStatus === 'Cancelada',
            [classes.checked]: parsedStatus === 'Check-in realizado',
            [classes.realized]: parsedStatus === 'Finalizada',
            [classes.progress]: parsedStatus === 'Em progresso',
            [classes.otherwise]: parsedStatus === 'Não confirmada',
          })}
        >
          {icon()}
          <Typography className={clsx(classes.text, classes.status)}>
            {parsedStatus}
          </Typography>
        </Box>
        <Typography className={classes.text}>{time}</Typography>
      </Box>
      <Button onClick={handleDetails} className={classes.button}>
        Ver detalhes
      </Button>
    </Box>
  )
}
