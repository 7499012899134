import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  eventPrimary: {
    borderRadius: theme.spacing(0.625),
    background: theme.palette.warning.light,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
  },
  eventSecondary: {
    borderRadius: theme.spacing(0.625),
    color: theme.palette.common.black,
    background: theme.palette.error.light,
    textDecoration: 'line-through',
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
  },
  eventConfirmed: {
    borderRadius: theme.spacing(0.625),
    background: theme.palette.success.main,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
  },
  eventFinished: {
    borderRadius: theme.spacing(0.625),
    background: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
  },
}))
