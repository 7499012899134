import { Stack, Typography } from '@mui/material'

import { ProgressChart } from '@components/ProgressChart/progress-chart'
import { formatNumber } from '@utils/number/number'

interface Props {
  summary: {
    appointments: number
    confirmed: number
    finished: number
    lobby: number
  }
}

const fontWeight = 550

export function DailySummary({ summary }: Props) {
  const { appointments, confirmed, finished, lobby } = summary
  const percentage = (finished / appointments) * 100

  const stats = {
    appointments: {
      value: formatNumber(appointments),
      label: 'Agendamentos',
    },
    confirmed: {
      value: formatNumber(confirmed),
      label: 'Confirmadas',
    },
    finished: {
      value: formatNumber(finished),
      label: 'Realizadas',
    },
    lobby: {
      value: formatNumber(lobby),
      label: lobby && lobby > 1 ? 'Pacientes no Lobby' : 'Paciente no Lobby',
    },
  }

  return (
    <Stack spacing={4} direction="column">
      <Stack direction="row" spacing={2}>
        <ProgressChart value={percentage} />
        <Stack>
          <Typography variant="h6">Consultas do dia</Typography>
          <Stack direction="row" spacing={2}>
            <Stack spacing={2}>
              <Typography noWrap fontWeight={fontWeight}>
                <Typography
                  variant="inherit"
                  component="span"
                  color={theme => theme.palette.primary.light}
                >
                  {stats.appointments.value}
                </Typography>
                <Typography component="span">
                  {' '}
                  {stats.appointments.label}
                </Typography>
              </Typography>
              <Typography noWrap fontWeight={fontWeight}>
                <Typography
                  variant="inherit"
                  component="span"
                  color={theme => theme.palette.primary.light}
                >
                  {stats.confirmed.value}/
                </Typography>
                <Typography
                  variant="inherit"
                  component="span"
                  color={theme => theme.palette.primary.main}
                >
                  {stats.appointments.value}
                </Typography>
                <Typography component="span">
                  {' '}
                  {stats.confirmed.label}
                </Typography>
              </Typography>
            </Stack>
            <Stack spacing={2}>
              <Typography noWrap fontWeight={fontWeight}>
                <Typography
                  variant="inherit"
                  component="span"
                  color={theme => theme.palette.primary.light}
                >
                  {stats.finished.value}/
                </Typography>
                <Typography
                  variant="inherit"
                  component="span"
                  color={theme => theme.palette.primary.main}
                >
                  {stats.appointments.value}
                </Typography>
                <Typography component="span">
                  {' '}
                  {stats.finished.label}
                </Typography>
              </Typography>
              <Typography noWrap fontWeight={fontWeight}>
                <Typography
                  variant="inherit"
                  component="span"
                  color={theme => theme.palette.primary.light}
                >
                  {stats.lobby.value}
                </Typography>
                <Typography component="span"> {stats.lobby.label}</Typography>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
