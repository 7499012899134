import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  form: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  input: {
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  timePickerContainer: {
    margin: theme.spacing(1, 0),
    '& div': {
      width: '100%',
    },
    '& input': {
      width: '90%',
    },
    '& .MuiInputAdornment-positionEnd': {
      width: '10%',
    },
  },
}))
