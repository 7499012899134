import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  confirmButton: {
    textTransform: 'none',
    width: '100%',
    height: '42px',
    fontSize: '12px',
    fontWeight: 600,
    color: 'hsla(0, 0%, 100%, 1)',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
  checkInButton: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    width: '100%',
    height: '42px',
    fontSize: '12px',
    fontWeight: 600,
    color: 'hsla(0, 0%, 100%, 1)',
    background: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    },
  },
  startButton: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    width: '100%',
    height: '42px',
    fontSize: '12px',
    fontWeight: 600,
    color: 'hsla(0, 0%, 100%, 1)',
    background: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    },
  },
  finishButton: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    width: '100%',
    height: '42px',
    fontSize: '12px',
    fontWeight: 600,
    color: 'hsla(0, 0%, 100%, 1)',
    background: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    },
  },
}))
