import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '@store/store'

import { profileApi } from './profile.api'
import { IProfile } from './profile.model'
import { sessionApi } from '../session/session.api'

const initialState = {} as IProfile.Slice.State

export const profileSlice = createSlice({
  name: 'profileSlice',
  initialState,
  reducers: {
    updateWorkWindow(
      state,
      { payload }: IProfile.Slice.UpdateWorkWindow.Payload,
    ) {
      // state.doctor.workWindows[payload.day] = {
      //   ...state.doctor.workWindows[payload.day],
      //   ...payload.data,
      // }
    },
    updateWorkWindowInterval(
      state,
      { payload }: IProfile.Slice.UpdateWorkWindowInterval.Payload,
    ) {
      // state.doctor.workWindows[payload.day].interval[payload.idx] = {
      //   ...state.doctor.workWindows[payload.day].interval[payload.idx],
      //   ...payload.data,
      // }
    },
    addInterval(state, { payload }: IProfile.Slice.AddInterval.Payload) {
      // state.doctor.workWindows[payload.day].interval.push({
      //   startAt: '',
      //   endAt: '',
      // })
    },
    removeInterval(state, { payload }: IProfile.Slice.RemoveInterval.Payload) {
      // state.doctor.workWindows[payload.day].interval.splice(payload.idx, 1)
    },
    toogleFreeDay(state, { payload }: IProfile.Slice.ToogleFriday.Payload) {
      // state.doctor.workWindows[payload.day].freeDay =
      //   !state.doctor.workWindows[payload.day].freeDay
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      sessionApi.endpoints.session.matchFulfilled,
      (state, { payload }) => {
        state.doctor = payload.doctor
      },
    ),
      builder.addMatcher(
        profileApi.endpoints.updateProfile.matchFulfilled,
        (state, { payload }) => {
          state.doctor = {
            ...payload.doctor,
          }
        },
      ),
      builder.addMatcher(
        profileApi.endpoints.updateImage.matchFulfilled,
        (state, { payload }) => {
          state.doctor = {
            ...payload.doctor,
          }
        },
      )
  },
})

export const {
  updateWorkWindow,

  addInterval,
  removeInterval,
  updateWorkWindowInterval,

  toogleFreeDay,
} = profileSlice.actions

export const doctorProfileSelector = (state: RootState) =>
  state.profileSlice.doctor
