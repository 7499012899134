import { Appointment } from '@app/appointment/appointment'
import { AppointmentCheck } from '@app/appointment-check/appointment-check'
import { Calendar } from '@app/calendar/calendar'
import { DayOfWork } from '@app/day-of-work/day-of-work'
import { Home } from '@app/home/home'
import { Login } from '@app/login/login'
import { MedicalFile } from '@app/medical-file/medical-file'
import { PatientAppointment } from '@app/patient-appointment/patient-appointment'
import { PatientDetails } from '@app/patient-details/patient-details'
import { Patients } from '@app/patients/patients'
import { Profile } from '@app/profile/profile'
import { SignUp } from '@app/sign-up/sign-up'

export const Pages = [
  {
    path: '/',
    isPrivate: false,
    Component: Login,
    title: 'Login',
  },
  {
    path: '/cadastro',
    isPrivate: false,
    Component: SignUp,
    title: 'Cadastro',
  },
  {
    path: '/agenda',
    isPrivate: true,
    Component: Calendar,
    title: 'Agenda',
  },
  {
    path: '/perfil',
    isPrivate: true,
    Component: Profile,
    title: 'Perfil',
  },
  {
    path: '/pacientes',
    isPrivate: true,
    Component: Patients,
    title: 'Pacientes',
  },
  {
    path: '/pacientes/:id',
    isPrivate: true,
    Component: PatientDetails,
    title: 'Informações dos pacientes',
  },
  {
    path: '/pacientes/:id/consultas/:id',
    isPrivate: true,
    Component: PatientAppointment,
    title: 'Consulta',
  },
  {
    path: '/pacientes/:id/ficha',
    isPrivate: true,
    Component: MedicalFile,
    title: 'Ficha médica',
  },
  {
    path: '/dia-de-trabalho',
    isPrivate: true,
    Component: DayOfWork,
    title: 'Dia de trabalho',
  },
  {
    path: '/dia-de-trabalho/pre-consulta/:id',
    isPrivate: true,
    Component: AppointmentCheck,
    title: 'Pré-consulta',
  },
  {
    path: '/dia-de-trabalho/consulta/:id',
    isPrivate: true,
    Component: Appointment,
    title: 'Consulta',
  },
  {
    path: 'home',
    isPrivate: true,
    Component: Home,
    title: 'Home',
  },
]
