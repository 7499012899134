import { Box, CircularProgress } from '@mui/material'

import { useStyles } from './loader.styles'

interface Props {
  size?: number
}

export const Loader = ({ size = 40 }: Props) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.container} data-testid="loader">
      <CircularProgress color="primary" size={size} />
    </Box>
  )
}
