import { useCallback, useEffect, useState } from 'react'

import { useDebounce } from 'use-debounce'

import { COMMON } from '@config/common'
import { useLazyGetDoctorPatientsQuery } from '@slices/searches/searches.api'

const { SEARCH_PATIENT_DEBOUNCE } = COMMON

export const useSearchPatient = () => {
  const [patientName, setPatientName] = useState('')
  const [patientNameStartsWith] = useDebounce(
    patientName,
    SEARCH_PATIENT_DEBOUNCE,
  )
  const [searchPatient, { data, isFetching, isLoading }] =
    useLazyGetDoctorPatientsQuery()

  const handlePatientNameChange = useCallback((value: string) => {
    setPatientName(value)
  }, [])

  useEffect(() => {
    if (patientNameStartsWith && patientNameStartsWith.length > 3) {
      searchPatient({ name: patientNameStartsWith })
    }
  }, [patientNameStartsWith, searchPatient])

  return {
    handlePatientNameChange,
    loading: isLoading || isFetching,
    patients: data?.patients,
  }
}
