import { useCallback, useState } from 'react'

import { sortTable } from '@utils/sort-table/sort-table'

export const useSortTable = <T>(initialOrderValue: keyof T, data?: T[]) => {
  const [orderBy, setOrderBy] = useState<keyof T>(initialOrderValue)
  const [order, setOrder] = useState<'asc' | 'desc'>('desc')

  const handleRequestSort = useCallback(
    (property: keyof T) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    },
    [order, orderBy],
  )

  return {
    sortedData: data ? sortTable<T>(data, orderBy, order) : [],
    order,
    orderBy,
    handleRequestSort,
  }
}
