import { CSSProperties } from '@mui/styled-engine'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  appointmentInfoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.common.white,
    padding: theme.spacing(1.5, 3),
    minHeight: '6rem',
    borderRadius: theme.spacing(1),
    flexWrap: 'wrap',
    boxShadow: '0 5px 20px 0 rgb(0 0 0 / 20%)',
  },
  appointmentInfoGridContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '65%',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  pacientDescription: {
    ...(theme.typography.caption as CSSProperties),
    color: '#707070',
    marginLeft: theme.spacing(0.5),
  },
  contentContainer: {
    boxShadow: '0 5px 20px 0 rgb(0 0 0 / 20%)',
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1, 4),
  },
  avatar: { width: '2.8rem', height: '2.8rem', borderRadius: 10 },
  appointmentInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  appointmentInfoDateTime: {
    display: 'flex',
  },
  datetimeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  firstPacient: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(0.5),
  },
  datetime: {
    ...(theme.typography.body1 as CSSProperties),
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightBold as CSSProperties['fontWeight'],
  },
  patientName: {
    marginLeft: theme.spacing(1),
    fontSize: '14px',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.h5.fontSize,
    },
    color: theme.palette.common.black,
    fontWeight: 500,
  },
  button: {
    ...(theme.typography.button as CSSProperties),
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    padding: theme.spacing(1.5, 10),
    fontSize: '13px',
  },
  lastAppointmentButton: {
    ...(theme.typography.button as CSSProperties),
    height: '2rem',
    borderRadius: '5px',
    background: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold as CSSProperties['fontWeight'],
    textTransform: 'none',
    fontSize: '10px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    minWidth: '25%',
    marginTop: theme.spacing(1),
  },
  medicalFileButton: {
    ...(theme.typography.button as CSSProperties),
    height: '2rem',
    borderRadius: '5px',
    background: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold as CSSProperties['fontWeight'],
    textTransform: 'none',
    fontSize: '10px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    minWidth: '25%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}))
