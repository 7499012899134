import { AddBoxOutlined, Delete } from '@mui/icons-material'
import {
  Box,
  Fab,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'

import {
  IPrescription,
  IPrescriptionItem,
} from '@model/appointment/appointment'
import {
  changePrescriptions,
  addPrescriptionItem,
  removePrescriptionItem,
} from '@slices/appointments/appointments.slice'
import { useAppDispatch } from '@store/store'

import { useStyles } from './prescriptions.styles'

interface Props {
  prescriptionIdx: number
  prescription: IPrescription
}

export const Prescriptions = ({ prescription, prescriptionIdx }: Props) => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()

  function handleIntervalChange(prescriptionItemIdx: number, add: number) {
    if (add === 1) {
      dispatch(addPrescriptionItem({ prescriptionIdx }))
      return
    }
    if (add === 0) {
      dispatch(removePrescriptionItem({ prescriptionIdx, prescriptionItemIdx }))
      return
    }
  }

  function handleChangePrescriptionsItem(
    prescriptionItemIdx: number,
    prescriptionItem: IPrescriptionItem,
  ) {
    dispatch(
      changePrescriptions({
        prescriptionIdx,
        prescriptionItemIdx,
        prescriptionItem,
      }),
    )
  }

  return (
    <Box>
      {prescription.prescriptionItems.map((item, idx) => (
        <Box className={classes.boxPrescriptionsItems}>
          <Grid
            key={idx}
            container
            spacing={1}
            className={classes.gridContainer}
          >
            <Grid item xs={6}>
              <TextField
                name="Remédio"
                fullWidth
                multiline
                autoFocus
                placeholder="Remédio"
                label="Remédio"
                variant="outlined"
                value={item.medicine}
                onChange={e =>
                  handleChangePrescriptionsItem(idx, {
                    ...item,
                    medicine: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="Duração"
                fullWidth
                multiline
                autoFocus
                placeholder="Duração"
                label="Duração"
                variant="outlined"
                value={item.duration}
                onChange={e =>
                  handleChangePrescriptionsItem(idx, {
                    ...item,
                    duration: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="Dosagem"
                fullWidth
                multiline
                autoFocus
                placeholder="Dosagem"
                label="Dosagem"
                variant="outlined"
                value={item.dosage}
                onChange={e =>
                  handleChangePrescriptionsItem(idx, {
                    ...item,
                    dosage: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="Horios"
                fullWidth
                multiline
                autoFocus
                placeholder="Horários"
                label="Horários"
                variant="outlined"
                value={item.time}
                onChange={e =>
                  handleChangePrescriptionsItem(idx, {
                    ...item,
                    time: e.target.value,
                  })
                }
              />
            </Grid>
          </Grid>
          <Box className={classes.boxDeleteButton}>
            <IconButton
              size="small"
              onClick={() => handleIntervalChange(idx, 0)}
            >
              <Delete />
            </IconButton>
          </Box>
        </Box>
      ))}
      <Box className={classes.boxAddButton}>
        <Fab
          variant="extended"
          size="small"
          color="primary"
          onClick={() => handleIntervalChange(0, 1)}
          className={classes.addButton}
        >
          <AddBoxOutlined color="secondary" />
          <Typography className={classes.textAddButton}>
            Novo medicamento
          </Typography>
        </Fab>
      </Box>
    </Box>
  )
}
