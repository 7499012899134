import { Box } from '@mui/material'

import { TableSummary } from '@components/TableSummary/table-summary'
import { patientSelector } from '@slices/patient/patient.slice'
import { useAppSelector } from '@store/store'
import { formatDate } from '@utils/date/date'

import { useStyles } from './medical-history.styles'

export const MedicalHistory = () => {
  const { classes } = useStyles()
  const patient = useAppSelector(patientSelector)

  return (
    <Box className={classes.container}>
      <TableSummary title="HISTÓRICO MÉDICO" icon="clipboard">
        <Box className={classes.contentContainer}>
          {patient.medicalFile?.healthHistoric && (
            <Box>
              <Box className={classes.question}>Doenças que já teve:</Box>
              <Box className={classes.answer}>
                {patient.medicalFile?.healthHistoric.join(' ,')}
              </Box>
            </Box>
          )}
          {patient.medicalFile?.familyHistoric && (
            <Box>
              <Box className={classes.question}>Doenças familiares</Box>
              <Box className={classes.answer}>
                {patient.medicalFile?.familyHistoric.join(' ,')}
              </Box>
            </Box>
          )}
          {patient.medicalFile?.surgery && (
            <Box>
              <Box className={classes.question}>Já fez cirurgia?</Box>
              <Box className={classes.answer}>
                {`${formatDate(patient.medicalFile?.surgery.date)} -
                  ${patient.medicalFile?.surgery.reason}`}
              </Box>
            </Box>
          )}
          {patient.medicalFile?.internation && (
            <Box>
              <Box className={classes.question}>Já esteve internado?</Box>
              <Box className={classes.answer}>
                {`${formatDate(patient.medicalFile?.internation.date)} -
                  ${patient.medicalFile?.internation.reason}`}
              </Box>
            </Box>
          )}
        </Box>
      </TableSummary>
    </Box>
  )
}
