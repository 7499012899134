import * as Yup from 'yup'

import { IFullAddress } from '@model/doctor/doctor'
import { phoneNumberRegex } from '@utils/phone-number/phone-number'
import { zipCodeRegex } from '@utils/zipcode/zipcode'

export const useSignUpForm = () => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Esse campo é obrigatório'),
    email: Yup.string()
      .email('Insira um endereço de email válido')
      .required('Esse campo é obrigatório'),
    password: Yup.string()
      .required('Esse campo é obrigatório')
      .min(6, 'A senha deve ter pelo menos 6 caracteres'),
    crmNumber: Yup.number().required('Esse campo é obrigatório'),
    phoneNumber: Yup.string()
      .required('Esse campo é obrigatório')
      .matches(
        phoneNumberRegex(),
        'O número precisa estar no seguinte formato: (XX) XXXXX-XXXX',
      ),
    clinicName: Yup.string().required('Esse campo é obrigatório'),
    specialities: Yup.array().required('Esse campo é obrigatório'),
    fullAddress: Yup.object()
      .shape({ placeId: Yup.string(), description: Yup.string() })
      .required('Esse campo é obrigatório'),
    uf: Yup.string().required('Esse campo é obrigatório'),
    city: Yup.string().required('Esse campo é obrigatório'),
    district: Yup.string().required('Esse campo é obrigatório'),
    street: Yup.string().required('Esse campo é obrigatório'),
    number: Yup.number().required('Esse campo é obrigatório'),
    zipcode: Yup.string()
      .required('Esse campo é obrigatório')
      .matches(
        zipCodeRegex(),
        'O CEP precisa estar no seguinte formato: XXXXX-XXX',
      ),
  })

  const initialValues = {
    name: '',
    email: '',
    password: '',
    phoneNumber: '',
    crmNumber: '',
    clinicName: '',
    specialities: [],
    fullAddress: {} as IFullAddress,
    uf: '',
    city: '',
    street: '',
    district: '',
    number: '',
    zipcode: '',
  }

  return { validationSchema, initialValues }
}
