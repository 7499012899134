import { CSSProperties } from '@mui/styled-engine'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  label: {
    ...(theme.typography.caption as CSSProperties),
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 11,
  },
}))
