import { Grid } from '@mui/material'

import { ErrorModal } from '@components/ErrorModal/error-modal'
import { Loader } from '@components/Loader/loader'
import { PatientGeneralInfo } from '@components/PatientGeneralInfo/patient-general-info'
import { useGetPatientDetailsQuery } from '@slices/patient/patient.api'
import { patientSelector } from '@slices/patient/patient.slice'
import { useAppSelector } from '@store/store'

import { PatientAppointmentsTable } from './components/PatientAppointmentsTable/patient-appointments-table'
import { PatientClinicalAnalizesTable } from './components/PatientClinicalAnalizesTable/patient-clinical-analizes-table'
import { PatientPrescriptionsTable } from './components/PatientPrescriptionsTable/patient-prescriptions-table'

export const PatientDetails = () => {
  const patient = useAppSelector(patientSelector)
  const { _id: patientId } = patient

  const {
    data: patientDetails,
    isLoading: isGettingPatientDetails,
    error: getPatientDetailsDataError,
  } = useGetPatientDetailsQuery({ patientId })

  if (isGettingPatientDetails) return <Loader />

  return (
    <>
      <PatientGeneralInfo />
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <PatientAppointmentsTable
            appointments={patientDetails?.appointments}
          />
        </Grid>
        <Grid item xs={4}>
          <PatientPrescriptionsTable
            prescriptions={patientDetails?.prescriptions}
          />
        </Grid>
        <Grid item xs={4}>
          <PatientClinicalAnalizesTable
            clinicalAnalizes={patientDetails?.clinicalAnalyzes}
          />
        </Grid>
      </Grid>

      {getPatientDetailsDataError && (
        <ErrorModal error={getPatientDetailsDataError} />
      )}
    </>
  )
}
