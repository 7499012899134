import { AppointmentInfo } from '@components/AppointmentInfo/appointment-info'
import { AppointmentMedicalAnalysis } from '@components/AppointmentMedicalAnalysis/appointment-medical-analysis'
import { currentPatientAppointmentSelector } from '@slices/patient/patient.slice'
import { useAppSelector } from '@store/store'

export const PatientAppointment = () => {
  const appointment = useAppSelector(currentPatientAppointmentSelector)

  return (
    <AppointmentInfo {...appointment}>
      <AppointmentMedicalAnalysis
        anamnesis={appointment.anamnesis}
        clinicalAnalysis={appointment.clinicalAnalysis}
        physicalAnalysis={appointment.physicalAnalysis}
        recommendations={appointment.recommendations}
        patient={appointment.patient}
      />
    </AppointmentInfo>
  )
}
