import { createSlice } from '@reduxjs/toolkit'

import { IPrescriptionItem } from '@model/appointment/appointment'
import { RootState } from '@store/store'
import { translateStatus } from '@utils/status/status'

import { appointmentsApi } from './appointments.api'
import {
  AppointmentTabs,
  ConfirmationTabs,
  IAppointments,
  MedicalTabs,
  PrescriptionsTabs,
} from './appointments.model'

const initialState = {
  doctorWorkday: [],
  appointmentTab: AppointmentTabs.Medical,
  medicalTab: MedicalTabs.Anamnesis,
  prescriptionsTab: PrescriptionsTabs.Prescriptions,
  confirmationTab: ConfirmationTabs.Medical,
  anamnesis: [{ complaint: '', intensity: '' }],
  physicalAnalysis: [{ index: '', value: '' }],
  clinicalAnalysis: { diagnosis: '', description: '', visible: true },
  recommendations: [],
  prescriptions: [],
  exams: [],
  medicalCertificate: { period: '', reason: '' },
  cameFromWorkday: true,
} as IAppointments.Slice.State

export const appointmentsSlice = createSlice({
  name: 'appointmentsSlice',
  initialState,
  reducers: {
    setCurrentAppointment: (
      state,
      { payload }: IAppointments.Slice.SetCurrentAppointment.Payload,
    ) => {
      const currentAppointment = state.doctorWorkday.find(
        item => item._id === payload.appointmentId,
      )

      if (
        currentAppointment?._id === state.currentAppointment?._id &&
        currentAppointment?.status === state.currentAppointment?.status
      ) {
        return
      }

      state.currentAppointment = currentAppointment
      state.cameFromWorkday = true
    },
    changeStatus: (
      state,
      { payload }: IAppointments.Slice.ChangeStatus.Payload,
    ) => {
      if (!state.currentAppointment) return

      state.currentAppointment = {
        ...state.currentAppointment,
        status: payload.status,
        translatedStatus: translateStatus(payload.status),
      }
    },
    changeAppointmentTab: (
      state,
      { payload }: IAppointments.Slice.ChangeAppointmentTab.Payload,
    ) => {
      state.appointmentTab = payload.appointmentTab
    },
    changeCurrentMedicalTab: (
      state,
      { payload }: IAppointments.Slice.ChangeCurrentMedicalTab.Payload,
    ) => {
      state.medicalTab = payload.medicalTab
    },
    changeCurrentConfirmationTab: (
      state,
      { payload }: IAppointments.Slice.ChangeCurrentConfirmationTab.Payload,
    ) => {
      state.confirmationTab = payload.confirmationTab
    },
    changeCurrentPrescriptionsTab: (
      state,
      { payload }: IAppointments.Slice.ChangeCurrentPrescriptionsTab.Payload,
    ) => {
      state.prescriptionsTab = payload.prescriptionsTab
    },
    addAnamnese(state) {
      state.anamnesis.push({
        complaint: '',
        intensity: '',
      })
    },
    removeAnamense(
      state,
      { payload }: IAppointments.Slice.RemoveAnamense.Payload,
    ) {
      state.anamnesis.splice(payload.idx, 1)
    },
    changeAnamnesis: (
      state,
      { payload }: IAppointments.Slice.ChangeAnamnesis.Payload,
    ) => {
      state.anamnesis[payload.idx] = payload.anamnese
    },

    changeClinicalAnalysis: (
      state,
      { payload }: IAppointments.Slice.ChangeClinicalAnalysis.Payload,
    ) => {
      state.clinicalAnalysis = payload.clinicalAnalysis
    },
    addPhysicalAnalysis(state) {
      state.physicalAnalysis.push({
        index: '',
        value: '',
      })
    },
    removePhysicalAnlysis(
      state,
      { payload }: IAppointments.Slice.RemovePhysicalAnlysis.Payload,
    ) {
      state.physicalAnalysis.splice(payload.idx, 1)
    },
    changePhysicalAnalysis: (
      state,
      { payload }: IAppointments.Slice.ChangePhysicalAnalysis.Payload,
    ) => {
      state.physicalAnalysis[payload.idx] = payload.physicalAnalysis
    },

    changeRecommendations: (
      state,
      { payload }: IAppointments.Slice.ChangeRecommendations.Payload,
    ) => {
      state.recommendations = payload.recommendations
    },

    addPrescription(state) {
      state.prescriptions.push({
        prescriptionItems: [
          { medicine: '', duration: '', dosage: '', time: '' },
        ],
      })
    },
    removePrescription(
      state,
      { payload }: IAppointments.Slice.RemovePrescription.Payload,
    ) {
      state.prescriptions.splice(payload.idx, 1)
    },

    addPrescriptionItem(
      state,
      { payload }: IAppointments.Slice.AddPrescriptionItem.Payload,
    ) {
      state.prescriptions[payload.prescriptionIdx].prescriptionItems.push(
        {} as IPrescriptionItem,
      )
    },
    removePrescriptionItem(
      state,
      { payload }: IAppointments.Slice.RemovePrescriptionItem.Payload,
    ) {
      state.prescriptions[payload.prescriptionIdx].prescriptionItems.splice(
        payload.prescriptionItemIdx,
        1,
      )
    },
    changePrescriptions: (
      state,
      { payload }: IAppointments.Slice.ChangePrescriptions.Payload,
    ) => {
      state.prescriptions[payload.prescriptionIdx].prescriptionItems[
        payload.prescriptionItemIdx
      ] = payload.prescriptionItem
    },

    addExam(state) {
      state.exams.push({
        examItems: [{ type: '', solicitations: [] }],
      })
    },
    removeExam(state, { payload }: IAppointments.Slice.RemoveExam.Payload) {
      state.exams.splice(payload.idx, 1)
    },

    addExamItem(state, { payload }: IAppointments.Slice.AddExamItem.Payload) {
      state.exams[payload.examIdx].examItems.push({
        type: '',
        solicitations: [],
      })
    },
    removeExamItem(
      state,
      { payload }: IAppointments.Slice.RemoveExamItem.Payload,
    ) {
      state.exams[payload.examIdx].examItems.splice(payload.examItemIdx, 1)
    },
    changeExams: (
      state,
      { payload }: IAppointments.Slice.ChangeExams.Payload,
    ) => {
      state.exams[payload.examIdx].examItems[payload.examItemIdx] =
        payload.examItem
    },
    changeMedicalCertificate: (
      state,
      { payload }: IAppointments.Slice.ChangeMedicalCertificate.Payload,
    ) => {
      state.medicalCertificate = payload.medicalCertificate
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      appointmentsApi.endpoints.getDoctorWorkday.matchFulfilled,
      (state, { payload }) => {
        state.doctorWorkday = payload.appointments
      },
    )
  },
})

export const {
  setCurrentAppointment,
  changeStatus,
  changeAppointmentTab,
  changeCurrentMedicalTab,
  changeCurrentPrescriptionsTab,
  changeCurrentConfirmationTab,
  changeAnamnesis,
  addAnamnese,
  removeAnamense,
  changePhysicalAnalysis,
  addPhysicalAnalysis,
  removePhysicalAnlysis,
  changeClinicalAnalysis,
  changeRecommendations,
  changePrescriptions,
  addPrescription,
  removePrescription,
  addPrescriptionItem,
  removePrescriptionItem,
  changeExams,
  addExam,
  removeExam,
  addExamItem,
  removeExamItem,
  changeMedicalCertificate,
} = appointmentsSlice.actions

export const currentAppointmentSelector = (state: RootState) =>
  state.appointmentsSlice.currentAppointment
export const currentAppointmentTabSelector = (state: RootState) =>
  state.appointmentsSlice.appointmentTab
export const currentMedicalTabSelector = (state: RootState) =>
  state.appointmentsSlice.medicalTab
export const currentPrescriptionsTabSelector = (state: RootState) =>
  state.appointmentsSlice.prescriptionsTab
export const currentConfirmationTabSelector = (state: RootState) =>
  state.appointmentsSlice.confirmationTab
export const anamnesisSelector = (state: RootState) =>
  state.appointmentsSlice.anamnesis
export const physicalAnalysisSelector = (state: RootState) =>
  state.appointmentsSlice.physicalAnalysis
export const clinicalAnalysisSelector = (state: RootState) =>
  state.appointmentsSlice.clinicalAnalysis
export const recommendationsSelector = (state: RootState) =>
  state.appointmentsSlice.recommendations
export const prescriptionsSelector = (state: RootState) =>
  state.appointmentsSlice.prescriptions
export const examsSelector = (state: RootState) => state.appointmentsSlice.exams
export const medicalCertificateSelector = (state: RootState) =>
  state.appointmentsSlice.medicalCertificate
export const cameFromWorkdaySelector = (state: RootState) =>
  state.appointmentsSlice.cameFromWorkday
