import { ChangeEvent } from 'react'

import { Box, Tab, Tabs } from '@mui/material'

import { MedicalTabs } from '@slices/appointments/appointments.model'
import {
  changeCurrentMedicalTab,
  currentMedicalTabSelector,
} from '@slices/appointments/appointments.slice'
import { useAppDispatch, useAppSelector } from '@store/store'

import { useStyles } from './medical-analysis-tab.styles'
import { Anamnesis } from '../Anamnesis/anamnesis'
import { ClinicalAnalysis } from '../ClinicalAnalysis/clinical-analysis'
import { PhysicalAnalysis } from '../PhysicalAnalysis/physical-analysis'
import { Recommendations } from '../Recommendations/recommendations'

export const MedicalAnalysisTab = () => {
  const { classes } = useStyles()
  const currentMedicalTab = useAppSelector(currentMedicalTabSelector)
  const dispatch = useAppDispatch()

  function handleTabChange(_: ChangeEvent<unknown>, newValue: MedicalTabs) {
    dispatch(changeCurrentMedicalTab({ medicalTab: newValue }))
  }

  return (
    <Box className={classes.container}>
      <Tabs
        value={currentMedicalTab}
        classes={{ indicator: classes.indicator, root: classes.root }}
        onChange={handleTabChange}
        variant="scrollable"
      >
        <Tab
          className={classes.tab}
          value={MedicalTabs.Anamnesis}
          label="Anamnese"
        />
        <Tab
          className={classes.tab}
          value={MedicalTabs.Physical}
          label="Avaliação Física"
        />
        <Tab
          className={classes.tab}
          value={MedicalTabs.Clinical}
          label="Análise Clínica"
        />
        <Tab
          className={classes.tab}
          value={MedicalTabs.Recommendations}
          label="Recomendações"
        />
      </Tabs>
      {currentMedicalTab === MedicalTabs.Anamnesis && <Anamnesis />}
      {currentMedicalTab === MedicalTabs.Physical && <PhysicalAnalysis />}
      {currentMedicalTab === MedicalTabs.Clinical && <ClinicalAnalysis />}
      {currentMedicalTab === MedicalTabs.Recommendations && <Recommendations />}
    </Box>
  )
}
