import {
  Button,
  ButtonProps,
  CircularProgress,
  Typography,
} from '@mui/material'
import clsx from 'clsx'

import { useStyles } from './form-button.styles'

interface Props extends ButtonProps {
  isLoading?: boolean
  loaderColor?: 'primary' | 'secondary' | 'inherit'
}

export const FormButton = ({
  isLoading,
  loaderColor,
  children,
  disabled,
  ...rest
}: Props) => {
  const { classes } = useStyles()

  return (
    <Button
      {...rest}
      disabled={disabled}
      className={clsx(classes.container, { [classes.disabled]: disabled })}
    >
      {!isLoading ? (
        <Typography className={classes.label}>{children}</Typography>
      ) : (
        <CircularProgress color={loaderColor ?? 'secondary'} />
      )}
    </Button>
  )
}
