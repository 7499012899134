import { IHealthPlan, ISpeciality } from '../health-info/health-info'

export const IAddressField = [
  'uf',
  'city',
  'district',
  'street',
  'number',
  'zipcode',
  'clinicName',
] as const

export interface IFullAddress {
  placeId: string
  description: string
}

export const STATE_OPTIONS = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
]

export type IAddress = Record<typeof IAddressField[number], string> & {
  fullAddress: IFullAddress
}

export type IWorkWindowGroup = {
  startAt: string
  endAt: string
  interval?: { startAt: string; endAt: string }[]
  duration: string
  freeDay: boolean
  expiresAt?: Date
  groupId: string
  days: typeof IWorkWindowMapKey[number][]
}

export const IWorkWindowMapKey = ['0', '1', '2', '3', '4', '5', '6'] as const

export interface IDoctor {
  _id: string
  name: string
  email: string
  imageUrl?: string
  profileImage?: {
    imageUrl?: string
    imageFile?: File
  }
  address: IAddress
  workWindowGroup: IWorkWindowGroup[]
  specialities: ISpeciality[]
  healthPlans: IHealthPlan[]
  refreshToken: string
  location: { coordinates: number[] }
  firstLogin: boolean
  birthDate?: Date
  crm: {
    pending: boolean
    approved: boolean
    number: string
  }
}
