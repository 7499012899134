import { ClipboardEventHandler } from 'react'

import {
  Box,
  Typography,
  Autocomplete,
  createFilterOptions,
  TextField,
} from '@mui/material'
import { useField, useFormikContext } from 'formik'

import { Loader } from '@components/Loader/loader'
import { useSearchPatient } from '@hooks/use-search-patient/use-search-patient'
import { IPatient } from '@model/patient/patient'
import { formatCPF } from '@utils/format-cpf/format-cpf'
import { formatPhoneNumber } from '@utils/phone-number/phone-number'

import { useStyles } from './search-patient-input.styles'

const filter = createFilterOptions({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stringify: (option: any) => option.title,
  matchFrom: 'start',
})

export function SearchPatientInput() {
  const { classes } = useStyles()
  const { patients, handlePatientNameChange, loading } = useSearchPatient()
  const [field, meta] = useField({
    name: 'patient',
  })
  const { setFieldValue, setFieldTouched } = useFormikContext()

  const patientsOptions = patients?.map(item => ({
    _id: item._id,
    title: item.name,
    name: item.name,
    email: item.email,
    cpf: item.cpf,
    phoneNumber: item.phoneNumber,
  }))

  return (
    <Autocomplete
      options={patientsOptions ?? []}
      fullWidth
      autoHighlight
      freeSolo
      value={field.value ?? ''}
      onChange={(_, newValue) => {
        if (newValue && newValue.inputValue) {
          setFieldValue(field.name, {
            title: newValue.inputValue,
          })
        } else {
          const value = newValue as IPatient

          setFieldValue(field.name, value)
          setFieldValue('email', value.email)
          setFieldValue('phoneNumber', formatPhoneNumber(value.phoneNumber))
          setFieldValue('cpf', formatCPF(value.cpf))
        }
      }}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option
        }
        if (option.inputValue) {
          return option.inputValue
        }
        return option.title
      }}
      renderOption={(props, option) => (
        <Box
          {...(props as ClipboardEventHandler<HTMLDivElement>)}
          key={option?._id ? option?._id : option.title}
          className={classes.optionContainer}
        >
          <Typography className={classes.optionTitle}>
            {option.title}
          </Typography>
          {option.cpf && option.phoneNumber && (
            <Typography className={classes.optionSubtitle}>
              {formatCPF(option.cpf)}|{formatPhoneNumber(option.phoneNumber)}
            </Typography>
          )}
        </Box>
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)

        const { inputValue } = params
        const isExisting = options.some(option => inputValue === option.title)
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Adicionar "${inputValue}"`,
          })
        }

        return filtered
      }}
      renderInput={params => (
        <TextField
          {...params}
          placeholder="Buscar nome"
          onChange={event => handlePatientNameChange(event.target.value)}
          variant="standard"
          error={Boolean(meta.error && meta.touched) ? true : false}
          onBlur={() => setFieldTouched('patient', true)}
          helperText={
            Boolean(meta.error && meta.touched)
              ? 'Você deve selecionar ou adicionar um paciente'
              : ''
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: loading ? (
              <Box>
                <Loader size={16} />
              </Box>
            ) : null,
          }}
        />
      )}
    />
  )
}
