import { Typography, Box, Divider } from '@mui/material'
import { BsArrowRightShort } from 'react-icons/bs'
import { useNavigate } from 'react-router'

import { TableSummary } from '@components/TableSummary/table-summary'
import { IFormattedAppointment } from '@model/appointment/appointment'
import {
  patientSelector,
  setCurrentAppointment,
} from '@slices/patient/patient.slice'
import { useAppDispatch, useAppSelector } from '@store/store'
import { formatDate, formatTime } from '@utils/date/date'

import { useStyles } from './patient-appointments-table.styles'

interface Props {
  appointments?: IFormattedAppointment[]
}

export const PatientAppointmentsTable = ({ appointments }: Props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const patient = useAppSelector(patientSelector)

  const { classes } = useStyles()

  function handleNavigation(appointment: IFormattedAppointment) {
    dispatch(setCurrentAppointment({ ...appointment, patient }))
    navigate(`/pacientes/${patient._id}/consultas/${appointment._id}`)
  }

  return (
    <TableSummary title="ÚLTIMAS CONSULTAS" icon="text">
      {appointments?.map(item => (
        <>
          <Box
            key={item._id}
            className={classes.rowContainer}
            onClick={() =>
              handleNavigation(item as unknown as IFormattedAppointment)
            }
          >
            <Typography className={classes.title}>Consulta</Typography>
            <Box className={classes.contentContainer}>
              <Box className={classes.content}>
                <Typography className={classes.cell}>
                  {formatDate(item.start)}
                </Typography>
                <Typography className={classes.cell}>|</Typography>
                <Typography className={classes.cell}>
                  {formatTime(item.start)}
                </Typography>
              </Box>
              <Box className={classes.content}>
                <Typography className={classes.seeText}>
                  Ver prontuário
                </Typography>
                <BsArrowRightShort size={16} className={classes.icon} />
              </Box>
            </Box>
          </Box>
          <Divider />
        </>
      ))}
    </TableSummary>
  )
}
