import { ReactNode } from 'react'

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Typography,
  Grid,
  Divider,
} from '@mui/material'
import {
  EnvelopeSimple,
  PhoneDisconnect,
  IdentificationBadge,
} from 'phosphor-react'

import { IFormattedAppointment } from '@model/appointment/appointment'
import { getAge } from '@utils/date/date'

import { useStyles } from './appointment-info.styles'

interface Props extends IFormattedAppointment {
  finishButton?: {
    isLoading: boolean
    handleFinish(): void
  }
  children: ReactNode
}

export const AppointmentInfo = ({
  patient,
  formattedDate,
  formattedTime,
  finishButton,
  children,
}: Props) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.appointmentInfoContainer}>
        <Grid item className={classes.appointmentInfoGridContainer}>
          <Box className={classes.appointmentInfo}>
            <Avatar className={classes.avatar}>
              <img
                className={classes.avatar}
                src={patient.imageUrl}
                alt="Blu"
              />
            </Avatar>
            <Box>
              <Typography className={classes.patientName}>
                {patient.name}
              </Typography>
              <Box className={classes.appointmentInfoDateTime}>
                <Box className={classes.firstPacient}>
                  <EnvelopeSimple
                    className={classes.pacientDescription}
                    fontSize="small"
                  />
                  <Typography className={classes.pacientDescription}>
                    {patient.email}
                  </Typography>
                </Box>
                <Box className={classes.datetimeContainer}>
                  <PhoneDisconnect
                    className={classes.pacientDescription}
                    fontSize="small"
                  />
                  <Typography className={classes.pacientDescription}>
                    {patient.phoneNumber}
                  </Typography>
                </Box>
                <Box className={classes.datetimeContainer}>
                  <IdentificationBadge
                    className={classes.pacientDescription}
                    fontSize="small"
                  />
                  <Typography className={classes.pacientDescription}>
                    {patient.birthday && `${getAge(patient.birthday)} anos`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Button className={classes.lastAppointmentButton}>
              Histórico do paciente
            </Button>
            <Button className={classes.medicalFileButton}>Ficha Médica</Button>
          </Box>
        </Grid>
        {finishButton &&
          (!finishButton.isLoading ? (
            <Button
              className={classes.button}
              onClick={finishButton.handleFinish}
            >
              Finalizar consulta
            </Button>
          ) : (
            <CircularProgress color="primary" />
          ))}
      </Box>
      <Box className={classes.contentContainer}>{children}</Box>
    </Box>
  )
}
