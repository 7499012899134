import { timeToDate } from '@utils/date/date'
import { onlyDigits } from '@utils/only-digits/only-digits'

import { ICreateAppointmentForm } from '../../model/create-appointment-form/create-appointment-form'

export const formatCreateAppointmentPayload = ({
  patient,
  email,
  cpf,
  phoneNumber,
  date,
  paymentMethod,
  time,
}: ICreateAppointmentForm) => {
  const patientCommonData = {
    name: patient.title,
    cpf: onlyDigits(cpf),
    phoneNumber: onlyDigits(phoneNumber),
    email: email,
  }

  const patientData = patient._id
    ? { _id: patient._id, ...patientCommonData }
    : { ...patientCommonData }

  return {
    patient: patientData,
    paymentMethod: paymentMethod.value,
    appointmentStartAt: timeToDate(time, date),
  }
}
