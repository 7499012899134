import { GeocodeResult } from 'use-places-autocomplete'

import { IAddress } from '@model/doctor/doctor'

export const GmapsAddressFields = [
  'route',
  'street_address',
  'street_number',
  'sublocality_level_1',
  'administrative_area_level_1',
  'administrative_area_level_2',
  'postal_code',
] as const

export const IGmapsAddressFieldMap: Record<
  typeof GmapsAddressFields[number],
  keyof IAddress
> = {
  route: 'street',
  street_address: 'street',
  street_number: 'number',
  sublocality_level_1: 'district',
  administrative_area_level_1: 'uf',
  administrative_area_level_2: 'city',
  postal_code: 'zipcode',
}

export namespace IParseAddress {
  export type Result = Omit<IAddress, 'clinicName' | 'fullAddress'> | null
  export type Params = Pick<
    GeocodeResult,
    'formatted_address' | 'address_components'
  >
}

export interface IParseAddress {
  (data: IParseAddress.Params): IParseAddress.Result
}
