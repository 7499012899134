import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { Routes } from './routes/routes'
import { persistor, store } from './store/store'
import { Theme } from './theme/theme'

export function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Theme>
          <Routes />
        </Theme>
      </PersistGate>
    </Provider>
  )
}
