import { Table, TableBody, Box } from '@mui/material'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'

import { DashboardCard } from '@components/DashboardCard/dashboard-card'
import { ErrorModal } from '@components/ErrorModal/error-modal'
import { Loader } from '@components/Loader/loader'
import { TableFooter } from '@components/TableFooter/table-footer'
import { usePagination } from '@hooks/table/use-pagination'
import { useSortTable } from '@hooks/table/use-sort-table'
import { IPatient } from '@model/patient/patient'
import { setPatient } from '@slices/patient/patient.slice'
import { useGetDoctorPatientsQuery } from '@slices/searches/searches.api'
import { useAppDispatch } from '@store/store'

import { PatientsTableHead } from './components/PatientsTableHead/patients-table-head'
import { PatientsTableRow } from './components/PatientsTableRow/patients-table-row'
import { useStyles } from './patients.styles'

export const Patients = () => {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { page, handleChangePage } = usePagination()

  const {
    data: patientsData,
    isLoading: isGettingDoctorPatients,
    error: patientsGetError,
  } = useGetDoctorPatientsQuery({
    page: 0,
  })

  const {
    sortedData: patients,
    order,
    orderBy,
    handleRequestSort,
  } = useSortTable<IPatient>('name', patientsData?.patients)

  function handleNavigation(patient: IPatient) {
    dispatch(setPatient(patient))
    navigate(`/pacientes/${patient._id}`)
  }

  if (isGettingDoctorPatients) {
    return <Loader />
  }

  return (
    <>
      <Box className={classes.dashContainer}>
        <DashboardCard
          title="BASE DE PACIENTES"
          value={patientsData?.formattedCount}
          icon="square"
        />
        <Box className={classes.dashItem}>
          <DashboardCard
            title="PACIENTES RECORRENTES"
            value={patientsData?.formattedRegularPatients}
            description="Pacientes recorrentes x Pacientes do App"
            graphData={patientsData?.regularBluPatientsData}
            graphName="Pacientes"
            icon="checkbox"
          />
        </Box>
        <Box className={classes.dashItem}>
          <DashboardCard
            title="NOVOS PACIENTES"
            value={patientsData?.formattedNewPatients}
            description="Pacientes novos x Pacientes do App"
            graphData={patientsData?.newBluPatientsData}
            graphName="Pacientes"
            icon="person"
          />
        </Box>
      </Box>
      <Table className={clsx(classes.table, classes.content)}>
        <PatientsTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {patients.map(patient => (
            <PatientsTableRow
              key={patient._id}
              {...patient}
              onRowClick={() => handleNavigation(patient)}
            />
          ))}
          <TableFooter
            count={patientsData?.count}
            totalPages={patientsData?.totalPages}
            handleChangePage={handleChangePage}
            page={page}
          />
        </TableBody>
      </Table>
      {patientsGetError && <ErrorModal error={patientsGetError} />}
    </>
  )
}
