/* eslint-disable import/no-duplicates */
import format from 'date-fns/format'
import getDay from 'date-fns/getDay'
import { ptBR } from 'date-fns/locale'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'

const locales = {
  'pt-BR': ptBR,
}

export const ptBRLocalizer = {
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
}

export { ptBR }
