import { Document, View, Text, Page, Image } from '@react-pdf/renderer'

import Logo from '@assets/sidebar-logo.png'
import { IDoctor } from '@model/doctor/doctor'

interface Props {
  patient: string
  doctor: IDoctor
  title: string
  dateDay: string
  dateMonth: string
  dateYear: string
  children: React.ReactNode
}

export const PrescriptionFileBasis = ({
  patient,
  doctor,
  title,
  children,
  dateDay,
  dateMonth,
  dateYear,
}: Props) => {
  return (
    <Document>
      <Page size="A4">
        <View
          style={{
            margin: 30,
            height: '95%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <View style={{ flex: 0.9 }}>
            <View style={{ textAlign: 'center', margin: 30 }}>
              <Text>{title}</Text>
            </View>
            <View>
              <Text style={{ fontSize: 12 }}>Paciente: {patient}</Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Text style={{ fontSize: 12 }}>Doutor(a): {doctor.name}</Text>

                <Text style={{ fontSize: 12, marginLeft: '10px' }}>
                  CRM: {doctor.crm.number}
                </Text>
                <Text style={{ fontSize: 12, marginLeft: '10px' }}>
                  UF: {doctor.address.uf}
                </Text>
              </View>
              <Text style={{ fontSize: 12 }}>
                Data: {dateDay}/{dateMonth}/{dateYear}
              </Text>
            </View>
            {children}
          </View>
          <View
            style={{
              flex: 0.1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontSize: 11,
              }}
            >
              Receita gerada através do BLU MED{' '}
            </Text>
            <Image
              style={{
                height: '30px',
                width: '60px',
              }}
              src={Logo}
            />
          </View>
        </View>
      </Page>
    </Document>
  )
}
