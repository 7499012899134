import { CSSProperties } from '@mui/styled-engine'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: theme.spacing(4),
    boxShadow: theme.palette.customShadows.shadow1,
    borderRadius: 10,
    width: '100%',
    height: '10rem',
  },
  contentContainer: { width: '100%', margin: 0 },
  appointmentInfoGridContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '65%',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  appointmentInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.common.white,
    padding: theme.spacing(1.5),
    minHeight: '6rem',
    borderRadius: theme.spacing(1),
  },
  appointmentInfoContentContainer: {
    marginBottom: theme.spacing(2),
  },
  avatar: { width: '2.8rem', height: '2.8rem', borderRadius: 10 },
  avatarImg: { width: '2.8rem', height: 'auto' },
  appointmentInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  appointmentInfoDateTime: {
    display: 'flex',
    alignItems: 'center',
  },
  datetimeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
  },
  datetime: {
    ...(theme.typography.caption as CSSProperties),
    color: '#707070',
    marginLeft: theme.spacing(0.5),
    fontSize: 15,
  },
  firstDate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...(theme.typography.caption as CSSProperties),
    color: '#707070',
    fontSize: 15,
  },
  firstPacient: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(0.5),
  },
  pacientDescription: {
    ...(theme.typography.caption as CSSProperties),
    color: '#707070',
    marginLeft: theme.spacing(0.5),
  },
  patientName: {
    marginLeft: theme.spacing(1),
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.h5.fontSize,
    },
    color: theme.palette.common.black,
    fontWeight: 500,
  },
  empty: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
  },
  appointmentStatus: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(1.5),
    padding: 10,
    borderRadius: 5,
    width: '30%',
  },
  text: {
    ...(theme.typography.body1 as CSSProperties),
    fontWeight: theme.typography.fontWeightBold as CSSProperties['fontWeight'],
    fontSize: 12,
  },
  title: {
    fontSize: 10,
    color: '#a3a3a3',
  },
  titleContainer: {
    marginTop: '0.6rem',
  },
  appointmentConfirmed: {
    color: theme.palette.success.main,
    backgroundColor: '#98d7b1',
  },
  appointmentCanceled: { color: theme.palette.error.main },
  appointmentChecked: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.main,
  },
  appointmentOtherwise: { color: theme.palette.secondary.dark },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '35%',
    },
    minHeight: '14rem',
  },
  button: {
    ...(theme.typography.button as CSSProperties),
    height: '4rem',
    borderRadius: 10,
    color: theme.palette.common.black,
    background: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold as CSSProperties['fontWeight'],
    textTransform: 'none',
    margin: '0.2rem',
    fontSize: 12,
  },
  cancelCheckInContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    minWidth: '55rem',
  },
  cancelButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    minWidth: '23%',
    marginTop: theme.spacing(2),
  },
  checkInButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    minWidth: '23%',
    marginTop: theme.spacing(2),
  },
  confirmButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    minWidth: '23%',
    marginTop: theme.spacing(2),
  },
  startAppointmentButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
    minWidth: '23%',
    marginTop: theme.spacing(2),
  },
  lastAppointmentButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
    minWidth: '25%',
    marginTop: theme.spacing(1),
    height: '3rem',
  },
  medicalFileButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    minWidth: '25%',
    marginTop: theme.spacing(1),
    height: '3rem',
  },
}))
