export enum IHabityFrequency {
  Never = 'Never',
  Almost_Never = 'Almost_Never',
  Sometimes = 'Sometimes',
  Always = 'Always',
}

export enum IBloodGroup {
  A_Positve = 'A+',
  A_Negative = 'A-',
  B_Positve = 'B+',
  B_Negative = 'B-',
  O_Positve = 'O+',
  O_Negative = 'O-',
  AB_Positve = 'AB+',
  AB_Negative = 'AB-',
}

interface IPhysicalFeatures {
  bloodGroup: IBloodGroup
  weight: number
  height: number
  pwd: boolean
}

export interface IMedicalFile {
  allergies: string[]
  medicationInUse: string[]
  familyHistoric: string[]
  healthHistoric: string[]
  physicalFeatures: IPhysicalFeatures
  internation: {
    date: Date
    reason: string
  }
  surgery: {
    date: Date
    reason: string
  }
  smokingHabit: IHabityFrequency
  drinkingHabit: IHabityFrequency
}
