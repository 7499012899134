import { Box } from '@mui/material'

import { useStyles } from './profile-image-tab.styles'
import { ProfileImageForm } from '../ProfileImageForm/profile-image-form'

export const ProfileImageTab = () => {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      <ProfileImageForm />
    </Box>
  )
}
