import { Box, Typography, Divider } from '@mui/material'

import { TableSummary } from '@components/TableSummary/table-summary'
import { IPrescription } from '@model/appointment/appointment'

import { useStyles } from './patient-prescriptions-table.styles'

interface Props {
  prescriptions?: IPrescription[]
}

export const PatientPrescriptionsTable = ({ prescriptions }: Props) => {
  const { classes } = useStyles()

  return (
    <TableSummary title="PRESCRIÇÕES" icon="clipboard">
      {prescriptions?.map(item => {
        return item.prescriptionItems.map(item => (
          <>
            <Box key={item._id} className={classes.rowContainer}>
              <Typography className={classes.title}>{item.medicine}</Typography>
              <Typography className={classes.text}>{item.dosage}</Typography>
              <Typography className={classes.text}>{item.duration}</Typography>
              <Typography className={classes.text}>{item.time}</Typography>
            </Box>
            <Divider />
          </>
        ))
      })}
    </TableSummary>
  )
}
