import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '20px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2),
    width: '20rem',
    backgroundColor: theme.palette.common.white,
    overflow: 'hidden',
    boxShadow: '0 5px 20px 0 rgb(0 0 0 / 20%)',
    height: '12rem',
    justifyContent: 'space-between',
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: 'black',
    fontSize: '13px',
  },
  icon: {
    color: theme.palette.primary.light,
  },
  subtitle: {
    fontWeight: 600,
    color: theme.palette.additionalColors.title,
    fontSize: '44px',
    marginTop: theme.spacing(-1),
  },
  description: {
    fontSize: '11px',
    fontWeight: 600,
    maxWidth: '7rem',
  },
  result: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 5px 20px 0 rgb(0 0 0 / 20%)',
    marginLeft: '35%',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  resultContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(-2),
  },
  chartImage: {
    marginBottom: theme.spacing(4),
  },
}))
