import { Avatar, Box, Button, Divider, Grid, Typography } from '@mui/material'
import clsx from 'clsx'
import {
  EnvelopeSimple,
  PhoneDisconnect,
  IdentificationBadge,
  CalendarBlank,
  Clock,
  BookOpen,
  UserSquare,
} from 'phosphor-react'
import { useNavigate } from 'react-router-dom'

import { ErrorModal } from '@components/ErrorModal/error-modal'
import { Loader } from '@components/Loader/loader'
import { Status } from '@model/appointment/appointment'
import { useUpdateAppointmentStatusMutation } from '@slices/appointments/appointments.api'
import {
  changeStatus,
  currentAppointmentSelector,
} from '@slices/appointments/appointments.slice'
import { setPatient } from '@slices/patient/patient.slice'
import { useAppDispatch, useAppSelector } from '@store/store'
import { getAge } from '@utils/date/date'
import {
  ITranslateStatusResponse,
  checkGreenStatus,
} from '@utils/status/status'

import { useStyles } from './appointment-check.styles'

export const AppointmentCheck = () => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const data = useAppSelector(currentAppointmentSelector)
  const isGreenStatus = checkGreenStatus(
    data?.translatedStatus as ITranslateStatusResponse,
  )
  const navigate = useNavigate()

  const [
    updateAppointmentStatus,
    { error: updateAppointmentError, isLoading: isUpdatingAppointmentStatus },
  ] = useUpdateAppointmentStatusMutation()

  async function handleChangeStatus(status: Status) {
    const appointmentId = data?._id

    if (appointmentId) {
      await updateAppointmentStatus({
        appointmentId,
        status,
      }).unwrap()
      dispatch(changeStatus({ status }))
    }
  }

  async function handleCancelation() {
    handleChangeStatus(Status.Canceled)
    navigate('/dia-de-trabalho')
  }

  async function handlePatientDetails() {
    const patient = data?.patient

    if (patient) {
      dispatch(setPatient(patient))
      navigate(`/pacientes/${patient._id}`)
    }
  }

  async function handleStartAppointment() {
    const appointmentId = data?._id

    if (data?.status === Status.In_Progress) {
      navigate(`/dia-de-trabalho/consulta/${appointmentId}`)
      return
    }

    handleChangeStatus(Status.In_Progress)
    navigate(`/dia-de-trabalho/consulta/${appointmentId}`)
  }

  if (isUpdatingAppointmentStatus) {
    return <Loader />
  }

  const error = updateAppointmentError

  return (
    <>
      <Box className={classes.container}>
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          className={classes.contentContainer}
        >
          <Grid item className={classes.appointmentInfoGridContainer}>
            <Box className={classes.appointmentInfoContainer}>
              <Typography className={classes.title}>PACIENTE</Typography>

              <Box className={classes.appointmentInfo}>
                <Avatar className={classes.avatar}>
                  <img
                    className={classes.avatarImg}
                    src={data?.patient.imageUrl}
                    alt="Blu"
                  />
                </Avatar>
                <Box>
                  <Typography className={classes.patientName}>
                    {data?.patient.name}
                  </Typography>
                  <Box className={classes.appointmentInfoDateTime}>
                    <Box className={classes.firstPacient}>
                      <EnvelopeSimple
                        className={classes.pacientDescription}
                        fontSize="small"
                      />
                      <Typography className={classes.pacientDescription}>
                        {data?.patient.email}
                      </Typography>
                    </Box>
                    <Box className={classes.datetimeContainer}>
                      <PhoneDisconnect
                        className={classes.pacientDescription}
                        fontSize="small"
                      />
                      <Typography className={classes.pacientDescription}>
                        {data?.patient.phoneNumber}
                      </Typography>
                    </Box>
                    <Box className={classes.datetimeContainer}>
                      <IdentificationBadge
                        className={classes.pacientDescription}
                        fontSize="small"
                      />
                      <Typography className={classes.pacientDescription}>
                        {data?.patient.birthday &&
                          `${getAge(data?.patient.birthday)} anos`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Divider />

              <Box className={classes.appointmentInfoDateTime}>
                <Box className={classes.empty}>
                  <Box className={classes.titleContainer}>
                    <Typography className={classes.title}>
                      INFORMAÇÕES DA CONSULTA
                    </Typography>
                  </Box>
                  <Box className={classes.appointmentInfoDateTime}>
                    <Box className={classes.firstDate}>
                      <CalendarBlank
                        className={classes.firstDate}
                        fontSize="small"
                      />
                      <Typography className={classes.datetime}>
                        {data?.formattedDate}
                      </Typography>
                    </Box>
                    <Box className={classes.datetimeContainer}>
                      <Clock className={classes.datetime} fontSize="small" />
                      <Typography className={classes.datetime}>
                        {data?.formattedTime}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  className={clsx(classes.appointmentStatus, {
                    [classes.appointmentConfirmed]: isGreenStatus,
                    [classes.appointmentCanceled]:
                      data?.status === Status.Canceled,
                    [classes.appointmentChecked]:
                      data?.status === Status.Checked_In,
                    [classes.appointmentOtherwise]:
                      data?.status !== Status.Canceled && !isGreenStatus,
                  })}
                >
                  {data?.status === Status.Checked_In ? (
                    <Typography className={classes.text}>
                      {data?.translatedStatus}
                    </Typography>
                  ) : (
                    <Typography className={classes.text}>
                      Consulta {data?.translatedStatus}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item className={classes.buttonsContainer}>
            <Button
              className={clsx(classes.button, classes.lastAppointmentButton)}
              onClick={handlePatientDetails}
            >
              <BookOpen fontSize="small" />
              Histórico do paciente
            </Button>
            <Button
              className={clsx(classes.button, classes.medicalFileButton)}
              onClick={handlePatientDetails}
            >
              <UserSquare fontSize="small" />
              Ficha Médica
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.cancelCheckInContainer}>
        <Button
          className={clsx(classes.button, classes.cancelButton)}
          disabled={
            data?.status === Status.Checked_In ||
            data?.status === Status.In_Progress ||
            data?.status === Status.Finished
          }
          onClick={handleCancelation}
        >
          CANCELAR CONSULTA
        </Button>
        {/* {data?.status === Status.Scheduled ? (
        <Button
          className={clsx(classes.button, classes.confirmButton)}
          onClick={handleConfirmAppointment}
        >
          CONFIRMAR CONSULTA
        </Button>
      ) : (
        <Button
          className={clsx(classes.button, classes.checkInButton)}
          disabled={data?.status !== Status.Confirmed}
          onClick={handleCheckInAppointment}
        >
          CHECK-IN PACIENTE
        </Button>
      )} */}
        <Button
          className={clsx(classes.button, classes.confirmButton)}
          disabled={data?.status !== Status.Scheduled}
          onClick={() => handleChangeStatus(Status.Confirmed)}
        >
          CONFIRMAR PACIENTE
        </Button>
        <Button
          className={clsx(classes.button, classes.checkInButton)}
          disabled={data?.status !== Status.Confirmed}
          onClick={() => handleChangeStatus(Status.Checked_In)}
        >
          CHECK-IN PACIENTE
        </Button>
        <Button
          className={clsx(classes.button, classes.startAppointmentButton)}
          disabled={
            !(
              data?.status === Status.Checked_In ||
              data?.status === Status.In_Progress
            )
          }
          onClick={handleStartAppointment}
        >
          IR PARA A CONSULTA
        </Button>
      </Box>
      {error && <ErrorModal error={error} />}
    </>
  )
}
