import { useState } from 'react'

import { Box, Container, Grow, TextField, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import Doctor from '@assets/lounge-doctor.png'
import Logo from '@assets/sidebar-logo.png'
import { ErrorModal } from '@components/ErrorModal/error-modal'
import { FormButton } from '@components/FormButton/form-button'
import { ModalContainer } from '@components/ModalContainer/modal-container'
import {
  useSendForgotPasswordEmailMutation,
  useSessionMutation,
} from '@slices/session/session.api'
import { ISession } from '@slices/session/session.model'

import { useStyles } from './login.styles'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um endereço de email válido')
    .required('Esse campo é obrigatório'),
  password: Yup.string()
    .required('Esse campo é obrigatório')
    .min(6, 'A senha deve ter pelo menos 6 caracteres'),
})

type FormData = ISession.Create.Request

export const Login = () => {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [signIn, { isLoading: isSigningIn, error: signInError }] =
    useSessionMutation()

  const [
    sendForgotPasswordEmail,
    {
      isLoading: isSendingForgotPasswordEmail,
      error: sendingForgotPasswordEmailError,
    },
  ] = useSendForgotPasswordEmailMutation()

  async function handleSubmit({ email, password }: FormData) {
    await signIn({
      email,
      password,
    })
      .unwrap()
      .then(({ doctor }) => navigate(doctor.firstLogin ? '/perfil' : '/home'))
  }

  async function handleSendForgotPasswordEmail({
    email,
  }: Pick<FormData, 'email'>) {
    await sendForgotPasswordEmail({
      email,
    })
      .unwrap()
      .finally(closeModal)
  }

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  const error = signInError || sendingForgotPasswordEmailError

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <img className={classes.animation} src={Doctor} alt="Doctor" />
        <Typography className={classes.title}>
          Seu prontuário de maneira totalmente digital
        </Typography>
        <Typography className={classes.subTitle}>
          Otimize a realização de suas consultas com o BluMed
        </Typography>
      </Box>
      <Grow in={true} timeout={700}>
        <Container className={classes.contentContainer}>
          <img className={classes.logo} src={Logo} alt="Blu" />
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              values,
              setFieldValue,
              setFieldTouched,
              touched,
              isValid,
              dirty,
            }) => (
              <Form id="login-form" className={classes.form}>
                <TextField
                  error={Boolean(errors['email'] && touched['email'])}
                  name="email"
                  data-testid="email-input"
                  placeholder="E-mail"
                  helperText={touched['email'] ? errors['email'] : null}
                  className={classes.input}
                  variant="outlined"
                  value={values['email']}
                  onBlur={() => setFieldTouched('email', true)}
                  onChange={e => setFieldValue('email', e.target.value)}
                />
                <TextField
                  error={Boolean(errors['password'] && touched['password'])}
                  name="password"
                  data-testid="password-input"
                  placeholder="Senha"
                  type="password"
                  helperText={touched['password'] ? errors['password'] : null}
                  className={classes.input}
                  variant="outlined"
                  value={values['password']}
                  onBlur={() => setFieldTouched('password', true)}
                  onChange={e => setFieldValue('password', e.target.value)}
                />
                <Typography
                  onClick={openModal}
                  className={classes.forgotPasswordText}
                >
                  Esqueci minha senha
                </Typography>
                <FormButton
                  disabled={!isValid || !dirty}
                  type="submit"
                  isLoading={isSigningIn}
                  data-testid="login-button"
                >
                  Entrar
                </FormButton>
              </Form>
            )}
          </Formik>
        </Container>
      </Grow>
      <ModalContainer
        id="send-forgot-email-modal"
        title="Enviar e-mail de redifinição de senha"
        handleModalClose={closeModal}
        isOpen={isModalOpen}
      >
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Insira um endereço de email válido')
              .required('Esse campo é obrigatório'),
          })}
          onSubmit={handleSendForgotPasswordEmail}
        >
          {({
            errors,
            values,
            setFieldValue,
            setFieldTouched,
            touched,
            isValid,
            dirty,
          }) => (
            <Form id="login-form" className={classes.form}>
              <TextField
                error={Boolean(errors['email'] && touched['email'])}
                name="email"
                placeholder="E-mail"
                helperText={touched['email'] ? errors['email'] : null}
                className={classes.input}
                variant="outlined"
                value={values['email']}
                onBlur={() => setFieldTouched('email', true)}
                onChange={e => setFieldValue('email', e.target.value)}
              />

              <FormButton
                disabled={!isValid || !dirty}
                type="submit"
                isLoading={isSendingForgotPasswordEmail}
              >
                Enviar
              </FormButton>
            </Form>
          )}
        </Formik>
      </ModalContainer>
      {error && <ErrorModal error={error} />}
    </Box>
  )
}
