import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    cursor: 'pointer',
    '&:hover': {
      borderRight: `2px solid ${theme.palette.primary.dark}`,
    },
  },
  avatar: { width: '2rem', height: '2rem' },
  avatarImg: { width: '2rem', height: '2rem' },
  nameCellTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  nameText: {
    fontSize: 13,
    marginLeft: theme.spacing(1),
  },
  typeText: {
    fontSize: 11,
    marginLeft: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(0.8),
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    maxWidth: '7rem',
  },
  dateText: {
    fontSize: 13,
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.light,
    fontWeight: 'bold',
  },
  ball: {
    height: '0.5rem',
    width: '0.5rem',
    backgroundColor: theme.palette.primary.light,
    borderRadius: 100,
  },
}))
