import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  gridContainer: {
    maxWidth: '70%',
  },
  title: {
    color: 'black',
    fontSize: 12,
  },
  icon: {
    color: theme.palette.primary.light,
  },
}))
