import { COMMON } from '@config/common'

const { SEARCH_PATIENTS_DEFAULT_LIMIT } = COMMON

export const getTableFooterText = (page: number, totalCount = 0) => {
  const rowsPerPageTable = SEARCH_PATIENTS_DEFAULT_LIMIT

  const from = (page + 1) * rowsPerPageTable - (rowsPerPageTable - 1)
  const last =
    (page + 1) * rowsPerPageTable <= totalCount
      ? (page + 1) * rowsPerPageTable
      : totalCount

  return `Exibindo de ${from} até ${last} de ${totalCount}`
}
