import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    width: '15rem',
    height: '15rem',
  },
  button: {
    bottom: theme.spacing(3),
  },
}))
