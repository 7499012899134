import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 400,
    fontSize: '13px',
  },
  clockIcon: {
    verticalAlign: 'middle',
    fontSize: '12px',
    marginRight: '0.3em',
  },
  appointmentTime: {
    color: theme.palette.primary.light,
    fontSize: '12px',
  },
  cancelButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cancelText: {
    textTransform: 'none',
    fontSize: '10px',
    fontWeight: 400,
    color: '#707070',
    height: '70%',
    alignContent: 'center',
    padding: '0 10px 0 10px',
    backgroundColor: 'hsla(0, 0%, 92%, 0.2)',
    borderRadius: '5px',
    boxShadow: 'hsla(0, 0%, 0%, 0.15)',
    marginRight: '10px',
  },
  cancelArrow: {
    fontSize: '29px',
    color: '#707070',
    height: '70%',
    backgroundColor: 'hsla(0, 0%, 92%, 0.2)',
    borderRadius: '5px',
    boxShadow: 'hsla(0, 0%, 0%, 0.15)',
    padding: '8.16px 0px 8.16px 0px',
  },
  summary: {
    alignSelf: 'center',
    margin: '10px 0',
  },
  profileImage: {
    height: '84px',
    width: '84px',
  },
  name: {
    fontSize: '1.2em',
    fontWeight: 400,
  },
  status: {
    color: '#A8B1CE',
    fontSize: '1em',
  },
  statusIcon: {
    verticalAlign: 'middle',
    fontSize: '12px',
  },
  profileButton: {
    alignSelf: 'center',
    textTransform: 'none',
    fontSize: '10px',
    color: '#00234C',
    backgroundColor: 'hsla(0, 0%, 92%, 0.2)',
    marginBottom: '20px',
    width: '129px',
    height: '29px',
    boxShadow: 'hsla(0, 0%, 0%, 0.15)',
  },
  profileButtonIcon: {
    backgroundColor: '#1172D2',
    width: '35px',
    height: '35px',
  },
}))
