import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material'

import { IClinicalAnalysis } from '@model/appointment/appointment'
import {
  changeClinicalAnalysis,
  clinicalAnalysisSelector,
} from '@slices/appointments/appointments.slice'
import { useAppDispatch, useAppSelector } from '@store/store'

import { useStyles } from './clinical-analysis.styles'

export const ClinicalAnalysis = () => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const clinicalAnalysisData = useAppSelector(clinicalAnalysisSelector)

  function handleChangeClinicalAnalysis(clinicalAnalysis: IClinicalAnalysis) {
    dispatch(changeClinicalAnalysis({ clinicalAnalysis }))
  }

  return (
    <Box className={classes.container}>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid>
          <Typography className={classes.textTitle}>Análise Clínica</Typography>
        </Grid>
        <Grid item className={classes.gridItem}>
          <Box className={classes.BoxInput}>
            <TextField
              hiddenLabel
              name="Diagnostico"
              value={clinicalAnalysisData.diagnosis}
              fullWidth
              multiline
              autoFocus
              placeholder="Coloque aqui o diagnóstico do paciente"
              label="Diagnóstico"
              variant="standard"
              className={classes.textDiagnostico}
              onChange={e =>
                handleChangeClinicalAnalysis({
                  ...clinicalAnalysisData,
                  diagnosis: e.target.value,
                })
              }
            />
            <Box className={classes.boxDiagnostico}>
              <TextField
                name="Descricao_diagnostico"
                fullWidth
                multiline
                autoFocus
                value={clinicalAnalysisData.description}
                placeholder="Explique o diagnóstico do paciente"
                label="Descrição do diagnóstico"
                variant="outlined"
                className={classes.textDiagnostico}
                onChange={e =>
                  handleChangeClinicalAnalysis({
                    ...clinicalAnalysisData,
                    description: e.target.value,
                  })
                }
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={clinicalAnalysisData.visible}
                      onChange={e =>
                        handleChangeClinicalAnalysis({
                          ...clinicalAnalysisData,
                          visible: e.target.checked,
                        })
                      }
                      defaultChecked
                    />
                  }
                  label="Visível"
                />
              </FormGroup>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
