import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  root: {
    height: '100%',
    justifyContent: 'center',
  },
  paperCard: {
    height: '100%',
    padding: '2em',
    alignContent: 'center',
    fontSize: '0.8em',
    borderRadius: '1.3em',
    boxShadow: theme.palette.customShadows.shadow1,
  },
}))
