import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    maxWidth: '40rem',
    margin: theme.spacing('1rem', 'auto'),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))
