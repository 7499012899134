import { COMMON } from '@config/common'
import { Status } from '@model/appointment/appointment'
import { baseApi } from '@services/api'
import { formatTime } from '@utils/date/date'
import {
  formatAppointment,
  formatAppointmentList,
} from '@utils/format-appointments/format-appointments'
import { formatAppointmentsToCalendar } from '@utils/format-appointments-to-calendar/format-appointments-to-calendar'

import { IAppointments } from './appointments.model'

const { POLLING_INTERVAL_IN_SECONDS } = COMMON

export const appointmentsApi = baseApi.injectEndpoints({
  endpoints(builder) {
    return {
      getAppointments: builder.query<
        IAppointments.Get.FormattedResponse,
        IAppointments.Get.Request
      >({
        query: arg => {
          const { date, period } = arg

          const dateOrDefault = date ?? new Date().toISOString()
          const periodOrDefault = period ?? 'month'

          const url = `appointments/doctor?date=${dateOrDefault}&period=${periodOrDefault}`

          return {
            url,
            method: 'GET',
          }
        },
        transformResponse: (
          response: IAppointments.Get.Response,
          _,
          { period },
        ) => {
          return {
            appointments: formatAppointmentsToCalendar(
              response.appointments,
              period,
            ),
          }
        },
        providesTags: ['Appointments'],
        keepUnusedDataFor: POLLING_INTERVAL_IN_SECONDS,
      }),
      getDoctorWorkday: builder.query<
        IAppointments.GetWorkday.Response,
        IAppointments.GetWorkday.Request
      >({
        query: () => {
          return {
            url: `appointments/workday?date=${new Date().toISOString()}`,
            method: 'GET',
          }
        },
        transformResponse: (response: IAppointments.GetWorkday.Response) => {
          const nextAppointmentHourFormatted = response?.nextAppointmentHour
            ? formatTime(response.nextAppointmentHour)
            : null

          return {
            ...response,
            nextAppointmentHourFormatted,
            appointments: formatAppointmentList(response.appointments),
          }
        },
        providesTags: ['Workday'],
        keepUnusedDataFor: POLLING_INTERVAL_IN_SECONDS,
      }),

      getAppointmentAvaliability: builder.query<
        IAppointments.GetAvaliability.Response,
        IAppointments.GetAvaliability.Request
      >({
        query: data => {
          return {
            url: 'appointments/available/doctor',
            method: 'POST',
            data,
          }
        },
      }),
      createAppointment: builder.mutation<
        IAppointments.Create.Response,
        IAppointments.Create.Request
      >({
        query(data) {
          return {
            url: 'appointments/doctor',
            method: 'POST',
            data,
          }
        },
        invalidatesTags: ['Appointments', 'Workday'],
      }),
      updateAppointmentStatus: builder.mutation<
        IAppointments.ChangeStatus.Response,
        IAppointments.ChangeStatus.Request
      >({
        query: ({ appointmentId, status }) => {
          const url = `appointments/doctor/${appointmentId}/update-status`

          return {
            url,
            method: 'PUT',
            data: {
              status,
            },
          }
        },
        invalidatesTags: ['Appointments', 'Workday'],
      }),
      moveAppointmentToNextStatus: builder.mutation<
        IAppointments.NextStatus.Response,
        IAppointments.NextStatus.Request
      >({
        query: ({ appointmentId }) => {
          const url = `appointments/${appointmentId}/next-status`

          return {
            url,
            method: 'POST',
          }
        },
        transformResponse: (response: IAppointments.NextStatus.Response) => {
          return { appointment: formatAppointment(response.appointment) }
        },
        invalidatesTags: result =>
          result?.appointment.status === Status.In_Progress ? ['Workday'] : [],
      }),
      finishAppointment: builder.mutation<
        IAppointments.Finish.Response,
        IAppointments.Finish.Request
      >({
        query: data => {
          return {
            url: 'appointments/doctor/finish',
            method: 'POST',
            data,
          }
        },
        invalidatesTags: ['Appointments', 'Workday'],
      }),
      cancelWorkday: builder.mutation<
        IAppointments.CancelWorkday.Response,
        IAppointments.CancelWorkday.Request
      >({
        query: () => {
          return {
            url: 'appointments/cancel/workday',
            method: 'POST',
          }
        },
        invalidatesTags: ['Appointments', 'Workday'],
      }),
    }
  },
})

export const {
  useGetAppointmentsQuery,
  useGetDoctorWorkdayQuery,
  useGetAppointmentAvaliabilityQuery,
  useLazyGetAppointmentAvaliabilityQuery,

  useCancelWorkdayMutation,
  useCreateAppointmentMutation,
  useFinishAppointmentMutation,
  useUpdateAppointmentStatusMutation,
  useMoveAppointmentToNextStatusMutation,
} = appointmentsApi
