import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
  container: {
    boxShadow: theme.palette.customShadows.shadow1,
    backgroundColor: theme.palette.common.white,
    border: `1.5px solid ${theme.palette.grey[200]}`,
    width: '100%',
    fontWeight: theme.typography.fontWeightBold,
  },
  offRange: {
    backgroundColor: theme.palette.grey[300],
  },
}))
