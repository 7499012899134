import { baseApi } from '@services/api'

import { ISignUp } from './sign-up.api.model'

export const signUpApi = baseApi.injectEndpoints({
  endpoints(builder) {
    return {
      signUp: builder.mutation<ISignUp.Create.Response, ISignUp.Create.Request>(
        {
          query: data => {
            return {
              url: 'accounts/doctor/register',
              method: 'POST',
              data,
            }
          },
        },
      ),
    }
  },
})

export const { useSignUpMutation } = signUpApi
